import React, { useCallback } from 'react'
import { Navigate, useNavigate } from "react-router-dom";
import ImageModal from '../../components/ImageModal';
import { Button, TextField } from '@mui/material';
import { green, pink, yellow, red } from '@mui/material/colors';
import { Link } from 'react-router-dom'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Tooltip from '@mui/material/Tooltip';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton, Box } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import MaterialReactTable from 'material-react-table';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import GppBadIcon from '@mui/icons-material/GppBad';



// "https://feedback-app.hoabl.in/api/vendor-registration"

const IconsMenu = ({row,handleStatusUpdate,statusEle}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = ()=> {
    setAnchorEl(null);
  }
  return (
    <>
      <Tooltip title="Verified">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          {
            statusEle[row?.original?.status]()
          }
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <MenuItem value='PENDING' onClick={() => {handleStatusUpdate(row?.original.id,'PENDING')}}>
          {statusEle['PENDING']()} PENDING
        </MenuItem>
        <MenuItem value='VERIFIED' onClick={() => {handleStatusUpdate(row?.original.id,'VERIFIED')}}>
          {statusEle['VERIFIED']()} VERIFIED
        </MenuItem>
        <MenuItem value='REJECTED' onClick={() => {handleStatusUpdate(row?.original.id,'REJECTED')}}>
          {statusEle['REJECTED']()} REJECTED
        </MenuItem>
      </Menu>
    </>
  )
}


function ListCpMaster() {
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [refresh, setRefresh] = React.useState(true);
  const navigate = useNavigate() 
  let authToken;
  try{
    authToken = JSON.parse(localStorage.getItem('EMPLOYEE-AUTH'))
  }catch(err){
    authToken = null
  }

  
  const handleStatusUpdate = (id,value) => {
    // console.log(id,value)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer "+authToken.token);

    var raw = JSON.stringify({
      status:value
    });

    var requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/cp-master-details/"+id, requestOptions)
      .then(result => {
        // console.log(result.status)
        if (result.status%400<10){
          NotificationManager.error('Not authorised to edit the data', 'Error', 6000);
        }
        navigate("/admin/cp-master")
        setRefresh(prev => !prev)
      })
      .catch(error => {
        console.log('error', error)
        NotificationManager.error('Something went wrong', 'Error', 6000);
      });
  };
  
  React.useEffect(() => {

    handleDataCall()    

  }, [])

  React.useEffect(() => {
    
    handleDataCall()

  }, [refresh])

  let statusEle = {
    PENDING: function(){
      return (
        <Avatar sx={{ width: 32, height: 32, bgcolor: yellow[500] }}>
          <GppMaybeIcon/>
        </Avatar>
      )
    },
    VERIFIED: function(){
      return (
        <Avatar sx={{ width: 32, height: 32, bgcolor: green[500] }}>
          <VerifiedUserIcon/>
        </Avatar>
      )
    },
    REJECTED: function(){
      return (
        <Avatar sx={{ width: 32, height: 32, bgcolor: red[500] }}>
          <GppBadIcon/>
        </Avatar>
      )
    }
  }

  const columns = React.useMemo(() => [
    // {
    //   accessorKey: 'status',
    //   header: 'Status',
    //   Cell: ({ cell, column }) => {
    //     let value = cell.getValue()

    //     return (
    //       <Tooltip title="Account settings">
    //         <IconButton
    //           onClick={() => {}}
    //           size="small"
    //           sx={{ ml: 2 }}
    //           // aria-controls={open ? 'account-menu' : undefined}
    //           // aria-haspopup="true"
    //           // aria-expanded={open ? 'true' : undefined}
    //         >
    //           {
    //             arrEle['pending']()
    //           }
    //         </IconButton>
    //       </Tooltip>
    //     ) 
    //   },
    //   size: 40,
    // },
    {
      accessorKey: 'hoabl_id',
      header: 'Hoabl Id',
    },
    {
      accessorKey: 'org_name',
      header: 'Organisation Name',
    },
    {
      accessorKey: 'type_of_business',
      header: 'Type of Business',
    },
    {
      accessorKey: 'owner_name',
      header: 'Owner Name',
    },
    {
      accessorKey: 'owner_phone',
      header: 'Owner Phone',
    },
    {
      accessorKey: 'contact_person_name',
      header: 'Contact Person Name',
    },
    {
      accessorKey: 'contact_person_phone',
      header: 'Contact Person Phone',
    },
    {
      accessorKey: 'email',
      header: 'Email',
    },
    {
      accessorKey: 'address',
      header: 'Address',
    },
    {
      accessorKey: 'state',
      header: 'State',
    },
    {
      accessorKey: 'city',
      header: 'City',
    },
    {
      accessorKey: 'is_gst_registered',
      header: 'Is Gst Registered',
    },
    {
      accessorKey: 'gst_no',
      header: 'Gst No',
    },
    {
      accessorKey: 'pan_no',
      header: 'Pan No',
    },
    {
      accessorKey: 'adhaar_no',
      header: 'Adhaar No',
    },
    {
      accessorKey: 'is_msme_registered',
      header: 'Is Msme Registered',
    },
    {
      accessorKey: 'msme_no',
      header: 'Msme_no',
    },
    {
      accessorKey: 'rera_no',
      header: 'Rera_no',
    },
    {
      accessorKey: 'beneficiary_name',
      header: 'Beneficiary Name',
    },
    {
      accessorKey: 'account_no',
      header: 'Account No',
    },
    {
      accessorKey: 'ifsc_code',
      header: 'Ifsc Code',
    },
    {
      accessorKey: 'bank_name',
      header: 'Bank Name',
    },
    {
      accessorKey: 'branch_name',
      header: 'Branch Name',
    },
    {
      accessorKey: 'xr_code',
      header: 'XR Code',
    },
    {
      accessorKey: 'gst_uri',
      header: 'Gst Uri',
      Cell: ({ cell, column }) => {
        let value = cell.getValue()
        return value ? (
          <ImageModal ls_token_key="EMPLOYEE-AUTH" url={value}/>
        ) : ""
      },
    },
    {
      accessorKey: 'msme_uri',
      header: 'Msme Uri',
      Cell: ({ cell, column }) => {
        let value = cell.getValue()
        return value ? (
          <ImageModal ls_token_key="EMPLOYEE-AUTH" url={value}/>
        ) : ""
      },
    },
    {
      accessorKey: 'pan_uri',
      header: 'Pan Uri',
      Cell: ({ cell, column }) => {
        let value = cell.getValue()
        return value ? (
          <ImageModal ls_token_key="EMPLOYEE-AUTH" url={value}/>
        ) : ""
      },
    },
    {
      accessorKey: 'adhaar_uri',
      header: 'Adhaar Uri',
      Cell: ({ cell, column }) => {
        let value = cell.getValue()
        return value ? (
          <ImageModal ls_token_key="EMPLOYEE-AUTH" url={value}/>
        ) : ""
      },
    },
    {
      accessorKey: 'rera_uri',
      header: 'Rera Uri',
      Cell: ({ cell, column }) => {
        let value = cell.getValue()
        return value ? (
          <ImageModal ls_token_key="EMPLOYEE-AUTH" url={value}/>
        ) : ""
      },
    },
    {
      accessorKey: 'cheque_uri',
      header: 'Cheque Uri',
      Cell: ({ cell, column }) => {
        let value = cell.getValue()
        return value ? (
          <ImageModal ls_token_key="EMPLOYEE-AUTH" url={value}/>
        ) : ""
      },
    },
  ])
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleDataCall = useCallback(() => {
    // let authToken = JSON.parse(localStorage.getItem('USER'))
    // console.log(authToken)
    if (!data.length){
      setIsLoading(true)
    }else{
      setIsRefetching(true)
    }


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer" +" "+ authToken.token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/cp-master-details", requestOptions)
      .then(response => {
        // console.log(response.status)
        if (response.status%400<10){
          NotificationManager.error('Not authorised to view this data', 'Error', 6000);
          throw new Error('Unauthorised role!!');
        }
        return response.json()
      })
      .then(result => {
        // console.log(result.status)
        // console.log(result.results)
        let tempData = result.results.sort((a, b)=>{
          let fa = a.hoabl_id.toLowerCase(),fb = b.hoabl_id.toLowerCase();

          if (fa < fb) {
              return 1;
          }
          if (fa > fb) {
              return -1;
          }
          return 0;
        })
        setData(tempData)
        setRows(tempData)
        setIsLoading(false)
        setIsRefetching(false)
      })
      .catch(error => {
        console.log('error', error)
        setIsLoading(false)
        setIsRefetching(false)
      });
  })

  const handleSearch = (e) => {
    // console.log(e.target.value,data)
    setRows(() => {
      return data.filter((item) => (item.org_name+item.hoabl_id+item.owner_name+item.contact_person_phone).toLowerCase().includes(e.target.value.toLowerCase()))
    })
  }
  return (
    <div style={{padding:"1rem 1rem"}}>
      <div style={{width:'100%' ,padding:"0 0 1rem 0", display:'flex', justifyContent: 'space-between'}}>
        {/* <Tooltip title="Search by Org Name, Owner Name, Hoabl Id, Phone">
          <TextField label="Search" onChange={handleSearch} style={{width:"250px"}} />
        </Tooltip> */}
        <div>

        </div>
        <div className="flex">
          <IconButton onClick={()=>setRefresh((prev) => (!prev))} sx={{marginRight:"1rem"}}><RefreshIcon/></IconButton>
          <Button sx={{marginRight:"1rem"}} className="registrationbutton"  variant="contained" startIcon={<ArrowBackIosNewIcon />} onClick={() => navigate(-1) } >Back</Button>
          {/* <Button className="registrationbutton"  variant="contained" onClick={() => navigate('create') } >Create</Button> */}
        </div>
      </div>
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        initialState={{ pagination: { pageSize: 5, pageIndex: 0 } }}
        columns={columns}
        data={rows}
        enableColumnOrdering
        enableEditing
        state={{
          isLoading: isLoading,
          showProgressBars: isRefetching,
        }}
        // onEditingRowSave={handleSaveRowEdits}
        // onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => {
          // console.log(row.original.id)
          return(
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement="left" title="Edit">
                <IconButton onClick={() => navigate(`edit/${row?.original.id}`)}>
                  <Edit />
                </IconButton>
              </Tooltip>
              {/* <Tooltip arrow placement="right" title="Delete">
                <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                  <Delete />
                </IconButton>
              </Tooltip> */}
              <IconsMenu row={row} statusEle={statusEle} handleStatusUpdate={handleStatusUpdate} />
            </Box>
          )
        }}
        
      />
    </div>
  )
}

export default ListCpMaster