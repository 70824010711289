import React,{ useState } from 'react'
import Grid  from "@mui/material/Grid"
import useMediaQuery from '@mui/material/useMediaQuery';
import { BrowserRouter , Route, Routes, Switch, Link, Redirect } from 'react-router-dom'
import CreateNetDeals from './CreateNetDeals';
import EditNetDeals from './EditNetDeals';
import ListNetDeals from './ListNetDealsV1';
import ShowNetDeals from './ShowNetDeals';



function NetDeals(props) {
  const matches = useMediaQuery('(min-width:1080px)');
  React.useEffect(() => {
    props.setIsSelected("Net Deals") 
    return () => {
      
    }
  }, [])
  return (
    <div style={{width:"100%",padding:"1rem"}}>
      <Routes>
        <Route exact path='/' element={<ListNetDeals />}/>
        <Route exact path='/create' element={<CreateNetDeals />}/>
        <Route exact path='/edit/:id' element={<EditNetDeals />}/>
        <Route exact path='/show/:id' element={<ShowNetDeals />}/>
      </Routes>
    </div>
  )
}

export default NetDeals