import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import "./index.css";
import useMediaQuery from '@mui/material/useMediaQuery';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { Navigate, useNavigate } from "react-router-dom";
// import Carousel from "react-simply-carousel";
import Chart from "react-apexcharts";

class ApexCharts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [{
        name: 'Applications',
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
      }, {
        name: 'Bookings',
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
      }],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
        },
        yaxis: {
          title: {
            text: ''
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val 
            }
          }
        }
      },
    }
  }

  componentDidMount() {

    let monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    let today = new Date()
    const currMonthConstant = today.getMonth()+1
    monthArr = monthArr.slice(currMonthConstant).map(item => item+'-'+String(today.getFullYear()-1).slice(2)).concat(monthArr.slice(0,currMonthConstant).map(item => item+'-'+String(today.getFullYear()).slice(2)))
    // console.log(monthArr.slice(today.getMonth()+1).map(item => item+'-'+String(today.getFullYear()-1).slice(2)).concat(monthArr.slice(0,today.getMonth()+1).map(item => item+'-'+String(today.getFullYear()).slice(2))))
    let tempApplications = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    this.props.totalCount?.application?.map(item => {
      let ind = monthArr.findIndex(ele => ele===item.monthYear)
      tempApplications[ind] =item.count 
      return item
    })
    let tempBookings = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    this.props.totalCount?.booking?.map(item => {
      let ind = monthArr.findIndex(ele => ele===item.monthYear)
      tempBookings[ind] =item.count 
      return item
    })

    let tempCategories = monthArr.slice(4)
    tempBookings = tempBookings.slice(4)
    tempApplications = tempApplications.slice(4)

    this.setState((prev)=>({
      ...prev,
      series: [{
        name: 'Applications',
        data: tempApplications
      }, {
        name: 'Bookings',
        data: tempBookings
      }],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: tempCategories,
        },
        yaxis: {
          title: {
            text: ''
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val 
            }
          }
        }
      }

    }))
  }


  render() {
    // console.log(this.props.totalCount)
    return (
      <Chart options={this.state?.options} series={this.state?.series} type="bar" width={"100%"} height={320} />
    )
  }
}

class ApexChartDonut extends React.Component {
  constructor(props) {
    super(props);
    const dummyData = [44, 55, 35]
    this.state = {
    
      series: dummyData,
      options: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: ['Invoice Raised', 'Payment Pending', 'Payment Paid'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      };
  }

  componentDidMount() {
    // console.log("ON__________mount",this.props.totalOfAmount)
    this.setState({series:this.props?.totalOfAmount})
  }


  render() {
    // console.log(this.state.series)
    return (
      <div id="chart">
        <Chart options={this.state.options} series={this.state.series} type="pie" width={"100%"} height={320}/>
      </div>
    );
  }
}

function Dashboard(props) {
  const [data, setData] = useState()
  const [totalCount, setTotalCount] = useState([])
  const [totalOfAmount, setTotalOfAmount] = useState([])
  const navigate = useNavigate()
  const matches = useMediaQuery('(max-width:600px)');
  const matchesLarge = useMediaQuery('(min-width:1200px)');
  let authToken;
  try{
    authToken = JSON.parse(localStorage.getItem('EMPLOYEE-AUTH'))
  }catch(err){
    authToken = null
  }

  React.useEffect(() => {
    props.setIsSelected("Dashboard");
    handleDataCall()
    return () => {};
  }, []);

  const handleDataCall = () => {
    var myHeaders2 = new Headers();
    myHeaders2.append("Content-Type", "application/json");
    myHeaders2.append("Authorization", "Bearer" +" "+ authToken?.token);

    var requestOptions2 = {
      method: 'GET',
      headers: myHeaders2,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+`/dashboard-details`, requestOptions2)
      .then(response => {
        console.log(response.status)
        if (response.status%400<10){
          NotificationManager.error('Not authorised to view this data', 'Error', 6000);
          throw new Error('Unauthorised role!!');
        }
        return response.json()
      })
      .then(result => {
        // console.log(totalCount,result)
        setData(result)
        
      })
      .catch(error => console.log('error', error));

    fetch(process.env.REACT_APP_BASE_URL+`/dashboard-details/total-count`, requestOptions2)
      .then(response => {
        console.log(response.status)
        if (response.status%400<10){
          NotificationManager.error('Not authorised to view this data', 'Error', 6000);
          throw new Error('Unauthorised role!!');
        }
        return response.json()
      })
      .then(result => {
        // console.log('totalCount',result)
        setTotalCount(result)
        
      })
      .catch(error => console.log('error', error));
    
    fetch(process.env.REACT_APP_BASE_URL+`/dashboard-details/total-of-amounts`, requestOptions2)
      .then(response => {
        console.log(response.status)
        if (response.status%400<10){
          NotificationManager.error('Not authorised to view this data', 'Error', 6000);
          throw new Error('Unauthorised role!!');
        }
        return response.json()
      })
      .then(result => {
        // console.log('totalOfAmount',result,[result?.invoice_raised, result?.payment_pending, result?.payment_paid])
        setTotalOfAmount([result.invoice_raised ? result.invoice_raised : 0, result.payment_pending ? result.payment_pending : 0, result.payment_paid ? result.payment_paid : 0])
        
      })
      .catch(error => console.log('error', error));
  }

  const PaymentStatChart = React.useCallback(
    () => {
      return <ApexChartDonut totalOfAmount={totalOfAmount} />
    },
    [totalOfAmount],
  )

  const MonthWiseStat = React.useCallback(
    () => {
      return <ApexCharts totalCount={totalCount} />
    },
    [totalCount],
  )
  

  return (
    <div style={{ padding: "1rem" }}>
      <Grid container spacing={2}>
        <Grid item md={3} sm={4} xs={12}>
          <div className="card card-body">
            <div
              style={{
                color: "#707070",
                fontSize: "16px",
                fontFamily: "'Futura Bold', sans-serif",
              }}
            >
              Applications
            </div>
            <h5
              style={{
                fontSize: "1.5rem",
                marginTop: "0",
                marginBottom: "0.5rem",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              {data?.applications?.count}
            </h5>
          </div>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <div className="card card-body">
            <div
              style={{
                color: "#707070",
                fontSize: "16px",
                fontFamily: "'Futura Bold', sans-serif",
              }}
            >
              Bookings
            </div>
            <h5
              style={{
                fontSize: "1.5rem",
                marginTop: "0",
                marginBottom: "0.5rem",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              {data?.bookings}
            </h5>
          </div>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <div className="card card-body">
            <div
              style={{
                color: "#707070",
                fontSize: "16px",
                fontFamily: "'Futura Bold', sans-serif",
              }}
            >
              Invoice Raised
            </div>
            <h5
              style={{
                fontSize: "1.5rem",
                marginTop: "0",
                marginBottom: "0.5rem",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              {data?.invoice_raised?.count}
            </h5>
          </div>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <div className="card card-body">
            <div
              style={{
                color: "#707070",
                fontSize: "16px",
                fontFamily: "'Futura Bold', sans-serif",
              }}
            >
              Total Paid
            </div>
            <h5
              style={{
                fontSize: "1.5rem",
                marginTop: "0",
                marginBottom: "0.5rem",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              {data?.total_paid?.total_paid ? data?.total_paid?.total_paid.toLocaleString("en-IN", {style:"currency", currency:"INR"}) : 0 }
            </h5>
          </div>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <PaymentStatChart />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <MonthWiseStat />
        </Grid>
      </Grid>
    </div>
  );
}

export default Dashboard;
