import React from 'react'
import { BrowserRouter , Route, Routes, Switch, Link, Redirect } from 'react-router-dom'
import { Navigate, useNavigate } from "react-router-dom";
import AppIncentive from './app_incentive'
import ConsumerDashboard from './consumer_dashboard'
import LadderIncentive from './ladder_incentive'
import NetDeals from './net_deals'
import LogoutIcon from '@mui/icons-material/Logout';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import NotFound from '../components/NotFound'
import useMediaQuery from '@mui/material/useMediaQuery';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import "./index.css"
import AdvancePayments from './adv-payments';
import jwt from 'jwt-decode'
import { IconButton } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Lead from './leads/Lead';
import ListLead from './leads/ListLead';
import Tooltip from '@mui/material/Tooltip';
import BulkLead from './leads/BulkLead';


function InfoPopperPopupState() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title="Help!!!">
        <IconButton 
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{marginRight:"1rem", color:'white'}} 
          variant="contained"
        >
          <InfoOutlinedIcon />
        </IconButton>
      </Tooltip>
      {/* <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Dashboard
      </Button> */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}><a href="tel:+918655829404" style={{all:'unset'}}><div style={{margin:'0.3rem', cursor:'pointer'}}><CallIcon /> Talk to Support</div></a></MenuItem>
        <MenuItem onClick={handleClose}><a href="mailto:channelpartnersupport@hoabl.in" style={{all:'unset'}}><div style={{margin:'0.3rem', cursor:'pointer'}}><EmailIcon /> Mail to Support</div></a> </MenuItem>
      </Menu>
    </div>
  );
}


function Consumer_front() {
  const matches = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  let authToken;
  try{
    authToken = JSON.parse(localStorage.getItem('CP-USER-AUTH'))
    // authToken = localStorage.getItem('CP-USER-AUTH')
    let decodedToken = jwt(authToken.token);
    // console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      localStorage.removeItem('CP-USER-AUTH');
      navigate("/login");
    }
  }catch(err){
    authToken = null
  }
  // console.log(authToken)
  if (!authToken){
    return <Navigate to="/login" replace />;
  }

  const handleLogout = () => {
    localStorage.removeItem('CP-USER-AUTH');
    navigate("/login");
  }

  return (
    <div className="Consumer-app">
      <div className="Nav-bar">
        <div>
          <img src="/images/hoabl_logo_white.png" alt="logo" style={{margin:"1rem",width:"5rem"}} />
        </div>
        <div style={{paddingTop:"0.8rem", flexGrow: 1, alignText: "center" }}>
          {matches ? (
            // <h4>{authToken?.info?.hoabl_id}</h4>
            ""
          ):(
            <h4>{authToken?.info?.org_name}: {authToken?.info?.hoabl_id}</h4>
          )}
        </div>
        <div style={{display:'flex', justifyContent:'center'}} >
          {/* <IconButton style={{marginRight:"1rem",color:'white'}} >
            <AddCircleOutlineIcon />
          </IconButton> */}
          {/* <Lead user={authToken} /> */}
          <BulkLead user={authToken} />
          <InfoPopperPopupState />
          <Tooltip title="Sign Out">
            <IconButton style={{marginRight:"1rem",color:'white'}} onClick={handleLogout} >
              <LogoutIcon/>
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className="Nav-bar-mobile">
        {matches ? (
            <h4>{authToken?.info?.org_name}: {authToken?.info?.hoabl_id}</h4>
          ):(
            // <h4>{authToken?.info?.hoabl_id}</h4>
            ""
          )}
      </div>
      <div className="Margin-top-2"></div>
      <Routes>
        <Route exact path='/' element={<ConsumerDashboard />}/>
        <Route exact path='/app-incentive' element={<AppIncentive />}/>
        <Route exact path='/netdeals' element={<NetDeals />}/>
        <Route exact path='/ladder-incentive' element={<LadderIncentive />}/>
        <Route exact path='/adv-payments' element={<AdvancePayments/>}/>
        <Route exact path='/leads' element={<ListLead />}/>
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </div>
  )
}

export default Consumer_front