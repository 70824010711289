import React,{ useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ImageIcon from '@mui/icons-material/Image';
import { Navigate, useNavigate } from "react-router-dom";
import { Button, TextField } from '@mui/material';
import { Link } from 'react-router-dom'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import InvoiceUpload from '../../components/InvoiceUpload';
import ImageModal from '../../components/ImageModal';
import Tooltip from '@mui/material/Tooltip';


const columns = [
  {
    id: "upload_invoice",
    label: "Invoice Document",
    minWidth: 100,
  },
  {
    id: "customer_name",
    label: "Customer Name",
    minWidth: 100,
  },
  {
    id: "unique_plot_number",
    label: "Plot Number",
    minWidth: 100,
  },
  {
    id: "eligibility_status",
    label: "Eligibility Status",
    minWidth: 100,
  },
  {
    id: "agreement_value",
    label: "Agreement Value",
    minWidth: 100,
  },
  {
    id: "eligibility_amount",
    label: "Eligibility Amount",
    minWidth: 100,
  },
  {
    id: "net_amount",
    label: "Net Amount",
    minWidth: 100,
  },
  {
    id: "tds_total",
    label: "TDS Amount",
    minWidth: 100,
  },
  {
    id: "gst_amount",
    label: "GST Amount",
    minWidth: 100,
  },
  {
    id: "advance_amount_adjusted",
    label: "Advance Amount Adjusted",
    minWidth: 100,
  },
  // {
  //   id: "invoice_status",
  //   label: "Invoice Status",
  //   minWidth: 100,
  // },
  {
    id: "payment_status",
    label: "Payment Status",
    minWidth: 100,
  },
  {
    id: "remark",
    label: "Remark",
    minWidth: 100,
  },
];


const CustomButton = ({ field, form, ...props }) => {
  return (
    <button className="registrationbutton" {...field} {...props} >
      {props.children}
    </button>
  );
};

function DataList(props) {
  const { data, rows, hoabl_id, handleDataCall, setRows, setData, projectName,launchName } = props
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate()
  let authToken;
  try{
    authToken = JSON.parse(localStorage.getItem('CP-USER-AUTH'))
  }catch(err){
    authToken = null
  }
  
  React.useEffect(() => {
    
    setPage(0)
    setRows(() => {
      return data.filter((item) => ((item.project_name).toLowerCase().includes(props.projectName.toLowerCase()) && (item.launch_name).toLowerCase().includes(props.launchName.toLowerCase())))
    })
  }, [props.projectName,props.launchName])
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };


  const invoiceDocumentRender = (data) => {
    // console.log(data)
    if (data?.invoice_status_info === "REJECTED"){
      return (
        <div style={{display:"flex"}}>
          <InvoiceUpload ls_token_key="CP-USER-AUTH" type="ladder_incentive" type_id={data.id} handleDataRefresh={handleDataCall} hoabl_id={data.hoabl_id} invoice_value={data.eligibility_amount} />
          <ImageModal url={data.invoice_img_url} ls_token_key={"CP-USER-AUTH"} />
        </div>
      )
    }
    if (data.invoice_img_url){
      return <ImageModal url={data.invoice_img_url} ls_token_key={"CP-USER-AUTH"} />
    }

    return <InvoiceUpload ls_token_key="CP-USER-AUTH" type="ladder_incentive" type_id={data.id} handleDataRefresh={handleDataCall} hoabl_id={data.hoabl_id} invoice_value={data.eligibility_amount} />
  }

  const handleSearch = (e) => {
    // console.log(e.target.value,data)
    setPage(0)
    setRows(() => {
      return data.filter((item) => (item.project_name+item.launch_name+item.eligibility_status+item.customer_name+item.unique_plot_number).toLowerCase().includes(e.target.value.toLowerCase()))
    })
  }
  return (
    <div style={{padding:"1rem 0"}}>
      <div style={{width:'100%' ,padding:"0 0 1rem 0", display:'flex', justifyContent: 'space-between'}}>
        <Tooltip title="Search by Eligibility Status, Customer, Plot Number">
          <TextField label="Search" onChange={handleSearch} style={{width:"250px"}} />
        </Tooltip>
        {/* <Button className="registrationbutton"  variant="contained" onClick={() => navigate('create') } >Create</Button> */}
      </div>
      <TableContainer sx={{ maxHeight: '80vh' }} >
        <Table stickyHeader aria-label="sticky table" >
          <TableHead >
            <TableRow >
              {columns?.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth,}}
                  className="clientthtcell"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];

                      if (column.id === 'upload_invoice'){
                        return (
                          <TableCell>
                            {/* <InvoiceUpload ls_token_key="CP-USER-AUTH" type="ladder_incentive" type_id={row.id} handleDataRefresh={handleDataCall} hoabl_id={authToken?.info?.hoabl_id} /> */}
                            {invoiceDocumentRender(row)}
                          </TableCell>
                        )
                      }
                      
                      if (column.label === 'Action'){
                        // console.log(row['hoabl_id'])
                        return(
                          <TableCell key={column.id} align={column.align}>
                            <Link to={`edit/${row['id']}`} style={{all:'unset'}}>
                              <Button>Edit</Button>
                            </Link>
                          </TableCell>
                        )
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  )
}

export default DataList