import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Formik, Form, Field } from "formik";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import CircularProgress from '@mui/material/CircularProgress';
import BackupIcon from '@mui/icons-material/Backup';
import { IconButton } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { NotificationManager } from 'react-notifications';
import { Link } from "react-router-dom"
import * as yup from "yup";
import Tooltip from '@mui/material/Tooltip';

const leadSchema = yup.object().shape({
    hoabl_id: yup.string().required("This is Required"),
    project_name: yup.object().required("This is Required"),
    launch_name: yup.object().required("This is Required"),
    lead_upload: yup.mixed().required("You need to provide a file")
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const TextInput = ({ field, form, ...props }) => {
    return (
        <TextField
            {...field}
            {...props}
            error={
                (form?.touched[field?.name] && form?.errors[field?.name]) ||
                (form?.errors[field?.name] && form?.submitCount > 0)
            }
            helperText={form?.touched[field?.name] && form?.errors[field?.name]}
        />
    );
};

const CustomButton = ({ field, form, ...props }) => {
    return (
        <button className="registrationbuttonclient" {...field} {...props} >
            {props.children}
        </button>
    );
};

function BulkLead({ user }) {
    const [open, setOpen] = React.useState(false);
    const [choices, setChoices] = React.useState({
        hoabl_id: null,
        project_name: [],
        launch: []
    });
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    let [isloading, setIsloading] = React.useState(false);

    React.useEffect(() => {
        handleDataCall()
        return () => {
        }
    }, [])

    const handleDataCall = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + user.token);

        var requestOptions = {
            method: 'get',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_BASE_URL + "/crm-project", requestOptions)
            .then(result => {
                // console.log(result.status)
                if (result.status % 400 < 10) {
                    return NotificationManager.error('Not able to get Projects data', 'Error', 6000);
                }
                return result.json()
            })
            .then(resp => {
                setChoices(prev => ({ ...prev, project_name: resp.results }))
            })
            .catch(error => {
                console.log('error', error)
                NotificationManager.error('Something went wrong', 'Error', 6000);

            });

        fetch(process.env.REACT_APP_BASE_URL + "/crm-launch", requestOptions)
            .then(result => {
                // console.log(result.status)
                if (result.status % 400 < 10) {
                    return NotificationManager.error('Not able to get Projects data', 'Error', 6000);
                }
                return result.json()
            })
            .then(resp => {
                setChoices(prev => ({ ...prev, launch_name: resp.results }))
            })
            .catch(error => {
                console.log('error', error)
                NotificationManager.error('Something went wrong', 'Error', 6000);

            });

    }

    const handleSubmit = async (values, actions, errors) => {
        let fileInput = values.lead_upload;

        var formdata = new FormData();
        formdata.append("file", fileInput, fileInput.name.replaceAll(" ", "_"));
        formdata.append("project", values.project_name?.hoabl_projectid);
        formdata.append("launch", values.launch_name?.hoabl_launchphaseid);
        formdata.append("project_name", values.project_name?.hoabl_name);
        formdata.append("launch_name", values.launch_name?.hoabl_launchname);

        let hoablId = values.hoabl_id;

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + user.token);

        var data = JSON.stringify({
            "hoabl_id": hoablId,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: data,
            redirect: 'follow',
            cors: 'no-cors'
        };

        const result = await fetch(process.env.REACT_APP_BASE_URL + "/cp-master-details/is-hoabl-id-available", requestOptions)
        if (result.status === 400) {
            NotificationManager.error("HOABL ID doesn't exist, Please check", 'Error', 6000)
        } else if (result.status === 200) {
            let resultData = await result.json();
            formdata.append("cp_detail", resultData?.crm_id);
            formdata.append("hoabl_id", resultData?.hoabl_id);

            myHeaders.delete("Content-Type");

            var requestOptionsInner = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_BASE_URL + "/lead/upload", requestOptionsInner)
                .then(async response => {
                    if (response.status % 400 < 10) {
                        return NotificationManager.error('Not authorised to edit the data', 'Error', 6000);
                    }
                    let responseData = await response.json();
                    if (response.status == 500) {
                        NotificationManager.error(responseData?.message, 'Error', 6000);
                    } else {
                        NotificationManager.success(responseData?.message, 'Success', 6000);
                    }
                    setIsloading(false)
                    handleClose()
                })
                .catch(error => {
                    NotificationManager.error("Something Went Wrong", 'Error', 6000);
                    setIsloading(false)
                });
        }
        handleClose()
    }

    return (
        <div>
            <Tooltip title="Bulk Lead Create">
                <IconButton style={{ marginRight: "1rem" }} onClick={handleOpen} >
                    <BackupIcon />
                </IconButton>
            </Tooltip>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Formik
                        initialValues={{}}
                        validationSchema={leadSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ touched, errors, values, submitCount, setFieldValue }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item md={12} xs={12}>
                                        <Typography id="modal-modal-title" variant="h6" component="h2">
                                            Leads Upload
                                        </Typography>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Field fullWidth name="hoabl_id" label="Hoabl Id" component={TextInput} required />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Autocomplete
                                            id="project_name"
                                            className="country-select"
                                            name="project_name"
                                            options={choices?.project_name?.filter(item => item.statecode === false)}
                                            getOptionLabel={option => option.hoabl_name}
                                            onChange={(e, value) => {
                                                // console.log(value)
                                                setFieldValue("project_name", value)
                                                setFieldValue("project", value.hoabl_projectid)
                                            }}
                                            renderInput={params => (
                                                <Field component={TextInput} {...params} required name="project_name" label="Project Name" fullWidth />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Autocomplete
                                            id="launch_name"
                                            className="country-select"
                                            name="launch_name"
                                            disabled={!values.project_name}
                                            options={choices?.launch_name?.filter(item => item.hoabl_Project_hoabl_projectid === values.project_name?.hoabl_projectid)}
                                            getOptionLabel={option => option.hoabl_launchname}
                                            onChange={(e, value) => {
                                                // console.log(value)
                                                setFieldValue("launch_name", value)
                                                setFieldValue("launch", value.hoabl_launchphaseid)
                                            }}
                                            renderInput={params => (
                                                <Field component={TextInput} {...params} required name="launch_name" label="Launch Name" fullWidth />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item md={4} xs={6} align="center">
                                        <div style={{ padding: "1rem 0 1rem 0", display: 'inline' }}>
                                            <Link to="https://cp-portal-public.s3.ap-south-1.amazonaws.com/leads_sample.csv" target="_blank" download>leads_sample.csv</Link>
                                        </div>
                                    </Grid>
                                    <Grid item md={8} xs={6} align="center">
                                        <div style={{ padding: "1rem 0 1rem 0", display: 'inline' }}>
                                            <label htmlFor="lead_upload">
                                                <img src="/images/xlsx_icon.png" style={{ height: '40px', alignSelf: 'right', marginRight: '1rem', cursor: 'pointer' }} /><span style={{ color: "rgb(211,47,47)" }}>*</span>
                                            </label>
                                            <input
                                                type="file"
                                                accept=".csv"
                                                id="lead_upload"
                                                name="lead_upload"
                                                onChange={(event) => {
                                                    setFieldValue(
                                                        "lead_upload",
                                                        event.currentTarget.files[0]
                                                    );
                                                }}
                                                hidden
                                                required
                                            />
                                            {values?.lead_upload && <label style={{ margin: "1rem 0 1rem 0" }} >{values.lead_upload.name}</label>}
                                        </div>

                                    </Grid>
                                    <Grid item md={8} xs={0}></Grid>
                                    <Grid item md={4} xs={12}>
                                        <Field type="submit" component={CustomButton}>
                                            {isloading ? <CircularProgress sx={{ color: "#FFFFFF" }} size="1.5rem" /> : "Submit"}
                                        </Field>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Modal>
        </div >
    );
}

export default BulkLead