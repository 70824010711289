export const imagesArray = [
  // "url('https://partners-img-collection.s3.ap-south-1.amazonaws.com/ayodhyacover.jpg')",
  // "url('https://partners-img-collection.s3.ap-south-1.amazonaws.com/gulf_of_goacover.jpg')",
  // "url('https://partners-img-collection.s3.ap-south-1.amazonaws.com/codename_goa_cover.jpg')",
  "url('https://partners-img-collection.s3.ap-south-1.amazonaws.com/cp_portal_banner.jpg')",


]


// export const imagesArray = [
//   "url('/images/ayodhyacover.jpg')",
//   "url('/images/gulf_of_goacover.jpg')",
// ]
