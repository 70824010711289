import React,{ useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ImageIcon from '@mui/icons-material/Image';
import { Navigate, useNavigate } from "react-router-dom";
import { Button, TextField } from '@mui/material';
import { Link } from 'react-router-dom'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import ImageModal from '../../components/ImageModal';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import DataList_v2 from './DataList';

const columns = [
  {
    id: "invoice_value",
    label: "Invoice Value",
    minWidth: 150,
  },
  {
    id: "invoice_no",
    label: "Invoice No",
    minWidth: 100,
  },
  {
    id: "invoice_date",
    label: "Invoice Date",
    minWidth: 150,
  },
  {
    id: "invoice_url",
    label: "Invoice Url",
    minWidth: 150,
  },
  {
    id: "type",
    label: "Type",
    minWidth: 150,
  },
  {
    id: "type_id",
    label: "Type Id",
    minWidth: 150,
  },
  {
    id: "hoabl_id",
    label: "Hoabl Id",
    minWidth: 150,
  },
  {
    id: "payment_mode",
    label: "Payment Mode",
    minWidth: 150,
  },
  {
    id: "payment_channel_details",
    label: "Payment Channel Details",
    minWidth: 150,
  },
  {
    id: "invoice_status",
    label: "Invoice Status",
    minWidth: 150,
  },
  {
    id: "payment_status",
    label: "Payment Status",
    minWidth: 150,
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 150,
  },
];


const CustomButton = ({ field, form, ...props }) => {
  return (
    <button className="registrationbutton" {...field} {...props} >
      {props.children}
    </button>
  );
};

function ListMiscellaneous() {
  const [data, setData] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [projectName, setProjectName] = useState("")
  const [launchName, setLaunchName] = useState("")
  const [filter, setFilter] = useState("")
  const matches = useMediaQuery('(min-width:1080px)');
  const navigate = useNavigate()
  let authToken;
  try{
    authToken = JSON.parse(localStorage.getItem('EMPLOYEE-AUTH'))
  }catch(err){
    authToken = null
  }
    // console.log(props)
  React.useEffect(() => {
    if(filter.length === 11){
      handleDataCall()
    }

  }, [filter])
  

  const handleDataCall = () => {
    // let authToken = JSON.parse(localStorage.getItem('USER'))
    // console.log(authToken)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer" +" "+ authToken?.token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+`/client/app-incentive-details?hoabl_id=${filter}`, requestOptions)
      .then(response => {
        console.log(response.status)
        if (response.status%400<10){
          NotificationManager.error('Not authorised to view this data', 'Error', 6000);
          throw new Error('Unauthorised role!!');
        }
        return response.json()
      })
      .then(result => {
        // console.log(result.status)
        // console.log(JSON.stringify(result.results))
        setData(result.results)
        setRows(result.results)
      })
      .catch(error => console.log('error', error));
  }

  const handleSearch = (e) => {
    // console.log(e.target.value,data)
    setFilter(e.target.value)
    
  }
  return (
    <div style={{padding:"1rem 1rem"}}>
      <div style={{width:'100%' ,padding:"0 0 1rem 0", display:'flex', justifyContent: 'space-between'}}>
        <Tooltip title="Search by Hoabl Id">
          <TextField label="Search" value={filter} onChange={handleSearch} style={{width:"250px"}} />
        </Tooltip>
        <div>
          <Button sx={{marginRight:"1rem"}} className="registrationbutton"  variant="contained" startIcon={<ArrowBackIosNewIcon />} onClick={() => navigate(-1) } >Back</Button>
          {/* <Button className="registrationbutton"  variant="contained" onClick={() => navigate('create') } >Create</Button> */}
        </div>
      </div>
      <DataList_v2
        projectName={projectName}
        launchName={launchName}
        data={data}
        rows={rows}
        hoabl_id={filter}
        setRows={setRows}
        setData={setData}
        handleDataCall={handleDataCall}
      />
    </div>
  )
}

export default ListMiscellaneous