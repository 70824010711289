import React from 'react'
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { BrowserRouter , Route, Routes, Switch, Link, Redirect } from 'react-router-dom'
import Avatar from '@mui/material/Avatar';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import { permissions } from './utility/permissions';


const menulist = [
  {
    name:"Dashboard",
    subItems: [],
    open: false,
    img_obj: "/images/dashboard.png",
    url: ""
  },
  {
    name: "Application Details",
    subItems: [],
    open: false,
    url: 'application-details',
    img_obj: '/images/applications.png'
  },
  {
    name:"Net Deals",
    subItems: [],
    open: false,
    url: 'netdeals',
    img_obj: '/images/brokerage.png'
  },
  {
    name:"Ladder Incentive",
    subItems: [],
    open: false,
    img_obj: "/images/ladder.png",
    url: "ladder-incentive"
  },
  {
    name:"Advance Payments",
    subItems: [],
    open: false,
    img_obj: "/images/AdvancePayments.png",
    url: "adv-payments"
  },
  {
    name:"Employee Details",
    subItems: [],
    open: false,
    img_obj: null,
    icon: <BadgeOutlinedIcon fontSize="small" sx={{color:'#161617'}} />,
    url: "employee"
  },
  {
    name:"CP Master",
    subItems: [],
    open: false,
    img_obj: null,
    icon: <HandshakeOutlinedIcon fontSize="small" sx={{color:'#161617'}} />,
    url: "cp-master"
  },
  {
    name:"Leads",
    subItems: [],
    open: false,
    img_obj: null,
    icon: <PeopleOutlineIcon fontSize="small" sx={{color:'#161617'}} />,
    url: "leads"
  },
  {
    name:"Projects",
    subItems: [],
    open: false,
    img_obj: null,
    icon: <LaunchOutlinedIcon fontSize="small" sx={{color:'#161617'}}/>,
    url: "projects"
  },
  {
    name:"Invoices",
    subItems: [],
    open: false,
    img_obj: null,
    icon: <ReceiptOutlinedIcon fontSize="small" sx={{color:'#161617'}} />,
    url: "invoices"
  },
  {
    name:"Feedback",
    subItems: [],
    open: false,
    img_obj: null,
    icon: <FeedbackOutlinedIcon fontSize="small" sx={{color:'#161617'}} />,
    url: "feedback"
  },
  {
    name:"Miscellaneous",
    subItems: [],
    open: false,
    img_obj: null,
    icon: <MiscellaneousServicesIcon fontSize="small" sx={{color:'#161617'}} />,
    url: "miscellaneous"
  },
]


function Menu(props) {
  const [menus, setMenus] = React.useState([]);


  React.useEffect(() => {
    menuCheck()
  
    return () => {
    }
  }, [])
  

  const menuCheck = () => {
    if (permissions.accounts(props.userMail)){
      let tempArr = menulist.filter(item => item.name !== 'Feedback' && item.name !== 'Miscellaneous' && item.name !== 'Projects' && item.name !== 'Employee Details')
      setMenus(tempArr)
    }else{
      setMenus(menulist)
    }
  }

  // const handleClick = () => {
  //   setOpen(!open);
  // };


  return (
    <List
      sx={{ width: '100%', maxWidth: 360,  }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {menus.map(item => (
        <Link key={item.url} to={item.url} style={{all:'unset'}}>
          <ListItemButton sx={{background:(props.isSelected === item.name) ? "rgba(214, 214, 214, 0.3)" : "transparent"}}  >
            {/* <ListItemIcon sx={{color:"white", width:"15px"}}> */}
              {/* <HandshakeIcon sx={{color:"white",margin:"0.5rem 0.7rem 0.5rem 0rem"}}/> */}
              <Avatar sx={{ width: '30px', height: '30px' }} style={{margin:"0rem 0.7rem 0rem 0rem"}} >
                {item.img_obj ? (
                  <img src={item.img_obj} alt={item.name} style={{ width:"1rem"}} />
                ):(
                  item.icon
                )}
              </Avatar>
            {/* </ListItemIcon> */}
            <ListItemText primary={item.name} />
            {
              item.subItems.length > 0 && (item.open ? <ExpandLess /> : <ExpandMore />)
            }
          </ListItemButton>
          {item.subItems.map(ele => (
            <Collapse in={item.open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <StarBorder />
                  </ListItemIcon>
                  <ListItemText primary={ele?.name} />
                </ListItemButton>
              </List>
            </Collapse>
          ))}
        </Link>
      ) )}
    </List>
  )
}

export default Menu