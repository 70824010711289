import React, { useState, useEffect } from 'react'
import { Formik, Form, Field, useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {useNavigate, useParams} from "react-router-dom"
import CircularProgress from '@mui/material/CircularProgress';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const employeeSchema = yup.object().shape({
  name: yup.string().required("This is Required"),
  emp_code: yup.string().required("This is Required"),
  department: yup.string().required("This is Required"),
  // password: yup.string().required("This is Required"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("This is Required"),
  phone: yup
    .string()
    .matches(phoneRegExp, { message: "Not a valid Phone Number" })
    .min(10)
    .max(10)
    .required("This is Required"), 
});

const TextInput = ({ field, form, ...props }) => {
  // if (field.name === "org_name") {
  //   console.log(field, form);
  // }

  return (
    <TextField
      {...field}
      {...props}
      error={
        (form?.touched[field?.name] && form?.errors[field?.name]) ||
        (form?.errors[field?.name] && form?.submitCount > 0)
      }
      helperText={form?.touched[field?.name] && form?.errors[field?.name]}
    />
  );
};

const SelectInput = ({ field, form, ...props }) => {
  // if (field.name === "org_name") {
  //   console.log(field, form);
  // }

  return (
    <input
      {...field}
      {...props}
      error={
        (form?.touched[field?.name] && form?.errors[field?.name]) ||
        (form?.errors[field?.name] && form?.submitCount > 0)
      }
      helperText={form?.touched[field?.name] && form?.errors[field?.name]}
      type='select'
      className="select"
    >
      {props.children}
    </input>
  );
};

const CustomButton = ({ field, form, ...props }) => {
  return (
    <button className="registrationbutton" {...field} {...props} >
      {props.children}
    </button>
  );
};

function EditEmployee() {
  const [isloading, setIsloading] = useState(false);
  const [initialValue, setInitialValue] = useState({})
  const navigate = useNavigate();
  let {id} = useParams()

  let authToken;
  try{
    authToken = JSON.parse(localStorage.getItem('EMPLOYEE-AUTH'))
  }catch(err){
    authToken = null
  }

  useEffect(() => {
    fetchDataById()

    return () => {
      
    }
  }, [])

  const fetchDataById = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer" +" "+ authToken.token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/employee-details/"+id, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        setInitialValue(result)
        // setRows(result.channel_partner)
      })
      .catch(error => console.log('error', error));
  }


  const handleEdit = (values, actions, errors) => {
    setIsloading(true)
    // console.log(values, actions, errors);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer "+authToken.token);

    var raw = JSON.stringify({
      name: values.name,
      emp_code: values.emp_code,
      department: values.department,
      // password: values.password,
      email: values.email,
      phone: values.phone,
    });

    var requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/employee-details/"+id, requestOptions)
      .then(result => {
        // console.log(result.status)
        if (result.status%400<10){
          NotificationManager.error('Not authorised to edit the data', 'Error', 6000);
        }
        setIsloading(false)
        navigate("/admin/employee")
      })
      .catch(error => {
        console.log('error', error)
        NotificationManager.error('Something went wrong', 'Error', 6000);
        setIsloading(false)
      });
    
  };

  return (
    <div>
      {initialValue?.name && (
      <Formik
        initialValues={initialValue}
        validationSchema={employeeSchema}
        onSubmit={handleEdit}
      >
        {({ touched, errors, values, submitCount, setFieldValue, handleSubmit,submitForm }) => (
          <Form  >
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <div style={{ padding: "1rem", display:'flex', justifyContent:'space-between' }}>
                  <h4 style={{ font: "Jost" }}>Edit Employee</h4>
                  <Button className="registrationbutton"  variant="contained" startIcon={<ArrowBackIosNewIcon />} onClick={() => navigate(-1) } >Back</Button>
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="name"
                  label="Name"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={3} xs={6}>
                <div style={{ padding: "1rem" }}>
                  <p>Department:</p>
                </div>
              </Grid>
              <Grid item md={3} xs={6}>
                <Field
                  as="select"
                  label="Department"
                  name="department"
                  className="select"
                  style={
                    (touched?.department && errors?.department) ||
                    (errors?.department && submitCount)
                      ? {
                          borderColor: "rgb(211,47,47)",
                          color: "rgb(211,47,47)",
                        }
                      : {}
                  }
                >
                  <option value="">Choose...</option>
                  <option value="Tech">Tech</option>
                  <option value="Accounts">Accounts</option>
                  <option value="Channel_Partner_Support">Channel Partner Support</option>
                  <option value="Sales_Strategy">Sales Strategy</option>
                  <option value="Sales">Sales</option>
                </Field>
                {touched?.department && errors?.department && (
                  <p className="error">{errors?.department}</p>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="email"
                  label="Email"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="phone"
                  label="Phone"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="emp_code"
                  label="Employee Code"
                  fullWidth
                  component={TextInput}
                />
              </Grid> 
              <Grid item md={12} xs={12}>
                <Field style={{width:'100%'}} id="submitEdit" name="submitEdit" type="submit"  component={CustomButton}>
                    {isloading ? <CircularProgress sx={{color:"#FFFFFF"}} size="1.5rem"/>:"Edit"}
                </Field>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      )}
    </div>
  )
}

export default EditEmployee