import React,{ useState } from 'react'
import Grid  from "@mui/material/Grid"
import useMediaQuery from '@mui/material/useMediaQuery';
import { BrowserRouter , Route, Routes, Switch, Link, Redirect } from 'react-router-dom'
import { Edit, Close } from "@mui/icons-material";
import CreateCpMaster from './CreateCpMaster';
import EditCpMaster from './EditCpMaster_v2';
import ListCpMaster from './ListCpMasterV1';
import ShowCpMaster from './ShowCpMaster';



function CpMaster(props) {
  const matches = useMediaQuery('(min-width:1080px)');
  React.useEffect(() => {
    props.setIsSelected("CP Master") 
    return () => {
      
    }
  }, [])
  // console.log(props)
  return (
    <div style={{padding:"1rem"}}>
      <Routes>
        <Route exact path='/' element={<ListCpMaster />}/>
        <Route exact path='/create' element={<CreateCpMaster />}/>
        <Route exact path='/edit/:id' element={<EditCpMaster />}/>
        <Route exact path='/show/:id' element={<ShowCpMaster />}/>
      </Routes>
    </div>
  )
}

export default CpMaster