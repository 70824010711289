import React,{ useState } from 'react'
import Grid  from "@mui/material/Grid"
import useMediaQuery from '@mui/material/useMediaQuery';
import { BrowserRouter , Route, Routes, Switch, Link, Redirect } from 'react-router-dom'
import CreateProjects from './CreateProjects';
import EditProjects from './EditProjects';
import ListProjects from './ListProjectsV1';
import ShowProjects from './ShowProjects';

function Projects(props) {
  React.useEffect(() => {
    props.setIsSelected("Projects") 
    return () => {
      
    }
  }, [])
  
  return (
    <div style={{width:"100%",padding:"1rem"}}>
      <Routes>
        <Route exact path='/' element={<ListProjects />}/>
        <Route exact path='/create' element={<CreateProjects />}/>
        <Route exact path='/edit/:id' element={<EditProjects />}/>
        <Route exact path='/show/:id' element={<ShowProjects />}/>
      </Routes>
    </div>
  )
}

export default Projects