import React, { useState, useEffect, useMemo } from "react";
import { Formik, Form, Field, useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom"
import CircularProgress from '@mui/material/CircularProgress';
import { NotificationManager } from 'react-notifications';
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const leadSchema = yup.object().shape({
    firstname: yup.string().required("This is Required"),
    lastname: yup.string().required("This is Required"),
    emailaddress1: yup
        .string()
        .email("Please enter a valid email")
        .required("This is Required"),
    mobilephone: yup
        .string()
        .matches(phoneRegExp, { message: "Not a valid Phone Number" })
        .min(10)
        .max(10)
        .required("This is Required"),
});

const TextInput = ({ field, form, ...props }) => {
    return (
        <TextField
            {...field}
            {...props}
            error={
                (form?.touched[field?.name] && form?.errors[field?.name]) ||
                (form?.errors[field?.name] && form?.submitCount > 0)
            }
            helperText={form?.touched[field?.name] && form?.errors[field?.name]}
        />
    );
};

const CustomButton = ({ field, form, ...props }) => {
    return (
        <button className="registrationbutton" {...field} {...props} >
            {props.children}
        </button>
    );
};

function EditLeadMaster() {
    let [isloading, setIsloading] = useState(false);
    const [initialValue, setInitialValue] = useState({})
    const navigate = useNavigate();

    let { id } = useParams()

    let authToken;
    try {
        authToken = JSON.parse(localStorage.getItem('EMPLOYEE-AUTH'))
    } catch (err) {
        authToken = null
    }

    useEffect(() => {
        fetchDataById()
        return () => {
        }
    }, [])


    const fetchDataById = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer" + " " + authToken.token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_BASE_URL + "/lead/" + id, requestOptions)
            .then(response => response.json())
            .then(result => {
                setInitialValue(result)
            })
            .catch(error => console.log('error', error));
    }

    const handleEdit = (values, actions, errors) => {
        setIsloading(true)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + authToken.token);

        var raw = JSON.stringify({
            firstname: values.firstname,
            lastname: values.lastname,
            emailaddress1: values.emailaddress1,
            mobilephone: values.mobilephone,
        });

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_BASE_URL + "/lead/" + id, requestOptions)
            .then(result => {
                if (result.status % 400 < 10) {
                    NotificationManager.error('Not authorised to edit the data', 'Error', 6000);
                }
                setIsloading(false)
                navigate("/admin/leads")
            })
            .catch(error => {
                console.log('error', error)
                NotificationManager.error('Something went wrong', 'Error', 6000);
                setIsloading(false)
            });
    };

    return (
        <div>
            {initialValue?.firstname && (
                <Formik
                    initialValues={initialValue}
                    validationSchema={leadSchema}
                    onSubmit={handleEdit}
                >
                    {({ touched, errors, values, submitCount, setFieldValue, handleSubmit, submitForm }) => (
                        <Form  >
                            <Grid container spacing={2}>
                                <Grid item md={12} xs={12}>
                                    <div style={{ padding: "1rem", display: 'flex', justifyContent: 'space-between' }}>
                                        <h4 style={{ font: "Jost" }}>Edit Lead</h4>
                                        <Button className="registrationbutton" variant="contained" startIcon={<ArrowBackIosNewIcon />} onClick={() => navigate(-1)} >Back</Button>
                                    </div>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field
                                        type="text"
                                        name="firstname"
                                        label="First Name"
                                        fullWidth
                                        component={TextInput}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field
                                        type="text"
                                        name="lastname"
                                        label="Last Name"
                                        fullWidth
                                        component={TextInput}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field
                                        type="text"
                                        name="emailaddress1"
                                        label="Email"
                                        fullWidth
                                        component={TextInput}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field
                                        type="text"
                                        name="mobilephone"
                                        label="Mobile Phone"
                                        fullWidth
                                        component={TextInput}
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <Field style={{ width: '100%' }} id="submitEdit" name="submitEdit" type="submit" component={CustomButton}>
                                        {isloading ? <CircularProgress sx={{ color: "#FFFFFF" }} size="1.5rem" /> : "Edit"}
                                    </Field>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
}

export default EditLeadMaster