import React,{ useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ImageIcon from '@mui/icons-material/Image';
import { Navigate, useNavigate } from "react-router-dom";
import { Button, TextField } from '@mui/material';
import { Link } from 'react-router-dom'
import {NotificationContainer, NotificationManager} from 'react-notifications';


const columns = [
  {
    id: "application_id",
    label: "Application_id",
    minWidth: 150,
  },
  {
    id: "application_date",
    label: "Application_date",
    minWidth: 100,
  },
  {
    id: "customer_name",
    label: "Customer_Name",
    minWidth: 150,
  },
  {
    id: "cp_firm_name",
    label: "Cp_Firm_Name",
    minWidth: 150,
  },
  {
    id: "eligibilty_status",
    label: "Eligibilty_Status",
    minWidth: 150,
  },
  {
    id: "eligible_amount",
    label: "Eligible_amount",
    minWidth: 150,
  },
  {
    id: "gst_amount",
    label: "Gst_amount",
    minWidth: 150,
  },
  {
    id: "gst_type",
    label: "Gst_type",
    minWidth: 150,
  },
  {
    id: "hoabl_id",
    label: "Hoabl_id",
    minWidth: 150,
  },
  {
    id: "incentive_amount",
    label: "Incentive_amount",
    minWidth: 150,
  },
  {
    id: "invoice_id",
    label: "Invoice_id",
    minWidth: 150,
  },
  {
    id: "invoice_status",
    label: "Invoice_status",
    minWidth: 150,
  },
  {
    id: "launch_name",
    label: "Launch_name",
    minWidth: 150,
  },
  {
    id: "net_amount",
    label: "Net_amount",
    minWidth: 150,
  },
  {
    id: "payment_status",
    label: "Payment_status",
    minWidth: 150,
  },
  {
    id: "project_name",
    label: "Project_name",
    minWidth: 150,
  },
  {
    id: "tds",
    label: "Tds",
    minWidth: 150,
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 150,
  },
];


const CustomButton = ({ field, form, ...props }) => {
  return (
    <button className="registrationbutton" {...field} {...props} >
      {props.children}
    </button>
  );
};

function DataList() {
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const navigate = useNavigate()
  // let authToken;
  // try{
  //   authToken = JSON.parse(localStorage.getItem('USER'))
  // }catch(err){
  //   authToken = null
  // }
  
  React.useEffect(() => {
    
    handleDataCall()

  }, [])
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleDataCall = () => {
    // let authToken = JSON.parse(localStorage.getItem('USER'))
    // console.log(authToken)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Authorization", "Bearer" +" "+ authToken.token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/app-incentive-details", requestOptions)
      .then(response => {
        console.log(response.status)
        if (response.status%400<10){
          NotificationManager.error('Not authorised to view this data', 'Error', 6000);
          throw new Error('Unauthorised role!!');
        }
        return response.json()
      })
      .then(result => {
        // console.log(result.status)
        // console.log(JSON.stringify(result.results))
        setData(result.results)
        setRows(result.results)
      })
      .catch(error => console.log('error', error));
  }

  const handleSearch = (e) => {
    
  }
  return (
    <div style={{padding:"1rem 0"}}>
      <div style={{width:'100%' ,padding:"0 0 1rem 0", display:'flex', justifyContent: 'space-between'}}>
        <TextField label="Search" onChange={handleSearch} style={{width:"250px"}} />
        {/* <Button className="registrationbutton"  variant="contained" onClick={() => navigate('create') } >Create</Button> */}
      </div>
      <TableContainer sx={{ maxHeight: '80vh' }} >
        <Table stickyHeader aria-label="sticky table" >
          <TableHead >
            <TableRow >
              {columns?.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, backgroundColor:"grey"}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      
                      if (column.label === 'Action'){
                        // console.log(row['hoabl_id'])
                        return(
                          <TableCell key={column.id} align={column.align}>
                            <Link to={`edit/${row['id']}`} style={{all:'unset'}}>
                              <Button>Edit</Button>
                            </Link>
                          </TableCell>
                        )
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  )
}

export default DataList