import './App.css';
import { BrowserRouter , Route, Routes, Switch, Link } from 'react-router-dom'
import NotFound from './components/NotFound'
import Consumer_front from './consumer_front';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Thanks from './components/Thanks'
import Error from './components/Error'
import Layout from './admin/Layout'
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import EmpLogin from './auth/EmpLogin';
import Login from './auth/Login';
import Signup from './auth/Signup';

function App() {
  return (
    <div className="App">
      <BrowserRouter >
        <Routes>
          <Route exact path='/*' element={<Consumer_front />}/>
          <Route exact path='/admin/*' element={<Layout />} />
          <Route path="/auth" element={<EmpLogin />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path='/thanks' element={<Thanks />} />
          <Route path='/error' element={<Error />} />
          <Route path="*" element={<NotFound/>} />
        </Routes>
      </BrowserRouter>
      <NotificationContainer/>
    </div>
  );
}

export default App;
