import React,{ useState, useEffect } from 'react'
import { Formik, Form, Field, useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {useNavigate, useParams} from "react-router-dom"
import CircularProgress from '@mui/material/CircularProgress';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import ImageModal from '../../components/ImageModal';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export const projectsSchema = yup.object().shape({
  project_name: yup.string().required("This is Required"),
  notification_send: yup.string().required("This is Required"),
  description: yup.string().required("This is Required"),
  status: yup.string().required("This is Required"),
  images_upload: yup.string(),
  incentive_schemes_upload: yup.string(),
  brochure_upload: yup.string(),
  whatsapp_contents_upload: yup.string(),
});

const reqFormat = (num) => {
  return (num).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
}

const getFileId = () => {
  var newDate = new Date();
  return parseInt(newDate.getFullYear()+''+reqFormat(newDate.getMonth()+1)+''+reqFormat(newDate.getDate())+''+reqFormat(newDate.getHours())+''+reqFormat(newDate.getMinutes())+''+reqFormat(newDate.getSeconds()))
}


const TextInput = ({ field, form, ...props }) => {
  // if (field.name === "org_name") {
  //   console.log(field, form);
  // }

  return (
    <TextField
      {...field}
      {...props}
      error={
        (form?.touched[field?.name] && form?.errors[field?.name]) ||
        (form?.errors[field?.name] && form?.submitCount > 0)
      }
      helperText={form?.touched[field?.name] && form?.errors[field?.name]}
    />
  );
};

const SelectInput = ({ field, form, ...props }) => {
  // if (field.name === "org_name") {
  //   console.log(field, form);
  // }

  return (
    <input
      {...field}
      {...props}
      error={
        (form?.touched[field?.name] && form?.errors[field?.name]) ||
        (form?.errors[field?.name] && form?.submitCount > 0)
      }
      helperText={form?.touched[field?.name] && form?.errors[field?.name]}
      type='select'
      className="select"
    >
      {props.children}
    </input>
  );
};

const CustomButton = ({ field, form, ...props }) => {
  return (
    <button className="registrationbutton" {...field} {...props} >
      {props.children}
    </button>
  );
};

function EditProjects() {
  const [isloading, setIsloading] = useState(false);
  const [initialValue, setInitialValue] = useState({})
  const navigate = useNavigate();
  let {id} = useParams()
  let authToken;
  try{
    authToken = JSON.parse(localStorage.getItem('EMPLOYEE-AUTH'))
  }catch(err){
    authToken = null
  }

  useEffect(() => {
    fetchDataById()

    return () => {
      
    }
  }, [])

  const fetchDataById = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer" +" "+ authToken.token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/project-details/"+id, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        setInitialValue(result)
        // setRows(result.channel_partner)
      })
      .catch(error => console.log('error', error));
  }

  const handleFileUpload = async(fileInput,fileUrl) => {
    // console.log(fileInput,fileUrl)
    var formdata = new FormData();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer" +" "+ authToken.token);
    formdata.append("file", fileInput,fileUrl+fileInput.name.replaceAll(" ","_"));
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
      headers: myHeaders
    };

    const response = await fetch(process.env.REACT_APP_BASE_URL+"/file/upload-public", requestOptions)
    const res = await response.json()
    if (response.status===501){
      NotificationManager.error(res.error.message, 'Form Not Submited', 8000);
    }
    return res.location
  }


  const handleSubmit = async (values, actions, errors) => {
    setIsloading(true)
    // console.log(values, actions, errors);
    if (values.images_upload){
      let imagesString = getFileId()
      values.images = await handleFileUpload(values.images_upload,imagesString)
    }

    if (values.brochure_upload){
      let brochureString = getFileId()
      values.brochure = await handleFileUpload(values.brochure_upload,brochureString)
    }

    if (values.incentive_schemes_upload){
      let incentive_schemesString = getFileId()
      values.incentive_schemes = await handleFileUpload(values.incentive_schemes_upload,incentive_schemesString)
    }

    let waContentsUrl = []
    if(values.whatsapp_contents_upload){
      for (var i = 0; i < values.whatsapp_contents_upload.length ; i++) {
        // console.log(values.whatsapp_contents[i])
        let itemString = getFileId()
        let itemUrl = await handleFileUpload(values.whatsapp_contents_upload[i],itemString)
        waContentsUrl.push(itemUrl)
      }
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer "+authToken.token);

    var raw = JSON.stringify({
      project_name: values.project_name,
      notification_send: values.notification_send,
      description: values.description,
      status: values.status,
      images: values.images,
      incentive_schemes: values.incentive_schemes,
      brochure: values.brochure,
      whatsapp_contents: waContentsUrl.concat(values.whatsapp_contents),
    });

    var requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/project-details/"+id, requestOptions)
      .then(result => {
        // console.log(result.status)
        if (result.status%400<10){
          NotificationManager.error('Not authorised to edit the data', 'Error', 6000);
        }
        setIsloading(false)
        navigate("/admin/projects")
      })
      .catch(error => {
        console.log('error', error)
        NotificationManager.error('Something went wrong', 'Error', 6000);
        setIsloading(false)
      });

  }
  return (
    <div>
      {initialValue.id && (<Formik
        initialValues={{...initialValue}}
        validationSchema={projectsSchema}
        onSubmit={handleSubmit}
      >
        {({ touched, errors, values, submitCount, setFieldValue, handleSubmit,submitForm }) => (
          <Form  >
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <div style={{ padding: "1rem", display:'flex', justifyContent:'space-between' }}>
                  <h4 style={{ font: "Jost" }}>Edit Projects</h4>
                  <Button className="registrationbutton"  variant="contained" startIcon={<ArrowBackIosNewIcon />} onClick={() => navigate(-1) } >Back</Button>
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="project_name"
                  label="Name*"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="notification_send"
                  label="Notification Send*"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="description"
                  label="Description*"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={3} xs={6}>
                <div style={{ padding: "1rem" }}>
                  <p>Status:<span style={{color:"rgb(211,47,47)"}}>*</span></p>
                </div>
              </Grid>
              <Grid item md={3} xs={6}>
                <Field
                  as="select"
                  label="Status*"
                  name="status"
                  className="select"
                  style={
                    (touched?.status && errors?.status) ||
                    (errors?.status && submitCount)
                      ? {
                          borderColor: "rgb(211,47,47)",
                          color: "rgb(211,47,47)",
                        }
                      : {}
                  }
                >
                  <option value="">Choose...</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Field>
                {touched?.status && errors?.status && (
                  <p className="error">{errors?.status}</p>
                )}
              </Grid>
              <Grid item md={3} xs={6}>
                <div style={{ padding: "0.5rem 0 0 0" }}>
                  <p>Images:<span style={{color:"rgb(211,47,47)"}}>*</span></p>
                </div>
              </Grid>
              <Grid item md={1} xs={2}>
                <div style={{ padding: "0.5rem 0 0 0" }}>
                  {values.images && <ImageModal url={values.images} ls_token_key={"EMPLOYEE-AUTH"}/>}
                </div>
              </Grid>
              <Grid item md={2} xs={4}>
                <div style={{ padding: "0.5rem 0 0 0" }}>
                  <label for="images_upload">
                    <CloudUploadIcon /><span style={{color:"rgb(211,47,47)"}}>*</span>
                  </label>
                  <input
                    type="file"
                    accept="image/jpeg,image/gif,image/png,image/x-eps"
                    id="images_upload"
                    name="images_upload"
                    onChange={(event) => {
                      // console.log(event.target.files[0])
                      setFieldValue(
                        "images_upload",
                        event.target.files[0]
                      );
                    }}
                    hidden
                  />
                </div>
              </Grid>
              <Grid item md={3} xs={6}>
                <div style={{ padding: "0.5rem 0 0 0" }}>
                  <p>Brochure:<span style={{color:"rgb(211,47,47)"}}>*</span></p>
                </div>
              </Grid>
              <Grid item md={1} xs={2}>
                <div style={{ padding: "0.5rem 0 0 0" }}>
                  {values.brochure && <ImageModal url={values.brochure} ls_token_key={"EMPLOYEE-AUTH"}/>}
                </div>
              </Grid>
              <Grid item md={2} xs={4}>
                <div style={{ padding: "0.5rem 0 0 0" }}>
                  <label for="brochure_upload">
                    <CloudUploadIcon /><span style={{color:"rgb(211,47,47)"}}>*</span>
                  </label>
                  <input
                    type="file"
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                    id="brochure_upload"
                    name="brochure_upload"
                    onChange={(event) => {
                      // console.log(event.target.files[0])
                      setFieldValue(
                        "brochure_upload",
                        event.target.files[0]
                      );
                    }}
                    hidden
                  />
                </div>
              </Grid>
              <Grid item md={3} xs={6}>
                <div style={{ padding: "0.5rem 0 0 0" }}>
                  <p>Incentive Schemes:<span style={{color:"rgb(211,47,47)"}}>*</span></p>
                </div>
              </Grid>
              <Grid item md={1} xs={2}>
                <div style={{ padding: "0.5rem 0 0 0" }}>
                  {values.incentive_schemes && <ImageModal url={values.incentive_schemes} ls_token_key={"EMPLOYEE-AUTH"}/>}
                </div>
              </Grid>
              <Grid item md={2} xs={4}>
                <div style={{ padding: "0.5rem 0 0 0" }}>
                  <label for="incentive_schemes_upload">
                    <CloudUploadIcon /><span style={{color:"rgb(211,47,47)"}}>*</span>
                  </label>
                  <input
                    type="file"
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                    id="incentive_schemes_upload"
                    name="incentive_schemes_upload"
                    onChange={(event) => {
                      // console.log(event.target.files[0])
                      setFieldValue(
                        "incentive_schemes_upload",
                        event.target.files[0]
                      );
                    }}
                    hidden
                  />
                </div>
              </Grid>
              <Grid item md={3} xs={6}>
                <div style={{ padding: "0.5rem 0 0 0" }}>
                  <p>Whatsapp Contents:<span style={{color:"rgb(211,47,47)"}}>*</span></p>
                </div>
              </Grid>
              <Grid item md={1} xs={3}>
                <div style={{ padding: "0.5rem 0 0 0" }}>
                  {values.whatsapp_contents && 
                    values.whatsapp_contents.map((item,index) => (
                      <div style={{display:'flex'}}>
                        <ImageModal url={item} ls_token_key={"EMPLOYEE-AUTH"}/>
                        <DeleteIcon onClick={() => {
                          let temp = values.whatsapp_contents.filter((el,ind) => ind !== index)
                          setFieldValue(
                            "whatsapp_contents",
                            temp
                          )
                        }} />
                      </div>
                    ))}
                </div>
              </Grid>
              <Grid item md={2} xs={3}>
                <div style={{ padding: "0.5rem 0 0 0" }}>
                  <label for="whatsapp_contents_upload">
                    <CloudUploadIcon /><span style={{color:"rgb(211,47,47)"}}>*</span>
                  </label>
                  <input
                    type="file"
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                    id="whatsapp_contents_upload"
                    name="whatsapp_contents_upload"
                    multiple="multiple"
                    onChange={(event) => {
                      console.log(event.target.files)
                      setFieldValue(
                        "whatsapp_contents_upload",
                        event.target.files
                      );
                    }}
                    hidden
                  />
                </div>
              </Grid>
              <Grid item md={12} xs={12}>
                <Field id="submitEdit" style={{width:'100%'}} name="submitEdit" type="submit"  component={CustomButton}>
                    {isloading ? <CircularProgress sx={{color:"#FFFFFF"}} size="1.5rem"/>:"Edit"}
                </Field>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>)}
    </div>
  )
}

export default EditProjects