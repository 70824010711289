import React,{ useState } from 'react'
import Grid  from "@mui/material/Grid"
import useMediaQuery from '@mui/material/useMediaQuery';
import { BrowserRouter , Route, Routes, Switch, Link, Redirect } from 'react-router-dom'
import { Edit, Close } from "@mui/icons-material";
import ListMiscellaneous from './ListMiscellaneous';




function Miscellaneous(props) {
  const matches = useMediaQuery('(min-width:1080px)');
  React.useEffect(() => {
    props.setIsSelected("Miscellaneous") 
    return () => {
      
    }
  }, [])
    // console.log(props)

    
  return (
    <div style={{padding:"1rem"}}>
      <Routes>
        <Route exact path='/' element={<ListMiscellaneous />}/>
        {/* <Route exact path='/create' element={<CreateMiscellaneous />}/>
        <Route exact path='/edit/:id' element={<EditMiscellaneous />}/>
        <Route exact path='/show/:id' element={<ShowMiscellaneous />}/> */}
      </Routes>
    </div>
  )
}

export default Miscellaneous