import React,{ useState, useEffect } from 'react'
import { Formik, Form, Field, useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {useNavigate, useParams} from "react-router-dom"
import CircularProgress from '@mui/material/CircularProgress';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import ImageModal from '../../components/ImageModal'
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { permissions } from '../utility/permissions';
import MenuItem from "@mui/material/MenuItem";
import { choices } from '../utility/choices'



const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  export const invoiceSchema = yup.object().shape({
    invoice_value: yup.string().required("This is Required"),
    invoice_no: yup.string().required("This is Required"),
    invoice_date: yup.string().required("This is Required"),
    invoice_upload: yup.string(),
    type: yup.string().required("This is Required"),
    // type_id: yup.string().required("This is Required"),
    // hoabl_id: yup.string(),
    payment_mode: yup.string(),
    payment_channel_details: yup.string(),
    payment_status: yup.string(),
    invoice_status: yup.string(),
  });

const reqFormat = (num) => {
  return (num).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
}

const getFileId = () => {
  var newDate = new Date();
  return parseInt(newDate.getFullYear()+''+reqFormat(newDate.getMonth()+1)+''+reqFormat(newDate.getDate())+''+reqFormat(newDate.getHours())+''+reqFormat(newDate.getMinutes())+''+reqFormat(newDate.getSeconds()))
}
  

const TextInput = ({ field, form, ...props }) => {
  // if (field.name === "org_name") {
  //   console.log(field, form);
  // }

  return (
    <TextField
      {...field}
      {...props}
      error={
        (form?.touched[field?.name] && form?.errors[field?.name]) ||
        (form?.errors[field?.name] && form?.submitCount > 0)
      }
      helperText={form?.touched[field?.name] && form?.errors[field?.name]}
    />
  );
};

const SelectInput = ({ field, form, ...props }) => {
  // if (field.name === "org_name") {
  //   console.log(field, form);
  // }

  return (
    <input
      {...field}
      {...props}
      error={
        (form?.touched[field?.name] && form?.errors[field?.name]) ||
        (form?.errors[field?.name] && form?.submitCount > 0)
      }
      helperText={form?.touched[field?.name] && form?.errors[field?.name]}
      type='select'
      className="select"
    >
      {props.children}
    </input>
  );
};

const CustomButton = ({ field, form, ...props }) => {
  return (
    <button className="registrationbutton" {...field} {...props} >
      {props.children}
    </button>
  );
};

function EditInvoices() {
  const [isloading, setIsloading] = useState(false);
  const [initialValue, setInitialValue] = useState({})
  const navigate = useNavigate();
  let {id} = useParams()

  let authToken;
  try{
    authToken = JSON.parse(localStorage.getItem('EMPLOYEE-AUTH'))
  }catch(err){
    authToken = null
  }

  useEffect(() => {
    fetchDataById()

    return () => {
      
    }
  }, [])

  const fetchDataById = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer" +" "+ authToken.token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/invoice-details/"+id, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        setInitialValue(result)
        // setRows(result.channel_partner)
      })
      .catch(error => console.log('error', error));
  }

  const handleFileUpload = async(fileInput,fileUrl) => {
    // console.log(fileInput,fileUrl)
    var formdata = new FormData();
    formdata.append("file", fileInput,fileUrl+fileInput.name.replaceAll(" ","_"));
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    const response = await fetch(process.env.REACT_APP_BASE_URL+"/file/upload", requestOptions)
    const res = await response.json()
    if (response.status===501){
      NotificationManager.error(res.error.message, 'Form Not Submited', 8000);
    }
    return res.location
  }

  const handleSubmit = async (values, actions, errors) => {
    setIsloading(true)
    // console.log(values, actions, errors);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer "+authToken.token);

    if (values.invoice_upload){
      let timeString = getFileId()
      values.invoice_url = await handleFileUpload(values.invoice_upload,timeString)
    }

    var raw = JSON.stringify({
      invoice_value: values.invoice_value,
      invoice_no: values.invoice_no,
      invoice_date: values.invoice_date,
      invoice_url: values.invoice_url,
      type: values.type,
      type_id: values.type_id,
      hoabl_id: values.hoabl_id,
      payment_mode: values.payment_mode,
      payment_channel_details: values.payment_channel_details,
      payment_status: values.payment_status,
      invoice_status: values.invoice_status,
    });

    var requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/invoice-details/"+id, requestOptions)
      .then(result => {
        // console.log(result.status)
        if (result.status%400<10){
          NotificationManager.error('Not authorised to edit the data', 'Error', 6000);
        }
        setIsloading(false)
        navigate(-1)
      })
      .catch(error => {
        console.log('error', error)
        NotificationManager.error('Something went wrong', 'Error', 6000);
        setIsloading(false)
      });

  }
  return (
    <div>
      {initialValue.id && (<Formik
        initialValues={{...initialValue}}
        validationSchema={invoiceSchema}
        onSubmit={handleSubmit}
      >
        {({ touched, errors, values, submitCount, setFieldValue, handleSubmit,submitForm }) => (
          <Form  >
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <div style={{ padding: "1rem", display:'flex', justifyContent:'space-between' }}>
                  <h4 style={{ font: "Jost" }}>Edit Invoice Details</h4>
                  <Button className="registrationbutton"  variant="contained" startIcon={<ArrowBackIosNewIcon />} onClick={() => navigate(-1) } >Back</Button>
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="invoice_value"
                  label="Invoice Value"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="invoice_no"
                  label="Invoice No"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="invoice_date"
                  label="Invoice Date"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="type"
                  label="Type"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              {/* <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="hoabl_id"
                  label="Hoabl Id"
                  fullWidth
                  component={TextInput}
                />
              </Grid> */}
              
              {/* <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="invoice_status"
                  label="Invoice Status"
                  fullWidth
                  component={TextInput}
                />
              </Grid> */}
              {/* <Grid item md={3} xs={6}>
                <div style={{ padding: "1rem" }}>
                  <p>Invoice Status:</p>
                </div>
              </Grid> */}
              <Grid item md={6} xs={6}>
                {/* <Field
                  as="select"
                  label="Invoice Status"
                  name="invoice_status"
                  className="select"
                  style={
                    (touched?.invoice_status && errors?.invoice_status) ||
                    (errors?.invoice_status && submitCount)
                      ? {
                          borderColor: "rgb(211,47,47)",
                          color: "rgb(211,47,47)",
                        }
                      : {}
                  }
                >
                  <option value="">Choose...</option>
                  <option value="INVOICE_UPLOADED">Invoice_Uploaded</option>
                  <option value="ACCEPTED">Accepted</option>
                  <option value="REJECTED">Rejected</option>
                </Field> */}
                <Field
                  name="invoice_status"
                  label="Invoice Status"
                  fullWidth
                  select={true}
                  SelectProps={{
                    onClose: () => {
                      setTimeout(() => {
                        document.activeElement.blur();
                      }, 0);
                    },
                  }}
                  onChange={(e) => {
                    setFieldValue("invoice_status",e.target.value)
                  }}
                  component={TextInput}
                >
                  {choices.invoice_status.map(item => <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>)}
                </Field>
                {touched?.invoice_status && errors?.invoice_status && (
                  <p className="error">{errors?.invoice_status}</p>
                )}
              </Grid>
              {/* <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="payment_status"
                  label="Payment Status"
                  fullWidth
                  component={TextInput}
                />
              </Grid> */}
              {/* <Grid item md={3} xs={6}>
                <div style={{ padding: "1rem" }}>
                  <p>Payment Status:</p>
                </div>
              </Grid> */}
              <Grid item md={6} xs={6}>
                <Field
                  name="payment_status"
                  label="Payment Status"
                  disabled = {!((permissions.accounts(authToken.info.email) || permissions.super(authToken.info.email)) && values.invoice_status==="ACCEPTED")}
                  fullWidth
                  select={true}
                  SelectProps={{
                    onClose: () => {
                      setTimeout(() => {
                        document.activeElement.blur();
                      }, 0);
                    },
                  }}
                  onChange={(e) => {
                    setFieldValue("payment_status",e.target.value)
                  }}
                  component={TextInput}
                >
                  {choices.payment_status.map(item => <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>)}
                </Field>
                {touched?.payment_status && errors?.payment_status && (
                  <p className="error">{errors?.payment_status}</p>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="payment_mode"
                  label="Payment Mode"
                  disabled = {!((permissions.accounts(authToken.info.email) || permissions.super(authToken.info.email)) && values.invoice_status==="ACCEPTED")}
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="payment_channel_details"
                  label="Payment Channel Details"
                  disabled = {!((permissions.accounts(authToken.info.email) || permissions.super(authToken.info.email)) && values.invoice_status==="ACCEPTED")}
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={3} xs={5}>
                <div style={{ padding: "0 0 0 0" }}>
                  <p>Invoice Preview:</p>
                </div>
              </Grid>
              <Grid item md={1} xs={2}>
                <div style={{ padding: "0.5rem 0 0 0" }}>
                  {values.invoice_url && <ImageModal url={values.invoice_url} ls_token_key={"EMPLOYEE-AUTH"}/>}
                </div>
              </Grid>
              <Grid item md={1} xs={2}>
                {values.type !== "app_incentive" && (<div style={{ padding: "0.5rem 0 0 0" }}>
                  <label for="invoice_upload">
                    <CloudUploadIcon />
                  </label>
                  <input
                    type="file"
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                    id="invoice_upload"
                    name="invoice_upload"
                    onChange={(event) => {
                      // console.log(event.target.files[0])
                      setFieldValue(
                        "invoice_upload",
                        event.target.files[0]
                      );
                    }}
                    hidden
                  />
                </div>)}
              </Grid>
              <Grid item md={12} xs={12}>
                <Field id="submitEdit" style={{width:'100%'}} name="submitEdit" type="submit"  component={CustomButton}>
                    {isloading ? <CircularProgress sx={{color:"#FFFFFF"}} size="1.5rem"/>:"Edit"}
                </Field>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>)}
    </div>
  )
}

export default EditInvoices