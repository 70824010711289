import React, { useState, useEffect } from 'react'
import ReactDom from 'react-dom'
import { Formik, Form, Field, useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom"
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import {NotificationContainer, NotificationManager} from 'react-notifications';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const fileSchema = yup.object().shape({
  csv_upload: yup.mixed().required("You need to provide a file")
});

const reqFormat = (num) => {
  return (num).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
}

const getFileId = () => {
  var newDate = new Date();
  return parseInt(newDate.getFullYear()+''+reqFormat(newDate.getMonth()+1)+''+reqFormat(newDate.getDate())+''+reqFormat(newDate.getHours())+''+reqFormat(newDate.getMinutes())+''+reqFormat(newDate.getSeconds()))
}


const TextInput = ({ field, form, ...props }) => {
  // if (field.name === "org_name") {
  //   console.log(field, form);
  // }

  return (
    <TextField
      {...field}
      {...props}
      error={
        (form?.touched[field?.name] && form?.errors[field?.name]) ||
        (form?.errors[field?.name] && form?.submitCount > 0)
      }
      helperText={form?.touched[field?.name] && form?.errors[field?.name]}
    />
  );
};

const CustomButton = ({ field, form, ...props }) => {
  return (
    <button className="registrationbutton" {...field} {...props} >
      {props.children}
    </button>
  );
};


function BulkDataUpload(props) {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let authToken;
  try{
    authToken = JSON.parse(localStorage.getItem("EMPLOYEE-AUTH"))
  }catch(err){
    authToken = null
  }
  
  
  const handleExcelFileUpload = async(values, actions, errors ) => {
    var fileInput = values.csv_upload
    // console.log(fileInput,fileUrl)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer" +" "+ authToken?.token);
    var formdata = new FormData();
    formdata.append("file", fileInput,fileInput.name.replaceAll(" ","_"));
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
      headers: myHeaders
    };

    const response = await fetch(process.env.REACT_APP_BASE_URL+"/"+props.entityType+"/upload", requestOptions)
    if(response.status === 401 || response.status === 500) {
      NotificationManager.error('Something went wrong Failed to Upload ', 'Error', 6000);
    }else{
      NotificationManager.success('Data Uploaded Successfully', 'Success', 6000);
      handleClose()
    }
    
  }
  
  return (
    <>
      <label onClick={handleOpen}>
        <img src="/images/xlsx_icon.png" style={{height:'40px',alignSelf:'right',marginRight:'1rem',cursor:'pointer'}} />
      </label>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Formik
            initialValues={{
              invoice_date: Date.now('dd/mm/yyyy')
            }}
            validationSchema={fileSchema}
            onSubmit={handleExcelFileUpload}
          >
            {({ touched, errors, values, submitCount, setFieldValue }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    {/* <div style={{ padding: "1rem" }}> */}
                      <h4 style={{ font: "Jost" }}>Bulk Upload</h4>
                    {/* </div> */}
                  </Grid>
                  
                  <Grid item md={5} xs={10}>
                    <div style={{ padding: "1rem" }}>
                      <p>CSV file Upload:</p>
                      {errors?.csv_upload && (
                        <p className="error">{errors?.csv_upload }</p>
                      )}
                    </div>
                  </Grid>
                  <Grid item md={1} xs={2}>
                    <div style={{ padding: "1rem 0 1rem 0" }}>
                      <label for="csv_upload">
                        <CloudUploadIcon /><span style={{color:"rgb(211,47,47)"}}>*</span>
                      </label>
                      <input
                        type="file"
                        accept=".csv"
                        id="csv_upload"
                        name="csv_upload"
                        onChange={(event) => {
                          // console.log(event.target.files[0])
                          setFieldValue(
                            "csv_upload",
                            event.target.files[0]
                          );
                        }}
                        hidden
                      />
                    </div>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Field type="submit" component={CustomButton}>
                      {isLoading ? <CircularProgress sx={{color:"#FFFFFF"}} size="1.5rem"/>:"Submit"}
                    </Field>
                    <Field style={{marginLeft:'1rem'}} component={CustomButton} onClick={handleClose}>
                      Close
                    </Field>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  )
}

export default BulkDataUpload