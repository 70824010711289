import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Formik, Form, Field, useFormik } from "formik";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {useNavigate, useParams} from "react-router-dom"
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import CircularProgress from '@mui/material/CircularProgress';
import * as yup from "yup";


const feedbackSchema = yup.object().shape({
  feedback_message: yup.string().required("This is Required"), 
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const TextInput = ({ field, form, ...props }) => {
  // if (field.name === "org_name") {
  //   console.log(field, form);
  // }

  return (
    <TextField
      {...field}
      {...props}
      error={
        (form?.touched[field?.name] && form?.errors[field?.name]) ||
        (form?.errors[field?.name] && form?.submitCount > 0)
      }
      helperText={form?.touched[field?.name] && form?.errors[field?.name]}
    />
  );
};

const CustomButton = ({ field, form, ...props }) => {
  return (
    <button className="registrationbuttonclient" {...field} {...props} >
      {props.children}
    </button>
  );
};

function Feedback({user}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isloading, setIsloading] = React.useState(false);
  const navigate = useNavigate()


  const handleSubmit = (values,actions,errors) => {
    console.log("Submited", values, errors)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer "+user.token);

    var raw = JSON.stringify({
      ...user?.info,
      feedback_message: values.feedback_message
    });

    var requestOptions = {
      method: 'post',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/feedback", requestOptions)
      .then(result => {
        // console.log(result.status)
        if (result.status%400<10){
          return NotificationManager.error('Not authorised to edit the data', 'Error', 6000);
        }
        NotificationManager.success('Feedback submited successfully', 'Success', 6000);
        setIsloading(false)
        handleClose()
      })
      .catch(error => {
        console.log('error', error)
        NotificationManager.error('Something went wrong', 'Error', 6000);
        setIsloading(false)
      });

    handleClose()

  }


  return (
    <div>
      <div onClick={handleOpen}  className="feedback-transform" style={{
        display: open && 'none',
        position: 'fixed',
        zIndex: '2147483647',
        cursor: 'pointer',
        transition: 'none !important',
        borderRadius: '4px',
        borderBottomRightRadius: '0',
        borderBottomLeftRadius: '0',
        borderBottom: 'none',
        backgroundColor:'#231F20',
        color:'#FFF',
        padding:'0.2rem 0.5rem 0.2rem 0.5rem'
      }} >
        Feedback
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Give Your Feedback
          </Typography>
          <Typography id="modal-modal-description"variant="body2" sx={{ mt: 2, color: 'rgb(112, 112, 112)' }}>
            A Message For Us*
          </Typography>
          <Formik
            initialValues={{}}
            validationSchema={feedbackSchema}
            onSubmit={handleSubmit}
          >
            {({ touched, errors, values, submitCount, setFieldValue }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <Field
                      as="textarea"
                      name="feedback_message"
                      label="A Message For Us"
                      style={
                        (touched?.feedback_message && errors?.feedback_message) ||
                        (errors?.feedback_message && submitCount)
                          ? {
                              borderColor: "rgb(211,47,47)",
                              color: "rgb(211,47,47)",
                            }
                          : {}
                      }
                    />
                    {touched?.feedback_message && errors?.feedback_message && (
                      <p className="error">{errors?.feedback_message}</p>
                    )}
                  </Grid>
                  <Grid item md={8} xs={0}></Grid>
                  <Grid item md={4} xs={12}>
                    <Field type="submit" component={CustomButton}>
                        {isloading ? <CircularProgress sx={{color:"#FFFFFF"}} size="1.5rem"/>:"Submit"}
                    </Field>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default Feedback