import React,{ useState } from 'react'
import { Formik, Form, Field, useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {useNavigate, useParams} from "react-router-dom"
import CircularProgress from '@mui/material/CircularProgress';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const appDetailsSchema = yup.object().shape({
  invoice_value: yup.string().required("This is Required"),
  invoice_no: yup.string().required("This is Required"),
  invoice_date: yup.string().required("This is Required"),
  invoice_url: yup.string().required("This is Required"),
  type: yup.string().required("This is Required"),
  type_id: yup.string().required("This is Required"),
  hoabl_id: yup.string(),
  payment_mode: yup.string(),
  payment_channel_details: yup.string(),
  payment_status: yup.string(),
});

const TextInput = ({ field, form, ...props }) => {
  // if (field.name === "org_name") {
  //   console.log(field, form);
  // }

  return (
    <TextField
      {...field}
      {...props}
      error={
        (form?.touched[field?.name] && form?.errors[field?.name]) ||
        (form?.errors[field?.name] && form?.submitCount > 0)
      }
      helperText={form?.touched[field?.name] && form?.errors[field?.name]}
    />
  );
};

const SelectInput = ({ field, form, ...props }) => {
  // if (field.name === "org_name") {
  //   console.log(field, form);
  // }

  return (
    <input
      {...field}
      {...props}
      error={
        (form?.touched[field?.name] && form?.errors[field?.name]) ||
        (form?.errors[field?.name] && form?.submitCount > 0)
      }
      helperText={form?.touched[field?.name] && form?.errors[field?.name]}
      type='select'
      className="select"
    >
      {props.children}
    </input>
  );
};

const CustomButton = ({ field, form, ...props }) => {
  return (
    <button className="registrationbutton" {...field} {...props} >
      {props.children}
    </button>
  );
};

function CreateInvoices() {
  const [isloading, setIsloading] = useState(false);
  const [initialValue, setInitialValue] = useState({})
  const navigate = useNavigate();

  let authToken;
  try{
    authToken = JSON.parse(localStorage.getItem('EMPLOYEE-AUTH'))
  }catch(err){
    authToken = null
  }

  const handleSubmit = async (values, actions, errors) => {
    setIsloading(true)
    // console.log(values, actions, errors);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer "+authToken.token);

    var raw = JSON.stringify({
      invoice_value: values.invoice_value,
      invoice_no: values.invoice_no,
      invoice_date: values.invoice_date,
      invoice_url: values.invoice_url,
      type: values.type,
      type_id: values.type_id,
      hoabl_id: values.hoabl_id,
      payment_mode: values.payment_mode,
      payment_channel_details: values.payment_channel_details,
      payment_status: values.payment_status,
    });

    var requestOptions = {
      method: 'post',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/invoice-details", requestOptions)
      .then(result => {
        // console.log(result.status)
        if (result.status%400<10){
          NotificationManager.error('Not authorised to edit the data', 'Error', 6000);
        }
        setIsloading(false)
        navigate(-1)
      })
      .catch(error => {
        console.log('error', error)
        NotificationManager.error('Something went wrong', 'Error', 6000);
        setIsloading(false)
      });

  }
  return (
    <div>
      <Formik
        initialValues={{...initialValue}}
        validationSchema={appDetailsSchema}
        onSubmit={handleSubmit}
      >
        {({ touched, errors, values, submitCount, setFieldValue, handleSubmit,submitForm }) => (
          <Form  >
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <div style={{ padding: "1rem", display:'flex', justifyContent:'space-between' }}>
                  <h4 style={{ font: "Jost" }}>Create Invoice Details</h4>
                  <Button className="registrationbutton"  variant="contained" startIcon={<ArrowBackIosNewIcon />} onClick={() => navigate(-1) } >Back</Button>
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="application_id"
                  label="application_id"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="application_date"
                  label="application_date"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="customer_name"
                  label="customer_name"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="cp_firm_name"
                  label="cp_firm_name"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="eligibilty_status"
                  label="eligibilty_status"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="eligible_amount"
                  label="eligible_amount"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="gst_amount"
                  label="gst_amount"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="gst_type"
                  label="gst_type"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="hoabl_id"
                  label="hoabl_id"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="incentive_amount"
                  label="incentive_amount"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="invoice_id"
                  label="invoice_id"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="invoice_status"
                  label="invoice_status"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="launch_name"
                  label="launch_name"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="net_amount"
                  label="net_amount"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="payment_status"
                  label="payment_status"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="project_name"
                  label="project_name"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="tds"
                  label="tds"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              
              <Grid item md={12} xs={12}>
                <Field id="submitEdit" style={{width:'100%'}} name="submitEdit" type="submit"  component={CustomButton}>
                    {isloading ? <CircularProgress sx={{color:"#FFFFFF"}} size="1.5rem"/>:"Create"}
                </Field>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CreateInvoices