import React,{ useState } from 'react'
import OwlCarousel from "react-owl-carousel";
import Grid  from "@mui/material/Grid"
import { useNavigate, Navigate } from "react-router-dom";
import Card from '@mui/material/Card';
import { TextField, Typography, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import CardContent from '@mui/material/CardContent';
import { imagesArray } from './constants';
import './index.css'


// const imagesArray = [
//   "url('https://hoabl.in/partners/assets/images/ayodhyacover.jpg')",
//   "url('https://hoabl.in/partners/assets/images/gulf_of_goacover.jpg')",
// ]

const styles = {
  authFormContainer: {
    display:' flex',
    justifyContent:' center',
    alignItems:' center',
    // width:' 100vw',
    height: ' 90vh',
    flexDirection:'column',
  },
  authForm: {
    width:' 360px',
    boxShadow:' rgb(0 0 0 / 16%) 1px 1px 10px',
    // paddingTop:' 10px',
    // paddingBottom:' 10px',
    borderRadius:' 8px',
    backgroundColor:' white',
  },
  authFormContent:{
    paddingLeft:' 12%',
    paddingRight:' 12%',
    alignItems:"center",
    
  },
  authFormTitle:{
    textAlign:' center',
    marginBottom:' 1em',
    fontSize:' 24px',
    color:' rgb(34, 34, 34)',
    fontWeight:' 800',
  },
  label:{
    fontSize:' 14px',
    fontWeight:' 600',
    color:' rgb(34, 34, 34)',
  },
}

export const PasswordLogin = (props) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();
  let authToken;
  try{
    authToken = JSON.parse(localStorage.getItem('EMPLOYEE-AUTH'))
  }catch(err){
    authToken = null
  }
  
  // console.log(authToken)
  if (authToken){
    return <Navigate to="/admin" replace />;
  }

  const handleEmpLogin = (e) =>{ 
    // console.log("emplogin")
    e.preventDefault()
    setIsLoading(true)

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "email": email,
      "password": password,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
      cors:'no-cors'
    };
    fetch(process.env.REACT_APP_BASE_URL+"/employee-details/login", requestOptions)
      .then(async(result) => {
        // console.log(result,result.status)
        // console.log(Number(result.status/100))
        if (Number(result.status/100) === 4){
          // setIsLoading(false)
          let error = await result.json()
          throw error
        }
        return result.json()
        // location.reload()
      })
      .then(response => {
        // console.log(response)
        setIsLoading(false)    
        localStorage.setItem('EMPLOYEE-AUTH',JSON.stringify(response))
        navigate("/admin")
      })
      .catch(error => {
        console.log('error', error)
        NotificationManager.error(error.message, 'Error', 6000);
        setIsLoading(false)
      });
    setIsLoading(false)
  }

  return (
    <>
      <Card style={styles.authForm}>
        <CardContent style={styles.authFormContent}>
          <h3 style={styles.authFormTitle}>Sign In</h3>
          {/* <div className="text-center">
            Not registered yet?{" "}
            <Typography variant="button" color="primary" sx={{fontSize:'1.2rem'}}>
              Sign Up
            </Typography>
          </div> */}
          <form action={null} onSubmit={handleEmpLogin}>
            <TextField name="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} label="Email" fullWidth sx={{marginTop:'2rem'}} />
            <TextField name="password" value={password} onChange={(e)=>{setPassword(e.target.value)}} label="Password" type="password" fullWidth sx={{marginTop:'2rem'}} />
            <Button type="submit"  variant="contained" color="primary" fullWidth sx={{marginTop:'2rem',padding:"1rem"}}>Submit</Button>
          </form>
        </CardContent>
      </Card>
    </>
  )
}

function EmpLogin() {
  
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={7} sx={{ overflow: "hidden" }}>
          <img className="logo-image" src="/images/hoabl_logo_black.png" />
          <OwlCarousel
            autoplay
            autoplayTimeout={5000}
            autoplayHoverPause={true}
            loop={true}
            animateOut="fadeOut"
            className=""
            items={1}
            dots={false}
          >
            {imagesArray.map(ele => (
              <div
                key={ele}
                className="item carousel"
                style={{
                  // height: matches ? "100vh":"220px",
                  background: "#000000",
                  backgroundImage: ele,
                  backgroundSize: "cover",
                }}
              ></div>
            ))}
          </OwlCarousel>
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <div style={{
            display:' flex',
            justifyContent:' center',
            alignItems:' center',
            // width:' 100vw',
            height: '100%',
            flexDirection:'column',
            background: 'white',
          }}>
            <img className="bluechipclub" src="/images/bluechipclub.png" />
            <PasswordLogin />
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default EmpLogin