import React,{ useState, useEffect, useCallback } from 'react'
import Grid  from "@mui/material/Grid";
import { BrowserRouter , Route, Routes, Switch, Link, Redirect } from 'react-router-dom'
import OwlCarousel from "react-owl-carousel";
// import carousel1 from '../../assets/carousel1.jpeg'
// import carousel2 from '../../assets/carousel2.jpeg'
import brochurePng from '../../assets/brochure.png'
import incentiveSchemePng from '../../assets/applications.png'
import customerCommPng from '../../assets/customer communication.png'
import useMediaQuery from '@mui/material/useMediaQuery';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { Navigate, useNavigate } from "react-router-dom";
import "./index.css"
import ProjectsModal from '../../components/ProjectsModal';
import Feedback from './Feedback';
// import "./index.scss"

const imagesArray = [
  "/images/carousel1.jpeg",
  "/images/carousel2.jpeg",
  // "https://hoabl.in/partners/assets/images/project2Image.jpg"

]

//comment to test

const CarouselElements = (props) => {
  return (
    <div style={{display:"flex", flexDirection:"row", flexWrap:'wrap', marginTop:'1rem', justifyContent:'space-between' }}>
      <ProjectsModal imgArray={[props.obj.brochure]} element={
        <div style={{display:"flex", flexDirection:"row", alignItems:"center", alignContent:"center", cursor:'pointer'}}>
          <img src={brochurePng} style={{width:"30px", margin:"5px"}} />
          <p style={{margin:"0.7rem 0", fontSize:'10px', fontWeight:'600'}}>Download Brochures</p>
        </div>
      } />
      <ProjectsModal imgArray={props.obj.whatsapp_contents} element={
        <div style={{display:"flex", flexDirection:"row", alignItems:"center",alignContent:"center", cursor:'pointer'}}>
          <img src={customerCommPng} style={{width:"30px", margin:"5px"}} />
          <p style={{margin:"0.7rem 0", fontSize:'10px', fontWeight:'600'}}>Customer Communications</p>
        </div>
      } />
      <ProjectsModal imgArray={[props.obj.incentive_schemes]} element={
        <div style={{display:"flex", flexDirection:"row", alignItems:"center",alignContent:"center", cursor:'pointer'}}>
          <img src={incentiveSchemePng} style={{width:"20px", margin:"10px"}} />
          <p style={{margin:"0.7rem 0", fontSize:'10px', fontWeight:'600'}}>Incentive Schemes</p>
        </div>
      } /> 
    </div>
  )
}



function ConsumerDashboard() {
  const [projects, setProjects] = useState([])
  const [data, setData] = useState()
  const navigate = useNavigate()
  const matches = useMediaQuery('(max-width:600px)');
  const matchesLarge = useMediaQuery('(min-width:1200px)');
  let authToken;
  try{
    authToken = JSON.parse(localStorage.getItem('CP-USER-AUTH'))
  }catch(err){
    authToken = null
  }

  useEffect(() => {
    handleDataCall()
  
    return () => {
      
    }
  }, [])
  
  

  const handleDataCall = () => {
    // let authToken = JSON.parse(localStorage.getItem('USER'))
    // console.log(authToken)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer" +" "+ authToken?.token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+`/project-details`, requestOptions)
      .then(response => {
        console.log(response.status)
        if (response.status%400<10){
          NotificationManager.error('Not authorised to view this data', 'Error', 6000);
          throw new Error('Unauthorised role!!');
        }
        return response.json()
      })
      .then(result => {
        // console.log(result.status)
        // console.log(JSON.stringify(result.results))
        // let tempProjects = result.results.filter(item => item.status === 'active')
        setProjects(() => result.results.filter(item => item.status === 'active'))
      })
      .catch(error => console.log('error', error));

    var myHeaders2 = new Headers();
    myHeaders2.append("Content-Type", "application/json");
    myHeaders2.append("Authorization", "Bearer" +" "+ authToken?.token);

    var requestOptions2 = {
      method: 'GET',
      headers: myHeaders2,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+`/client/dashboard?hoabl_id=${authToken?.info?.hoabl_id}`, requestOptions2)
      .then(response => {
        console.log(response.status)
        if (response.status%400<10){
          NotificationManager.error('Not authorised to view this data', 'Error', 6000);
          throw new Error('Unauthorised role!!');
        }
        return response.json()
      })
      .then(result => {
        // console.log(result)
        setData(result.results)
        
      })
      .catch(error => console.log('error', error));
  }

  const renderProjects = React.useMemo(() => {
    return projects
  }, [projects])

  const total = React.useMemo(() => {
    let tot = 0
    if (data){
      if (data.app_incentives){
        tot += data.app_incentives.sum
      }
      if (data.base_earnings){
        tot += data.base_earnings.sum
      }
      if (data.ladder_incentives){
        tot += data.ladder_incentives.sum
      }
    }

    return tot
    
  }, [data])
  
  return (
    <>
      <div style={{width:"100%",padding:"1rem"}}>
        <header style={{minWidth:"360px", marginTop:'6rem',padding:"0 1rem"}} >
          <Grid container spacing={2} >
            {/* <Grid item md={12} sm={12} xs={12}>
              <Link to='/leads' style={{cursor:'pointer',color:'black'}}>
                <div className="card-blue card-body">
                  <div style={{ color:"cadetblue", fontSize:"16px", fontFamily:"'Futura Bold', sans-serif", fontWeight:'bold'}}>
                    My Leads
                  </div>
                </div>
              </Link>
            </Grid> */}
            <Grid item md={3} sm={4} xs={12}>
              <div className="card card-body">
                <div style={{ color:"#707070", fontSize:"16px", fontFamily:"'Futura Bold', sans-serif"}}>
                  Applications
                </div>
                <h5 style={{fontSize:"1.2rem",marginTop:"0", marginBottom:"0.5rem", fontWeight:"700", textAlign:"center"}}>{!data?.applications ? 0 : data?.applications.count}</h5>
              </div>
            </Grid>
            <Grid item md={3} sm={4} xs={12}>
              <div className="card card-body">
                <div style={{ color:"#707070", fontSize:"16px", fontFamily:"'Futura Bold', sans-serif"}}>
                  Bookings
                </div>
                <h5 style={{fontSize:"1.2rem",marginTop:"0", marginBottom:"0.5rem", fontWeight:"700", textAlign:"center"}}>{!data?.bookings ? 0 : data?.bookings}</h5>
              </div>
            </Grid>
            <Grid item md={3} sm={4} xs={12}>
              <div className="card card-body">
                <div style={{ color:"#707070", fontSize:"16px", fontFamily:"'Futura Bold', sans-serif"}}>
                  Invoice Raised
                </div>
                <h5 style={{fontSize:"1.2rem",marginTop:"0", marginBottom:"0.5rem", fontWeight:"700", textAlign:"center"}}>{!data?.invoice_raised ? 0 : data?.invoice_raised.count}</h5>
              </div>
            </Grid>
            <Grid item md={3} sm={4} xs={12}>
              <div className="card card-body">
                <div style={{ color:"#707070", fontSize:"16px", fontFamily:"'Futura Bold', sans-serif"}}>
                  Total Paid
                </div>
                <h5 style={{fontSize:"1.2rem",marginTop:"0", marginBottom:"0.5rem", fontWeight:"700", textAlign:"center"}}>{!data?.total_paid.total_paid ? 0 : data?.total_paid.total_paid.toLocaleString("en-IN", {style:"currency", currency:"INR"})}</h5>
              </div>
            </Grid>
            <Grid item md={3} sm={4} xs={12}>
              <Link to='/app-incentive' style={{cursor:'pointer',color:'black'}}>
                <div className="card card-body" style={{backgroundColor:"#F1F2F3"}}>
                  <div style={{ color:"#707070", fontSize:"16px", fontFamily:"'Futura Bold', sans-serif"}}>
                    Application Incentive
                  </div>
                  <h5 style={{fontSize:"1.2rem",marginTop:"0", marginBottom:"0.5rem", fontWeight:"700", textAlign:"center"}}>{!data?.app_incentives ? 0 : data?.app_incentives.sum.toLocaleString("en-IN", {style:"currency", currency:"INR"})}</h5>
                </div>
              </Link>
            </Grid>
            <Grid item md={3} sm={4} xs={12}>
              <Link to='/netdeals' style={{cursor:'pointer',color:'black'}}>
                <div className="card card-body" style={{backgroundColor:"#F1F2F3"}}>
                  <div style={{ color:"#707070", fontSize:"16px", fontFamily:"'Futura Bold', sans-serif"}}>
                    Base Earnings
                  </div>
                  <h5 style={{fontSize:"1.2rem",marginTop:"0", marginBottom:"0.5rem", fontWeight:"700", textAlign:"center"}}>{!data?.base_earnings ? 0 : data?.base_earnings.sum.toLocaleString("en-IN", {style:"currency", currency:"INR"})}</h5>
                </div>
              </Link>
            </Grid>
            <Grid item md={3} sm={4} xs={12}>
              <Link to='/ladder-incentive' style={{cursor:'pointer',color:'black'}}>
                <div className="card card-body" style={{backgroundColor:"#F1F2F3"}}>
                  <div style={{ color:"#707070", fontSize:"16px", fontFamily:"'Futura Bold', sans-serif"}}>
                    Ladder Incentive
                  </div>
                  <h5 style={{fontSize:"1.2rem",marginTop:"0", marginBottom:"0.5rem", fontWeight:"700", textAlign:"center"}}>{!data?.ladder_incentives ? 0 : data?.ladder_incentives.sum.toLocaleString("en-IN", {style:"currency", currency:"INR"})}</h5>
                </div>
              </Link>
            </Grid>
            <Grid item md={3} sm={4} xs={12}>
              <div className="card card-body">
                <div style={{ color:"#707070", fontSize:"16px", fontFamily:"'Futura Bold', sans-serif"}}>
                  Total Earnings
                </div>
                <h5 style={{fontSize:"1.2rem",marginTop:"0", marginBottom:"0.5rem", fontWeight:"700", textAlign:"center"}}>{total.toLocaleString("en-IN", {style:"currency", currency:"INR"})}</h5>
              </div>
            </Grid>
          </Grid>
        </header>
        <Feedback user={authToken} />
        {projects.length > 1 && (
          <div style={{marginTop:"1rem",width:"100%",padding: matchesLarge ? "1.5rem 5rem":"1.5rem",}}>
            <Grid container >
              {/* <Grid item md={12} sm={12} xs={12} sx={{ overflow: "hidden" }}> */}
              <OwlCarousel
                autoplay
                autoplayTimeout={5000}
                autoplayHoverPause={true}
                loop={true}
                animateOut="fadeOut"
                className=""
                items={matches ? 1 : 2}
                dots = {false}
                nav =  {true}
                // navContainerClass = {matches && '.main-content .custom-nav'}
                navText = {["<i class='fas fa-chevron-left iclass'></i>","<i class='fas fa-chevron-right iclass'></i>"]}
              >
                {renderProjects.map(ele => (
                  <div 
                    key={ele.images}
                    className="item carousel" 
                    style={{padding: '0.5rem', height:'fit-content'}}
                  >
                    <div
                      style={{
                        background: "#FFFFFF",
                        border: "1px solid rgba(0,0,0,.125)",
                        borderRadius: '5px',
                        height: 'fit-content',
                        width: '100%',
                        padding: '1rem',
                        alignContent:'center',
                        alignItems:'center',
                      }}
                    >
                      <img src={ele.images} style={{width: '100%'}} />
                      <CarouselElements obj={ele} />
                    </div>
                  </div>
                ))}
              </OwlCarousel>
              {/* </Grid> */}
            </Grid>
          </div>
        )}
        
      </div>
    </>
  )
}

export default ConsumerDashboard
