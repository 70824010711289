import React,{ useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ImageIcon from '@mui/icons-material/Image';
import { Navigate, useNavigate } from "react-router-dom";
import { Button, TextField } from '@mui/material';
import { Link } from 'react-router-dom'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Tooltip from '@mui/material/Tooltip';


const columns = [
  {
    id: "project_name",
    label: "Project Name",
    // minWidth: 150,
  },
  {
    id: "launch_name",
    label: "Launch Name",
    // minWidth: 150,
  },
  {
    id: "amount",
    label: "Amount",
    minWidth: 100,
  },
  {
    id: "advance_adjusted_amount",
    label: "Advance Adjusted Amount",
    // minWidth: 150,
  },
  {
    id: "sourcing_sm",
    label: "Sourcing SM",
    // minWidth: 150,
  },
  {
    id: "tl",
    label: "TL",
    // minWidth: 150,
  },
  {
    id: "activity",
    label: "Activity",
    // minWidth: 150,
  },
  {
    id: "platform",
    label: "Platform",
    // minWidth: 150,
  },
  {
    id: "start_date",
    label: "Start Date",
    // minWidth: 150,
  },
  {
    id: "updated",
    label: "Updated",
    // minWidth: 150,
  },
];


const CustomButton = ({ field, form, ...props }) => {
  return (
    <button className="registrationbutton" {...field} {...props} >
      {props.children}
    </button>
  );
};

function DataList(props) {
  const { data, rows, hoabl_id, handleDataCall, setRows, setData, projectName,launchName } = props
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate()
  
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  

  const handleSearch = (e) => {
    // console.log(e.target.value,data)
    setPage(0)
    setRows(() => {
      return data.filter((item) => (item.project_name+item.launch_name).toLowerCase().includes(e.target.value.toLowerCase()))
    })
  }
  return (
    <div style={{padding:"1rem 0"}}>
      <div style={{width:'100%' ,padding:"0 0 1rem 0", display:'flex', justifyContent: 'space-between'}}>
        <Tooltip title="Search by Eligibility Status, Customer, Plot Number">
          <TextField label="Search" onChange={handleSearch} style={{width:"250px"}} />
        </Tooltip>
        {/* <Button className="registrationbutton"  variant="contained" onClick={() => navigate('create') } >Create</Button> */}
      </div>
      <TableContainer sx={{ maxHeight: '80vh' }} >
        <Table stickyHeader aria-label="sticky table" >
          <TableHead >
            <TableRow >
              {columns?.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth,}}
                  className="clientthtcell"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      
                      if (column.label === 'Action'){
                        // console.log(row['hoabl_id'])
                        return(
                          <TableCell key={column.id} align={column.align}>
                            <Link to={`edit/${row['id']}`} style={{all:'unset'}}>
                              <Button>Edit</Button>
                            </Link>
                          </TableCell>
                        )
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  )
}

export default DataList