import React,{ useState, useCallback, useEffect } from 'react'
import OwlCarousel from "react-owl-carousel";
import Grid  from "@mui/material/Grid"
import { useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import { TextField, Typography, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import CardContent from '@mui/material/CardContent';
import { Switch, Link } from 'react-router-dom'
import { imagesArray } from './constants';
import './index.css'

// const imagesArray = [
//   "url('https://hoabl.in/partners/assets/images/ayodhyacover.jpg')",
//   "url('https://hoabl.in/partners/assets/images/gulf_of_goacover.jpg')",
// ]

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const styles = {
  authFormContainer: {
    display:' flex',
    justifyContent:' center',
    alignItems:' center',
    // width:' 100vw',
    height: ' 90vh',
    flexDirection:'column',
  },
  authForm: {
    width:' 360px',
    boxShadow:' rgb(0 0 0 / 16%) 1px 1px 10px',
    // paddingTop:' 10px',
    // paddingBottom:' 10px',
    borderRadius:' 8px',
    backgroundColor:' white',
  },
  authFormContent:{
    paddingLeft:' 12%',
    paddingRight:' 12%',
    alignItems:"center",
    
  },
  authFormTitle:{
    textAlign:' center',
    marginBottom:' 1em',
    fontSize:' 24px',
    color:' rgb(34, 34, 34)',
    fontWeight:' 800',
  },
  label:{
    fontSize:' 14px',
    fontWeight:' 600',
    color:' rgb(34, 34, 34)',
  },
}

export const OtpLogin = (props) => {
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [otp, setOtp] = useState("")
  const [error, setError] = useState(false)
  const [otpSent, setOtpSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [userDetails, setUserDetails] = useState("")
  const navigate = useNavigate()
  const [timer, setTimer] = useState(60);    
  const timeOutCallback = useCallback(() => setTimer(currTimer => {
    if(currTimer !== 0) return currTimer - 1
    return currTimer
  }), []);

  useEffect(() => {
    setTimer(60)
  
    return () => {

    }
  }, [])
  

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  const resetTimer = function () {
    if (!timer) {
      setTimer(60);
    }
  };


  const handlePhoneChange = (e) => {
    // console.log(e.target.value)
    if(/^[0-9]*$/.test(e.target.value)){
      setPhone(e.target.value)
    } 
    if (error && phoneRegExp.test(e.target.value)){
      setError(false)
    }
  }

  const handleVerifyOtp = () => {
    setIsLoading(true)
    // if (phone.length !== 10){
    //   // console.log(phone.length)
    //   setError(true)
    //   return
    // }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "phoneNumber": phone,
      "country": "IN",
      "countryCode": "+91",
      "otp": otp
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
      cors:'no-cors'
    };

    var myHeaders1 = new Headers();
    myHeaders1.append("Content-Type", "application/json");

    var userCheck = JSON.stringify({
      "contact_person_phone": phone,
    });

    var requestOptions1 = {
      method: 'POST',
      headers: myHeaders1,
      body: userCheck,
      redirect: 'follow',
      cors:'no-cors'
    };

    fetch(process.env.REACT_APP_OTP_URL+"/verify-otp", requestOptions)
      .then(async(result) => {
        // console.log(Number(result.status%400))
        if (Number(result.status%400) <= 10 ){
          let error = await result.json()
          throw error
          // setIsLoading(false)
        }
        // console.log(result)
        return fetch(process.env.REACT_APP_BASE_URL+"/cp-master-details/login", requestOptions1)
        // window.location.reload(false);
      })
      .then(result => {
        // if (result.status === 400){
        //   setIsLoading(false)
        //   NotificationManager.error("User doesn't exist, Please Register", 'Error', 6000)
        //   return Promise.reject(result);
        // }
        if (result.status === 200){
          return result.json()
        }
        return Promise.reject(result);
      })
      .then(response => {
        setUserDetails(response)
        setIsLoading(false)
        localStorage.setItem('CP-USER-AUTH',JSON.stringify(response))
        navigate('/')
      })
      .catch(error => {
        console.log('error', error)
        NotificationManager.error(error.message, 'Error', 6000);
        setIsLoading(false)
      });
    // setIsLoading(false)
    // setOtpSent(true)
  }

  const handleSendOtp = () =>{ 
    setTimer(60)
    // if (phone.length !== 10){
    //   // console.log(phone.length)
    //   setError(true)
    //   return
    // }
    setIsLoading(true)

    var myHeaders1 = new Headers();
    myHeaders1.append("Content-Type", "application/json");

    var userCheck = JSON.stringify({
      "contact_person_phone": phone,
    });

    var requestOptions1 = {
      method: 'POST',
      headers: myHeaders1,
      body: userCheck,
      redirect: 'follow',
      cors:'no-cors'
    };

    var myHeaders2 = new Headers();
    myHeaders2.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "phoneNumber": phone,
      "email": email,
      "country": "IN",
      "countryCode": "+91"
    });

    var requestOptions2 = {
      method: 'POST',
      headers: myHeaders2,
      body: raw,
      redirect: 'follow',
      cors:'no-cors'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/cp-master-details/is-available", requestOptions1)
      .then(result => {
        if (result.status === 400){
          setIsLoading(false)
          NotificationManager.error("User doesn't exist, Please Register", 'Error', 6000)
          return Promise.reject(result);
        }
        if (result.status === 200){
          return result.json()
        }
        return Promise.reject(result);
      })
      .then(resp => {
        setEmail(resp.email)
        var tempraw = JSON.stringify({
          "phoneNumber": phone,
          "email": resp.email,
          "country": resp.country === "India" ? "IN" : "",
          "countryCode": resp.country_code
        });
    
        var temprequestOptions2 = {
          method: 'POST',
          headers: myHeaders2,
          body: tempraw,
          redirect: 'follow',
          cors:'no-cors'
        };
        return temprequestOptions2
      })
      .then(response => {
        // setUserDetails(response)
        return fetch(process.env.REACT_APP_OTP_URL+"/generate-otp", response)
      })
      .then(result => {
        if (result.status === 400){
          // setIsLoading(false)
        }
        // console.log(result)
        setIsLoading(false)
        setOtpSent(true)
      })
      .catch(error => {
        error.json().then(data => {
          // console.log(data)
          NotificationManager.error("Something Went Wrong", 'Error', 6000);
        })
        setIsLoading(false)
      });

    
    // fetch("http://13.233.253.58:3000"+"/api/generate-otp", requestOptions2)
    //   .then(result => {
    //     if (result.status === 400){
    //       // setIsLoading(false)
    //     }
    //     console.log(result)
    //     setIsLoading(false)
    //     setOtpSent(true)
    //   })
    //   .catch(error => {
    //     console.log('error', error)
    //     setIsLoading(false)
    //   });
    // setIsLoading(false)
    // setOtpSent(true)
  }


  return (
    <>
      <Card style={styles.authForm}>
        <CardContent style={styles.authFormContent}>
          <h3 style={styles.authFormTitle}>Sign In</h3>
          <div className="text-center">
            Not registered yet?{" "}
            <Link to="/signup" style={{all:'unset'}}>
              <Typography variant="button" color="primary" sx={{fontSize:'1.2rem', cursor:'pointer', textTransform:'none' }}>
                Sign Up
              </Typography>
            </Link>
          </div>
          <TextField label="Phone" error={error} value={phone} onChange={handlePhoneChange}  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} fullWidth sx={{marginTop:'2rem'}} />
          {otpSent ?
            (
              <>
                <TextField label="OTP" value={otp} onChange={(e) => setOtp(e.target.value)} fullWidth sx={{marginTop:'2rem'}} />
                <Button disabled={timer !== 0} className="text-center" style={{marginTop:'1rem'}} onClick={() => {resetTimer();handleSendOtp()}}>Resend OTP ({timer})</Button>
                <Button variant="contained" color="primary" fullWidth sx={{marginTop:'1rem',padding:"1rem"}} onClick={handleVerifyOtp} >{isLoading ? <CircularProgress sx={{color:"#FFFFFF"}} size="1.5rem"/>:"Verify OTP"}</Button>
              </>
            ): (
              <Button variant="contained" color="primary" fullWidth sx={{marginTop:'2rem',padding:"1rem"}} onClick={handleSendOtp} >{isLoading ? <CircularProgress sx={{color:"#FFFFFF"}} size="1.5rem"/>:"Send OTP"}</Button>
            )
          }
          
        </CardContent>
      </Card>
    </>
  )
}


function Login() {
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={7} sx={{ overflow: "hidden" }}>
          <img className="logo-image" src="/images/hoabl_logo_black.png" />
          <OwlCarousel
            autoplay
            autoplayTimeout={5000}
            autoplayHoverPause={true}
            loop={true}
            animateOut="fadeOut"
            className=""
            items={1}
            dots={false}
          >
            {imagesArray.map(ele => (
              <div
                key={ele}
                className="item carousel"
                style={{
                  // height: matches ? "100vh":"220px",
                  background: "#000000",
                  backgroundImage: ele,
                  backgroundSize: "cover",
                }}
              ></div>
            ))}
          </OwlCarousel>
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <div style={{
            display:' flex',
            justifyContent:' center',
            alignItems:' center',
            // width:' 100vw',
            height: '100%',
            flexDirection:'column',
            background: 'white',
          }}>
            <img className="bluechipclub" src="/images/bluechipclub.png" />
            <OtpLogin />
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default Login