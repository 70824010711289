import React,{ useState } from 'react'
import { Formik, Form, Field, useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {useNavigate, useParams} from "react-router-dom"
import CircularProgress from '@mui/material/CircularProgress';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MenuItem from "@mui/material/MenuItem";
import { choices } from '../utility/choices'


const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const netDealsSchema = yup.object().shape({
  application_id: yup.string().required("This is Required"),
  agreement_value: yup.number().required("This is Required"),
  brokerage: yup.number().required("This is Required"),
  bucket: yup.string(),
  collection: yup.number(),
  created_by: yup.string(),
  customer_name: yup.string().required("This is Required"),
  eligibility_amount: yup.string().required("This is Required"),
  eligibility_status: yup.string().required("This is Required"),
  gst: yup.string().required("This is Required"),
  gst_amount: yup.number().required("This is Required"),
  hoabl_id: yup.string().required("This is Required"),
  invoice_id: yup.string(),
  invoice_status: yup.string(),
  launch_name: yup.string().required("This is Required"),
  milestone: yup.number().required("This is Required"),
  net_amount: yup.number(),
  payment_status: yup.string(),
  project_name: yup.string().required("This is Required"),
  sm_name: yup.string(),
  tds_amount: yup.number().required("This is Required"),
  unique_plot_number: yup.string().required("This is Required"),
});





const TextInput = ({ field, form, ...props }) => {
  // if (field.name === "org_name") {
  //   console.log(field, form);
  // }

  return (
    <TextField
      {...field}
      {...props}
      error={
        (form?.touched[field?.name] && form?.errors[field?.name]) ||
        (form?.errors[field?.name] && form?.submitCount > 0)
      }
      helperText={form?.touched[field?.name] && form?.errors[field?.name]}
    />
  );
};

const SelectInput = ({ field, form, ...props }) => {
  // if (field.name === "org_name") {
  //   console.log(field, form);
  // }

  return (
    <input
      {...field}
      {...props}
      error={
        (form?.touched[field?.name] && form?.errors[field?.name]) ||
        (form?.errors[field?.name] && form?.submitCount > 0)
      }
      helperText={form?.touched[field?.name] && form?.errors[field?.name]}
      type='select'
      className="select"
    >
      {props.children}
    </input>
  );
};

const CustomButton = ({ field, form, ...props }) => {
  return (
    <button className="registrationbutton" {...field} {...props} >
      {props.children}
    </button>
  );
};

function CreateNetDeals() {
  const [isloading, setIsloading] = useState(false);
  const [initialValue, setInitialValue] = useState({})
  const navigate = useNavigate();
  let authToken;
  try{
    authToken = JSON.parse(localStorage.getItem('EMPLOYEE-AUTH'))
  }catch(err){
    authToken = null
  }
  const handleSubmit = async (values, actions, errors) => {
    setIsloading(true)
    // console.log(values, actions, errors);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer "+authToken.token);

    var raw = JSON.stringify({
      application_id: values.application_id,
      agreement_value: values.agreement_value,
      brokerage: values.brokerage,
      bucket: values.bucket,
      collection: values.collection,
      created_by: values.created_by,
      customer_name: values.customer_name,
      eligibility_amount: values.eligibility_amount,
      eligibility_status: values.eligibility_status,
      gst: values.gst,
      gst_amount: values.gst_amount,
      hoabl_id: values.hoabl_id,
      invoice_id: values.invoice_id,
      // invoice_status: values.invoice_status,
      launch_name: values.launch_name,
      milestone: values.milestone,
      net_amount: values.net_amount,
      // payment_status: values.payment_status,
      project_name: values.project_name,
      sm_name: values.sm_name,
      tds_amount: values.tds_amount,
      unique_plot_number: values.unique_plot_number,
    });

    var requestOptions = {
      method: 'post',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/base-earning-details", requestOptions)
      .then(result => {
        // console.log(result.status)
        if (result.status%400<10){
          NotificationManager.error('Not authorised to edit the data', 'Error', 6000);
        }
        setIsloading(false)
        navigate("/admin/netdeals")
      })
      .catch(error => {
        console.log('error', error)
        NotificationManager.error('Something went wrong', 'Error', 6000);
        setIsloading(false)
      });

  }

  return (
    <div>
      <Formik
        initialValues={{...initialValue}}
        validationSchema={netDealsSchema}
        onSubmit={handleSubmit}
      >
        {({ touched, errors, values, submitCount, setFieldValue, handleSubmit,submitForm }) => (
          <Form  >
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <div style={{ padding: "1rem", display:'flex', justifyContent:'space-between' }}>
                  <h4 style={{ font: "Jost" }}>Create NetDeals</h4>
                  <Button className="registrationbutton"  variant="contained" startIcon={<ArrowBackIosNewIcon />} onClick={() => navigate(-1) } >Back</Button>
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  required
                  name="application_id"
                  label="Application Id"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  required
                  name="agreement_value"
                  label="Agreement Value"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  required
                  name="brokerage"
                  label="Brokerage"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="bucket"
                  label="Bucket"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="collection"
                  label="Collection (%)"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              {/* <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="created_by"
                  label="Created By"
                  fullWidth
                  component={TextInput}
                />
              </Grid> */}
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  required
                  name="customer_name"
                  label="Customer Name"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  required
                  name="eligibility_amount"
                  label="Eligibility Amount"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {/* <Field
                  type="text"
                  name="eligibility_status"
                  label="eligibility_status"
                  fullWidth
                  component={TextInput}
                /> */}
                <Field
                  name="eligibility_status"
                  label="Eligibility Status"
                  required
                  fullWidth
                  select={true}
                  SelectProps={{
                    onClose: () => {
                      setTimeout(() => {
                        document.activeElement.blur();
                      }, 0);
                    },
                  }}
                  onChange={(e) => {
                    setFieldValue("eligibility_status",e.target.value)
                  }}
                  component={TextInput}
                >
                  {choices.eligibility_status.map(item => <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>)}
                </Field>
              </Grid>
              <Grid item md={6} xs={12}>
                {/* <Field
                  type="text"
                  name="gst"
                  label="gst"
                  fullWidth
                  component={TextInput}
                /> */}
                <Field
                  name="gst"
                  label="GST Type"
                  required
                  fullWidth
                  select={true}
                  SelectProps={{
                    onClose: () => {
                      setTimeout(() => {
                        document.activeElement.blur();
                      }, 0);
                    },
                  }}
                  onChange={(e) => {
                    setFieldValue("gst",e.target.value)
                  }}
                  component={TextInput}
                >
                  {choices.gst.map(item => <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>)}
                </Field>
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="gst_amount"
                  label="Gst Amount"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  required
                  name="hoabl_id"
                  label="Hoabl Id"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              {/* <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="invoice_id"
                  label="invoice_id"
                  fullWidth
                  component={TextInput}
                />
              </Grid> */}
              {/* <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="invoice_status"
                  label="invoice_status"
                  fullWidth
                  component={TextInput}
                />
              </Grid> */}
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  required
                  name="launch_name"
                  label="Launch Name"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  required
                  name="milestone"
                  label="Milestone"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  required
                  name="net_amount"
                  label="Net Amount"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              {/* <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="payment_status"
                  label="payment_status"
                  fullWidth
                  component={TextInput}
                />
              </Grid> */}
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  required
                  name="project_name"
                  label="Project Name"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="sm_name"
                  label="Sm Name"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  required
                  name="tds_amount"
                  label="Tds Amount"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  required
                  name="unique_plot_number"
                  label="Unique Plot Number"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  required
                  name="advance_amount_adjusted"
                  label="Advance Amount Adjusted"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
                            
              <Grid item md={12} xs={12}>
                <Field id="submitEdit" style={{width:'100%'}} name="submitEdit" type="submit"  component={CustomButton}>
                    {isloading ? <CircularProgress sx={{color:"#FFFFFF"}} size="1.5rem"/>:"Create"}
                </Field>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CreateNetDeals