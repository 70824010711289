import React, { useCallback } from 'react'
import { useNavigate } from "react-router-dom";
import { Button } from '@mui/material';
import { NotificationManager } from 'react-notifications';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Tooltip from '@mui/material/Tooltip';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton, Box } from '@mui/material';
import { Edit } from '@mui/icons-material';
import MaterialReactTable from 'material-react-table';
import BulkLead from './BulkLead';

function ListLeadMaster() {
    const [data, setData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isRefetching, setIsRefetching] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [refresh, setRefresh] = React.useState(true);
    const navigate = useNavigate()
    let authToken;

    try {
        authToken = JSON.parse(localStorage.getItem('EMPLOYEE-AUTH'))
    } catch (err) {
        authToken = null
    }

    React.useEffect(() => {
        handleDataCall()
    }, [])

    React.useEffect(() => {
        handleDataCall()
    }, [refresh])

    const columns = React.useMemo(() => [
        {
            accessorKey: 'hoabl_id',
            header: 'Hoabl Id',
        },
        {
            accessorKey: 'firstname',
            header: 'First Name',
        },
        {
            accessorKey: 'lastname',
            header: 'Last Name',
        },
        {
            accessorKey: 'emailaddress1',
            header: 'Email',
        },
        {
            accessorKey: 'mobilephone',
            header: 'Mobile Phone',
        },
        {
            accessorKey: 'project_name',
            header: 'Project Name',
        },
        {
            accessorKey: 'launch_name',
            header: 'Launch Name',
        },
    ])

    const handleDataCall = useCallback(() => {
        if (!data.length) {
            setIsLoading(true)
        } else {
            setIsRefetching(true)
        }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer" + " " + authToken.token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_BASE_URL + "/lead", requestOptions)
            .then(response => {
                if (response.status % 400 < 10) {
                    NotificationManager.error('Not authorised to view this data', 'Error', 6000);
                    throw new Error('Unauthorised role!!');
                }
                return response.json()
            })
            .then(result => {
                let tempData = result.results;
                setData(tempData)
                setRows(tempData)
                setIsLoading(false)
                setIsRefetching(false)
            })
            .catch(error => {
                console.log('error', error)
                setIsLoading(false)
                setIsRefetching(false)
            });
    })

    return (
        <div style={{ padding: "1rem 1rem" }}>
            <div style={{ width: '100%', padding: "0 0 1rem 0", display: 'flex', justifyContent: 'space-between' }}>
                <div>

                </div>
                <div className="flex">
                    <BulkLead user={authToken} />
                    <IconButton onClick={() => setRefresh((prev) => (!prev))} sx={{ marginRight: "1rem" }}><RefreshIcon /></IconButton>
                    <Button sx={{ marginRight: "1rem" }} className="registrationbutton" variant="contained" startIcon={<ArrowBackIosNewIcon />} onClick={() => navigate(-1)} >Back</Button>
                </div>
            </div>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                initialState={{ pagination: { pageSize: 5, pageIndex: 0 } }}
                columns={columns}
                data={rows}
                enableColumnOrdering
                enableEditing
                state={{
                    isLoading: isLoading,
                    showProgressBars: isRefetching,
                }}
                renderRowActions={({ row, table }) => {
                    return (
                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <Tooltip arrow placement="left" title="Edit">
                                <IconButton onClick={() => navigate(`edit/${row?.original.id}`)}>
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )
                }}
            />
        </div>
    )
}

export default ListLeadMaster