import React, { useState } from 'react'
import { Formik, Form, Field, useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {useNavigate, useParams} from "react-router-dom"
import CircularProgress from '@mui/material/CircularProgress';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Stack from '@mui/material/Stack';
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const employeeSchema = yup.object().shape({
  current_password: yup.string().required("This is Required"),
  new_password: yup.string().required("This is Required"),
  confirm_password: yup.string().required("This is Required"),
});

const TextInput = ({ field, form, ...props }) => {
  // if (field.name === "org_name") {
  //   console.log(field, form);
  // }

  return (
    <TextField
      {...field}
      {...props}
      error={
        (form?.touched[field?.name] && form?.errors[field?.name]) ||
        (form?.errors[field?.name] && form?.submitCount > 0)
      }
      helperText={form?.touched[field?.name] && form?.errors[field?.name]}
    />
  );
};

const SelectInput = ({ field, form, ...props }) => {
  // if (field.name === "org_name") {
  //   console.log(field, form);
  // }

  return (
    <input
      {...field}
      {...props}
      error={
        (form?.touched[field?.name] && form?.errors[field?.name]) ||
        (form?.errors[field?.name] && form?.submitCount > 0)
      }
      helperText={form?.touched[field?.name] && form?.errors[field?.name]}
      type='select'
      className="select"
    >
      {props.children}
    </input>
  );
};

const CustomButton = ({ field, form, ...props }) => {
  return (
    <button className="registrationbutton" {...field} {...props} >
      {props.children}
    </button>
  );
};


function UpdatePassword() {
  const [isloading, setIsloading] = useState(false);
  // const [initialValue, setInitialValue] = useState({})
  const navigate = useNavigate();
  let user;
  try{
    user = JSON.parse(localStorage.getItem('EMPLOYEE-AUTH'))
  }catch(err){
    user = null
  }

  const handleSubmit = (values, actions, errors) => {
    setIsloading(true)
    // console.log(values, actions, errors);
    
    if (values?.new_password !== values?.confirm_password){
      // console.log((values?.new_password !== values?.confirm_password))
      NotificationManager.error('Confirm password Mismatched', 'Error', 6000);
      setIsloading(false)
      return
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer "+ user.token);

    var raw = JSON.stringify({
      email: user?.info?.email,
      current_password: values.current_password,
      new_password: values.new_password,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/employee-details/update-password", requestOptions)
      .then(result => {
        // console.log(result.status)
        if (result.status%500<10){
          NotificationManager.error('Something Went Wrong', 'Error', 6000);
          return
        }
        if (result.status%400<10){
          NotificationManager.error('Not authorised to edit the data', 'Error', 6000);
          return 
        }
        NotificationManager.success('Password Updated Successfully', 'Error', 6000);
        setIsloading(false)
        navigate("/admin/employee")
      })
      .catch(error => {
        console.log('error', error)
        NotificationManager.error('Something went wrong', 'Error', 6000);
        setIsloading(false)
      });
  }

  return (
    <div>
      <Formik
        initialValues={{}}
        validationSchema={employeeSchema}
        onSubmit={handleSubmit}
      >
        {({ touched, errors, values, submitCount, setFieldValue, handleSubmit,submitForm }) => (
          <Form  >
            <Grid container spacing={2} >
              <Grid item align="left" xs={12} md={12} lg={12}>
                <div style={{ padding: "1rem", display:'flex', justifyContent:'space-between' }}>
                  <h4 style={{ font: "Jost" }}>Update Password</h4>
                  <Button className="registrationbutton"  variant="contained" startIcon={<ArrowBackIosNewIcon />} onClick={() => navigate(-1) } >Back</Button>
                </div>
              </Grid>
              <Grid item align="left" xs={12} md={6} lg={4}>
                <Stack  spacing={2}>
                  {/* <div style={{ padding: "1rem" }}> */}
                    {/* <h4 style={{ font: "Jost" }}>Update Password</h4> */}
                  {/* </div> */}
                  <Field
                    type="password"
                    name="current_password"
                    label="Current Password"
                    fullWidth
                    component={TextInput}
                  />
                  <Field
                    type="password"
                    name="new_password"
                    label="New Password"
                    fullWidth
                    component={TextInput}
                  />
                  <Field
                    type="text"
                    name="confirm_password"
                    label="Confirm Password"
                    fullWidth
                    component={TextInput}
                  />
                  <Field  style={{width:'100%'}}  type="submit" onClick={handleSubmit}  component={CustomButton}>
                    {isloading ? <CircularProgress sx={{color:"#FFFFFF"}} size="1.5rem"/>:"Change Password"}
                  </Field>
                </Stack>
                
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default UpdatePassword