import React,{ useState, useEffect } from 'react'
import ReactDom from 'react-dom'
import ImageIcon from '@mui/icons-material/Image';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
 
const MODAL_STYLES = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FFF',
  padding: '1rem',
  zIndex: 1310,
  width: 'fit-content',
  maxWidth: '90vw',
  minWidth: '340px',
  textAlign: 'center',
}

const OVERLAY_STYLES = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, .7)',
  zIndex: 1310,
}

export default function ImageModal({ children, url,ls_token_key}) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [open, setOpen] = useState(false)
  const [imgUrl, setImgUrl] = useState("")
  // console.log(ls_token_key)
  let authToken;
  try{
    authToken = JSON.parse(localStorage.getItem(ls_token_key))
  }catch(err){
    authToken = null
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if(open){
      fetchSignedUrl()
    }
  
    return () => {
      
    }
  }, [open])
  

  const fetchSignedUrl = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer" +" "+ authToken.token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    // https://cp-portal-files.s3.ap-south-1.amazonaws.com/hoablQr.png

    fetch(process.env.REACT_APP_BASE_URL+"/file/signedURL?s3Url="+url, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        // setIsLoading(false)
        setImgUrl(result.url)
        // setRows(result.channel_partner)
      })
      .catch(error => console.log('error', error));
    setIsLoading(false)
  }

  

  // console.log(url)

  if (!open) return (
    // <IconButton
    //     // aria-label="expand row"
    //     size="large"
    //     // color="primary"
    //     sx={{color:"#161617"}}
    //     onClick={() => setOpen(true)}
    //   >
        <ImageIcon onClick={() => setOpen(true)}/>
    // </IconButton>
    
  )

  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLES} onClick={handleClose}/>
      <div style={MODAL_STYLES}>
        {isLoading ? (
          <CircularProgress sx={{color:"#333333"}} size="10rem"/>
        ) : (
          <div>
            {
              url.includes(".pdf") ? (
                // <p>This is PDF file <a href={imgUrl} target="_blank">Click here to view pdf</a>.</p>
                <iframe src={imgUrl} style={{width:'80vw',height:'80vh',minWidth:'320px',maxHeight:'80vh',minWidth:'320px'}} ></iframe>
              ) : (
                <img src={imgUrl} style={{maxWidth:'80vw',maxHeight:'75vh',width:'100%',minWidth:'320px'}} />
              )
            }
          </div>
        )}
      </div>
    </>,
    document.getElementById('portal')
  )
}