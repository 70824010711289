const SUPER_AUTHORITY = [
  // 'rutvik.pimple@hoabl.in',
  'admin@hoabl.in',
]

const DELETE_AUTHORITY = [
  // 'rutvik.pimple@hoabl.in',
  'admin@hoabl.in',
  // 'account@hoabl.in'
]

const EDIT_AUTHORITY = [
  // 'rutvik.pimple@hoabl.in',
  'admin@hoabl.in',
  // 'account@hoabl.in'
]

const ACCOUNTS_AUTHORITY = [
  // 'rutvik.pimple@hoabl.in'
  'account@hoabl.in',
  'accounts@hoabl.in',
  'anushree.shah@hoabl.in',
  'sumeet.yadav@hoabl.in',
  'satish.kadam@hoabl.in',
]


export const permissions = {
  delete: function(usermail) {
    let authorised = DELETE_AUTHORITY.includes(usermail)
    return authorised
  },
  edit: function(usermail) {
    let authorised = EDIT_AUTHORITY.includes(usermail)
    return authorised
  },
  accounts: function(usermail) {
    let authorised = ACCOUNTS_AUTHORITY.includes(usermail)
    return authorised
  },
  super: function(usermail) {
    let authorised = SUPER_AUTHORITY.includes(usermail)
    return authorised
  }
}