import React,{ useState } from 'react'
import Grid  from "@mui/material/Grid"
import useMediaQuery from '@mui/material/useMediaQuery';
import { BrowserRouter , Route, Routes, Switch, Link, Redirect } from 'react-router-dom'
import { Edit, Close } from "@mui/icons-material";
import CreateAdvancePayments from './CreateAdvancePayments';
import EditAdvancePayments from './EditAdvancePayments';
import ListAdvancePayments from './ListAdvancePaymentsV1';
import ShowAdvancePayments from './ShowAdvancePayments';


const nav_elements = [
  {
    title:"Dashboard",
    img_obj: "/images/dashboard.png",
    url: "/info/"
  },
  {
    title:"Application Incentive",
    img_obj: "/images/applications.png",
    url: "/info/app-incentive"
  },
  {
    title:"Base Earnings",
    img_obj: "/images/brokerage.png",
    url: "/info/netdeals"
  },
  {
    title:"Ladder Incentive",
    img_obj: "/images/ladder.png",
    url: "/info/ladder-incentive"
  },
  {
    title:"Advance Payments",
    img_obj: "/images/AdvancePayments.png",
    url: "/info/adv-payments"
  },
]

const NavbarElements = () => {
  const [elements, setElements] = useState(nav_elements)
  const styles = {
          height: "30px",
          width: "50px",
          // zIndex: "1",
          overflow: "visible",
          alignItems: "center",
        }

  return (
    <>
      {elements.map((item,index) => (
        <Link to={item.url} style={{color:"#57564F",fontWeight:"600"}}>
          <div
            key={item.title}
            className="card card-body"
            style={{
              height: "50px",
              width: "70px",
              // zIndex: "1",
              overflow: "visible",
              marginLeft: index!==0 ? "1rem":"0",
              alignItems: "center",
            }}
          >
          <img src={item.img_obj} width="30px" />
          <p style={{fontSize:"11px", margin:"0.2rem", fontWeight:"600"}}>{item.title}</p>
        </div>
        </Link>
      ))}
    </>
  );
}

function AdvancePayments(props) {
  const matches = useMediaQuery('(min-width:1080px)');
  React.useEffect(() => {
    props.setIsSelected("Advance Payments") 
    return () => {
      
    }
  }, [])
    // console.log(props)

    
  return (
    <div style={{padding:"1rem"}}>
      <Routes>
        <Route exact path='/' element={<ListAdvancePayments />}/>
        <Route exact path='/create' element={<CreateAdvancePayments />}/>
        <Route exact path='/edit/:id' element={<EditAdvancePayments />}/>
        <Route exact path='/show/:id' element={<ShowAdvancePayments />}/>
      </Routes>
    </div>
  )
}

export default AdvancePayments