import React, { useState, useEffect } from 'react'
import ReactDom from 'react-dom'
import { Formik, Form, Field, useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom"
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import {NotificationContainer, NotificationManager} from 'react-notifications';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const invoiceSchema = yup.object().shape({
  invoice_value: yup.number().required("This is Required"),
  invoice_no: yup.string().required("This is Required"),
  invoice_date: yup.string().required("This is Required"),
  invoice_upload: yup.string().required("You need to provide a file")
});

const reqFormat = (num) => {
  return (num).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
}

const getFileId = () => {
  var newDate = new Date();
  return parseInt(newDate.getFullYear()+''+reqFormat(newDate.getMonth()+1)+''+reqFormat(newDate.getDate())+''+reqFormat(newDate.getHours())+''+reqFormat(newDate.getMinutes())+''+reqFormat(newDate.getSeconds()))
}


const TextInput = ({ field, form, ...props }) => {
  // if (field.name === "org_name") {
  //   console.log(field, form);
  // }

  return (
    <TextField
      {...field}
      {...props}
      error={
        (form?.touched[field?.name] && form?.errors[field?.name]) ||
        (form?.errors[field?.name] && form?.submitCount > 0)
      }
      helperText={form?.touched[field?.name] && form?.errors[field?.name]}
    />
  );
};

const CustomButton = ({ field, form, ...props }) => {
  return (
    <button className="registrationbuttonclient" {...field} {...props} >
      {props.children}
    </button>
  );
};


export default function InvoiceUpload(props) {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let authToken;
  try{
    authToken = JSON.parse(localStorage.getItem(props.ls_token_key))
  }catch(err){
    authToken = null
  }

  let type_id = props.type_id
  
  const handleFileUpload = async(fileInput,fileUrl) => {
    // console.log(fileInput,fileUrl)
    var formdata = new FormData();
    formdata.append("file", fileInput,fileUrl+fileInput.name.replaceAll(" ","_"));
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    const response = await fetch(process.env.REACT_APP_BASE_URL+"/file/upload", requestOptions)
    const res = await response.json()
    if (response.status===501){
      NotificationManager.error(res.error.message, 'Form Not Submited', 8000);
    }
    return res.location
  }

  const handleSubmit = async(values, actions, errors) => {
    setIsLoading(true)
    // console.log(values, actions, errors);
    let timeString = getFileId()
    let nameString = `${timeString}`
  
    const fileUri = await handleFileUpload(values.invoice_upload, nameString)
    
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer" +" "+ authToken?.token);
    // console.log(props.type,type_id)
    var raw = JSON.stringify({
      invoice_value: values.invoice_value,
      invoice_no: values.invoice_no,
      invoice_date: values.invoice_date,
      invoice_status: "INVOICE_UPLOADED",
      invoice_url: fileUri,
      type: props.type,
      type_id: props.type_id,
      hoabl_id: props.hoabl_id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/invoice-details", requestOptions)
      .then(result => {
        // console.log(result.status%400)
        if (result.status%400 < 110){
          NotificationManager.error('Something went wrong Failed to Upload ', 'Error', 6000);
          throw "Something went wrong"
        }
        // console.log(result)
        NotificationManager.success("Successfully Uploaded", 'Success', 6000);
        return result.json()
        // navigate("/thanks")
      })
      .then(resp => {
        props.setValue && props.setValue(resp.id)
        setIsLoading(false)
      })
      .catch(error => {
        // navigate("/error")
        NotificationManager.error( "Something Went Wrong" , 'Error', 6000);
        // console.log('error', error)
        setIsLoading(false)
      });
    
    handleClose()
    props.handleDataRefresh()
  }

  return (
    <div>
      {/* <IconButton
        // aria-label="expand row"
        size="large"
        // color="primary"
        sx={{color:"#161617"}}
        onClick={handleOpen}
      > */}
        <CloudUploadIcon size="large" onClick={handleOpen}/> 
      {/* </IconButton> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Formik
            initialValues={{
              invoice_date: Date.now('dd/mm/yyyy'),
              invoice_value: props.invoice_value
            }}
            validationSchema={invoiceSchema}
            onSubmit={handleSubmit}
          >
            {({ touched, errors, values, submitCount, setFieldValue }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    {/* <div style={{ padding: "1rem" }}> */}
                      <h4 style={{ font: "Jost" }}>Invoice Details</h4>
                    {/* </div> */}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Field
                      name="invoice_value"
                      label="Invoice Amount"
                      fullWidth
                      component={TextInput}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Field
                      name="invoice_no"
                      label="Invoice No."
                      fullWidth
                      component={TextInput}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Field
                      name="invoice_date"
                      label="Invoice Date"
                      type="date"
                      fullWidth
                      component={TextInput}
                    />
                  </Grid>
                  <Grid item md={5} xs={10}>
                    <div style={{ padding: "1rem" }}>
                      <p>Invoice Upload</p>
                      {errors?.invoice_upload && (
                        <p className="error">{errors?.invoice_upload }</p>
                      )}
                    </div>
                  </Grid>
                  <Grid item md={1} xs={2}>
                    <div style={{ padding: "1rem 0 1rem 0" }}>
                      <label for="invoice_upload">
                        <CloudUploadIcon /><span style={{color:"rgb(211,47,47)"}}>*</span>
                      </label>
                      <input
                        type="file"
                        accept="image/jpg,image/png,application/pdf,image/x-eps"
                        id="invoice_upload"
                        name="invoice_upload"
                        onChange={(event) => {
                          // console.log(event.target.files[0])
                          setFieldValue(
                            "invoice_upload",
                            event.target.files[0]
                          );
                        }}
                        hidden
                      />
                    </div>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Field type="submit" component={CustomButton}>
                        {isLoading ? <CircularProgress sx={{color:"#FFFFFF"}} size="1.5rem"/>:"Submit"}
                    </Field>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}


