export const choices = {
  eligibility_status : [
    {value:"Yes",id:'1'},
    {value:"No",id:'2'}
  ],
  gst : [
    {value:"NO",id:'1'},
    {value:"IGST",id:'2'},
    {value:"CGST",id:'3'}
  ],
  invoice_status : [
    {value:"INVOICE_UPLOADED",id:'1'},
    {value:"ACCEPTED",id:'2'},
    {value:"REJECTED",id:'3'}
  ],
  payment_status : [
    {value:"INVOICE_PROCESSING",id:'1'},
    {value:"PAID",id:'2'},
    {value:"CANCELLED",id:'3'}
  ],

}