import React,{ useEffect } from 'react'
import hoabl_logo from "../assets/hoabl_logo_white.png"
import {useNavigate} from "react-router-dom"

function Thanks() {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/")
    },5000)
  
  }, [])
  
  return (
    <div style={{ padding: "1rem", width:"80%",minWidth:"360px"}}>
      <div style={{background:"#161617", width:"100%", padding:"1rem", marginBottom:"1rem", color:"white", borderRadius:"5px", alignContent:"center", alignItems:"center",textAlign:"center"}}>
        <img src={hoabl_logo} width="150px" />
        <h2 style={{color:"#FFFFFF", fontFamily:"Jost"}}>Thank You!!!</h2>
        <p style={{color:"#FFFFFF", fontFamily:"Jost"}}>Your Onboarding is completed successfully.</p>
      </div>
    </div>
  )
}

export default Thanks