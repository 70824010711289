import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Navigate, useNavigate } from "react-router-dom";
import { Button, TextField } from '@mui/material';
import { Link } from 'react-router-dom'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InvoiceUpload from '../../components/InvoiceUpload';
import ImageModal from '../../components/ImageModal';
import Tooltip from '@mui/material/Tooltip';

const columns = [
  {
    id: "expand",
    label: "",
    minWidth: 10,
  },
  {
    id: "project_name",
    label: "Project Name",
    minWidth: 100,
  },
  {
    id: "launch_name",
    label: "Launch Name",
    minWidth: 100,
  },
  {
    id: "customer_name",
    label: "Customer Name",
    minWidth: 100,
  },
  {
    id: "unique_plot_number",
    label: "Plot Number",
    minWidth: 100,
  },
  // {
  //   id: "eligibility_status",
  //   label: "Eligibility Status",
  //   minWidth: 100,
  // },
  {
    id: "total_amount",
    label: "Eligibility Amount",
    minWidth: 100,
  },
  {
    id: "tds_amount",
    label: "TDS Amount",
    minWidth: 100,
  },
  {
    id: "gst_amount",
    label: "GST Amount",
    minWidth: 100,
  },
  {
    id: "advance_amount_adjusted",
    label: "Advance Amount Adjusted",
    minWidth: 100,
  },
  // {
  //   id: "invoice_status",
  //   label: "Invoice Status",
  //   minWidth: 100,
  // },
  {
    id: "payment_status",
    label: "Payment Status",
    minWidth: 100,
  },
  {
    id: "remark",
    label: "Remark",
    minWidth: 100,
  },
];

const recordColumns = [
  // {
  //   id: "application_id",
  //   label: "Application_id",
  //   // minWidth: 150,
  // },
  {
    id: "upload_invoice",
    label: "Invoice Document",
    // minWidth: 100,
  },
  {
    id: "milestone",
    label: "Milestone",
  },
  {
    id: "customer_name",
    label: "Customer Name",
    // minWidth: 150,
  },
  {
    id: "eligibility_status",
    label: "Eligibilty Status",
    // minWidth: 150,
  },
  {
    id: "eligibility_amount",
    label: "Eligible Amount",
    // minWidth: 150,
  },
  {
    id: "gst_amount",
    label: "Gst Amount",
    // minWidth: 150,
  },
  {
    id: "collection",
    label: "Collection",
    // minWidth: 150,
  },
  {
    id: "agreement_value",
    label: "Agreement Value",
    // minWidth: 150,
  },
  {
    id: "invoice_status_info",
    label: "Invoice Status",
    // minWidth: 150,
  },
  {
    id: "payment_status",
    label: "Payment Status",
    // minWidth: 150,
  },
  {
    id: "tds",
    label: "Tds",
    // minWidth: 150,
  },
];



function Row(props) {
  const { row, hoabl_id, handleDataRefresh } = props;
  const [open, setOpen] = React.useState(false);

  const handleDataReset = () => {
    handleDataRefresh()
  }

  const invoiceDocumentRender = (data) => {
    if (data.invoice_status_info === "REJECTED"){
      return (
        <div style={{display:"flex"}}>
          <InvoiceUpload ls_token_key="CP-USER-AUTH" type="base_earning" type_id={[data.id]} handleDataRefresh={handleDataReset} hoabl_id={hoabl_id} invoice_value={data.eligibility_amount} />
          <ImageModal url={data.invoice_img_url} ls_token_key={"CP-USER-AUTH"} />
        </div>
      )
    }
    if (data.invoice_img_url){
      return <ImageModal url={data.invoice_img_url} ls_token_key={"CP-USER-AUTH"} />
    }

    if  (data.eligibility_status === "Yes") {
      return <InvoiceUpload ls_token_key="CP-USER-AUTH" type="base_earning" type_id={[data.id]} handleDataRefresh={handleDataReset} hoabl_id={hoabl_id} invoice_value={data.eligibility_amount} />
    }

    return null
  }

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        {columns.map((column) => {
          const value = row[column.id];
          if (column.id === 'expand'){
            return (
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
            )
          }
          
          if (column.label === 'Action'){
            // console.log(row['hoabl_id'])
            return(
              <TableCell key={column.id} align={column.align}>
                <Link to={`edit/${row['id']}`} style={{all:'unset'}}>
                  <Button>Edit</Button>
                </Link>
              </TableCell>
            )
          }
          return (
            <TableCell key={column.id} align={column.align}>
              {typeof(value) === 'number'
                              ? value.toFixed(2)
                              : value}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={18}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box id="box" sx={{ margin: 1, maxWidth:'95vw', overflow:'scroll' }}>
              {/* <Typography variant="h6" gutterBottom component="div">
                History
              </Typography> */}
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                  {recordColumns?.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth,}}
                      className="clientthtcell"
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.records?.map((recordRow) => (
                    <TableRow key={recordRow.id}>
                      {recordColumns?.map((column) => {
                        // console.log(recordRow.id)
                        const value = recordRow[column.id];
                        if (column.id === 'upload_invoice'){
                          return (
                            <TableCell>
                              {invoiceDocumentRender(recordRow)}
                            </TableCell>
                          )
                        }
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {typeof(value) === 'number'
                              ? value.toFixed(2)
                              : value}
                          </TableCell>
                        )
                      })}
                      
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}




// export default function CollapsibleTable() {
export default function DataList_v2(props) {
  const { data, rows, hoabl_id, handleDataCall, setRows, setData, projectName,launchName, user } = props
  // const [data, setData] = React.useState([]);
  // const [rows, setRows] = React.useState([]);
  const navigate = useNavigate()
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // let authToken;
  // try{
  //   authToken = JSON.parse(localStorage.getItem('CP-USER-AUTH'))
  // }catch(err){
  //   authToken = null
  // }
  
  React.useEffect(() => {
    
    handleDataCall()

  }, [])

  React.useEffect(() => {
    
    setPage(0)
    setRows(() => {
      return data.filter((item) => ((item.project_name).toLowerCase().includes(props.projectName.toLowerCase()) && (item.launch_name).toLowerCase().includes(props.launchName.toLowerCase())))
    })
  }, [props.projectName,props.launchName])
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  // const handleDataCall = () => {
  //   // let authToken = JSON.parse(localStorage.getItem('USER'))
  //   // console.log(authToken)
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append("Authorization", "Bearer" +" "+ authToken?.token);

  //   var requestOptions = {
  //     method: 'GET',
  //     headers: myHeaders,
  //     redirect: 'follow'
  //   };

  //   fetch(process.env.REACT_APP_BASE_URL+`/client/base-earning-details?hoabl_id=${authToken?.info?.hoabl_id}`, requestOptions)
  //     .then(response => {
  //       console.log(response.status)
  //       if (response.status%400<10){
  //         NotificationManager.error('Not authorised to view this data', 'Error', 6000);
  //         throw new Error('Unauthorised role!!');
  //       }
  //       return response.json()
  //     })
  //     .then(result => {
  //       // console.log(result.status)
  //       // console.log(JSON.stringify(result.results))
  //       setData(result.results)
  //       setRows(result.results)
  //     })
  //     .catch(error => console.log('error', error));
  // }

  const handleSearch = (e) => {
    // console.log(e.target.value,data)
    setPage(0)
    setRows(() => {
      return data.filter((item) => (item.project_name+item.launch_name+item.eligibility_status+item.customer_name+item.unique_plot_number).toLowerCase().includes(e.target.value.toLowerCase()))
    })
  }
  return (
    <div style={{padding:"1rem 0"}}>
      <div style={{width:'100%' ,padding:"0 0 1rem 0", display:'flex', justifyContent: 'space-between'}}>
        <Tooltip title="Search by Eligibility Status, Customer, Plot Number">
          <TextField label="Search" onChange={handleSearch} style={{width:"250px"}} />
        </Tooltip>
        {/* <Button className="registrationbutton"  variant="contained" onClick={() => navigate('create') } >Create</Button> */}
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth,}}
                  className="clientthtcell"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <Row key={row.name} row={row} hoabl_id={user?.info?.hoabl_id} handleDataRefresh={handleDataCall} hover role="checkbox" tabIndex={-1}  />                  
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}