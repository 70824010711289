import React, { useState } from 'react'
import { BrowserRouter, Route, Routes, Switch, Link, Redirect } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery';
import ListLeadMaster from './ListLeadMasterV1';
import EditLeadMaster from './EditLeadMaster';

function Leads(props) {
    const matches = useMediaQuery('(min-width:1080px)');
    React.useEffect(() => {
        props.setIsSelected("Leads")
        return () => {

        }
    }, [])
    return (
        <div style={{ padding: "1rem" }}>
            <Routes>
                <Route exact path='/' element={<ListLeadMaster />} />
                <Route exact path='/edit/:id' element={<EditLeadMaster />} />
                {/* <Route exact path='/create' element={<CreateCpMaster />}/>
                
                <Route exact path='/show/:id' element={<ShowCpMaster />}/> */}
            </Routes>
        </div>
    )
}

export default Leads;