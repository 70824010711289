import React,{ useState } from 'react'
import { Navigate, useNavigate } from "react-router-dom";
import { Button, TextField } from '@mui/material';
import { Link } from 'react-router-dom'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Tooltip from '@mui/material/Tooltip';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton, Box } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import MaterialReactTable from 'material-react-table';


function ListLead() {
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [refresh, setRefresh] = React.useState(true);
  const navigate = useNavigate()
  let authToken;
  try{
    authToken = JSON.parse(localStorage.getItem('CP-USER-AUTH'))
  }catch(err){
    authToken = null
  }
  
  React.useEffect(() => {
    
    handleDataCall()

  }, [])

  React.useEffect(() => {
    
    handleDataCall()

  }, [refresh])

  const columns = React.useMemo(() => [
    {
      accessorKey: "firstname",
      header: "First Name",
    },
    {
      accessorKey: "lastname",
      header: "Last Name",
    },
    {
      accessorKey: "mobilephone",
      header: "Phone",
    },
    {
      accessorKey: "project_name",
      header: "Project",
    },
    {
      accessorKey: "launch_name",
      header: "Launch",
    },
    {
      accessorKey: "call_type",
      header: "Call Type",
    },
    {
      accessorKey: "dispositions",
      header: "Dispositions",
    },
    {
      accessorKey: "sub_dispositions",
      header: "Sub Dispositions",
    },
    {
      accessorKey: "is_exist_in_crm",
      header: "Status",
      Cell: ({ cell, column }) => {
        let value = cell.getValue()

        if (value === "undefined"){
          return null
        }
        
        if (value === false){
          return (<div>Accepted</div>)
        }
        else{
          return (<div>Rejected</div>)
        }
        
      },
    },
    {
      accessorKey: "is_exist_in_crm",
      header: "VC Status",
      Cell: ({ cell, column }) => {
        let value = cell.getValue()

        return null
        
      },
    },
    {
      accessorKey: "is_exist_in_crm",
      header: "Booking Status",
      Cell: ({ cell, column }) => {
        let value = cell.getValue()

        return null
        
      },
    },
    // {
    //   accessorKey: "is_exist_in_crm",
    //   header: "Status",
    // },
  ])
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleDataCall = () => {
    // let authToken = JSON.parse(localStorage.getItem('USER'))
    // console.log(authToken)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer" +" "+ authToken.token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+`/client/lead-details?hoabl_id=${authToken?.info.hoabl_id}`, requestOptions)
      .then(response => {
        // console.log(response.status)
        if (response.status%400<10){
          NotificationManager.error('Not authorised to view this data', 'Error', 6000);
          throw new Error('Unauthorised role!!');
        }
        return response.json()
      })
      .then(result => {
        console.log(result)
        // console.log(JSON.stringify(result.results))
        setData(result.results)
        setRows(result.results)
      })
      .catch(error => console.log('error', error));
  }

  const handleSearch = (e) => {
    // console.log(e.target.value,data)
    setPage(0)
    setRows(() => {
      return data.filter((item) => (item.project_name).toLowerCase().includes(e.target.value.toLowerCase()))
    })
  }
  return (
    <div style={{padding:"1rem 1rem", width:'100%', margin:'6rem 1rem 1rem 1rem'}}>
      <div style={{width:'100%' ,padding:"0 0 1rem 0", display:'flex', justifyContent: 'space-between'}}>
        {/* <Tooltip title="Search by Name">
          <TextField label="Search" onChange={handleSearch} style={{width:"250px"}} />
        </Tooltip> */}
        <div>

        </div>
        <div className="flex">
          <IconButton onClick={()=>setRefresh(prev => !prev)} sx={{marginRight:"1rem"}}><RefreshIcon/></IconButton>
          <Button sx={{marginRight:"1rem"}} className="registrationbutton"  variant="contained" startIcon={<ArrowBackIosNewIcon />} onClick={() => navigate(-1) } >Back</Button>
          {/* <Button className="registrationbutton"  variant="contained" onClick={() => navigate('create') } >Create</Button> */}
        </div>
      </div>
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        initialState={{ pagination: { pageSize: 5, pageIndex: 0 } }}
        columns={columns}
        data={rows}
        enableColumnOrdering
        // enableEditing
        // onEditingRowSave={handleSaveRowEdits}
        // onEditingRowCancel={handleCancelRowEdits}
        // renderRowActions={({ row, table }) => {
        //   // console.log(row)
        //   return(
        //     <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
        //       <Tooltip arrow placement="left" title="Edit">
        //         <IconButton onClick={() => navigate(`edit/${row?.original.id}`)}>
        //           <Edit />
        //         </IconButton>
        //       </Tooltip>
        //       <Tooltip arrow placement="right" title="Delete">
        //         <IconButton color="error" onClick={() => handleDeleteRow(row)}>
        //           <Delete />
        //         </IconButton>
        //       </Tooltip>
        //     </Box>
        //   )
        // }}
        
      />
    </div>
  )
}

export default ListLead