import React,{ useState, useEffect } from 'react'
import { Navigate } from "react-router-dom";
import Menu from './Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { BrowserRouter , Route, Routes, Switch, Link, Redirect } from 'react-router-dom'
import AppDetails from './app_details';
import Dashboard from './dashboard';
import NetDeals from './net_deals';
import LadderIncentive from './ladder_incentive';
import NotFound from '../components/NotFound'
import Employee from './employee_details';
import CpMaster from './cp_master';
import Leads from './leads';
import Profile from './utility/Profile';
import Projects from './projects';
import Invoices from './invoices';
import Feedback from './feedback';
import useMediaQuery from '@mui/material/useMediaQuery';
import AdvancePayments from './advance_payments';
import Miscellaneous from './miscellaneous';
import jwt from 'jwt-decode'

// import axios from 'axios'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#161F29',
      // main: '#1976d2',
    },
  },
});


function Layout(props) {
  const [imgUri, setImgUri] = useState("")
  const navigate = useNavigate();
  const matches = useMediaQuery('(min-width:800px)');
  const [drawer, setDrawer] = React.useState(true);
  const [isSelected, setIsSelected] = React.useState("Dashboard")
  let user;
  try{
    user = JSON.parse(localStorage.getItem('EMPLOYEE-AUTH'))
    let decodedToken = jwt(user.token);
    // console.log("Decoded Token", decodedToken); 
    let currentDate = new Date();
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      localStorage.removeItem('EMPLOYEE-AUTH');
      navigate("/auth");
    }
  }catch(err){
    user = null
  }
  useEffect(() => {
    (matches === false) && setDrawer(false)
  
    return () => {

    }
  }, [])
  
  
  // console.log(user)
  if (!user){
    return <Navigate to="/auth" replace />;
  }

  const handleLogout = () => {
    localStorage.removeItem('EMPLOYEE-AUTH');
    navigate("/auth");
  }
  const handleInputChange = async(event) => {
    var blob = new Blob(event.target.files, {type: "image/png"})
    // const res = await fetch("https://s3.ap-south-1.amazonaws.com/forms.company/forms_docs/booking_amenities.jpeg")
    // const blob = await res.buffer()
    let url = URL.createObjectURL(blob)
    setImgUri(url)
  }

  

  return (
    <div style={{display:"flex",flexDirection:"row",width:"100%",marginTop:"4rem"}}>
      <ThemeProvider theme={darkTheme}>
        <AppBar color="primary" style={{position:"fixed",height:"4rem",zIndex:2}}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={()=>setDrawer(prev => !prev)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {isSelected}
            </Typography>
            {/* <Button onClick={handleLogout} color="inherit">Logout</Button> */}
            <Profile handleLogout={handleLogout} userId={user?.info?.id} name={user?.info?.name} />
          </Toolbar>
        </AppBar>
      </ThemeProvider>
      {drawer && (
        <>
          <div style={{width:"250",background:"#304665",height:"100%", minHeight:"94vh", color:"white", minWidth:"250px", maxWidth:"250px",position:'fixed', zIndex: 100}}>
            <Menu isSelected={isSelected} userMail={user.info.email}  />
          </div>
          {matches && (
            <div style={{width:"250",background:"#304665",height:"inherit", color:"white", minWidth:"250px", maxWidth:"250px"}}></div>
          )}
        </>
      )}
      <div style={{width: 'inherit',height:"min-content", overflow:"auto", textAlign:"left" }}>
        <Routes>
          <Route exact path='/' element={<Dashboard setIsSelected={setIsSelected} />}/>
          <Route exact path='/application-details/*' element={<AppDetails setIsSelected={setIsSelected}  />}/>
          <Route exact path='/netdeals/*' element={<NetDeals setIsSelected={setIsSelected}  />}/>
          <Route exact path='/adv-payments/*' element={<AdvancePayments setIsSelected={setIsSelected}  />}/>
          <Route exact path='/ladder-incentive/*' element={<LadderIncentive setIsSelected={setIsSelected} />}/>
          <Route exact path='/employee/*' element={<Employee setIsSelected={setIsSelected}  />}/>
          <Route exact path='/cp-master/*' element={<CpMaster setIsSelected={setIsSelected}  />}/>
          <Route exact path='/leads/*' element={<Leads setIsSelected={setIsSelected}  />}/>
          <Route exact path='/projects/*' element={<Projects setIsSelected={setIsSelected}  />}/>
          <Route exact path='/invoices/*' element={<Invoices setIsSelected={setIsSelected}  />}/>
          <Route exact path='/feedback/*' element={<Feedback setIsSelected={setIsSelected}  />}/>
          <Route exact path='/miscellaneous/*' element={<Miscellaneous setIsSelected={setIsSelected}  />}/>
          <Route path="*" element={<NotFound/>} />
        </Routes>
      </div>     
    </div>
  )
}

export default Layout

