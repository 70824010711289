import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Formik, Form, Field, useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { IconButton } from '@mui/material';
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from '@mui/material/Autocomplete';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {useNavigate, useParams} from "react-router-dom"
import * as yup from "yup";
import { info } from 'sass';
import Tooltip from '@mui/material/Tooltip';
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;


const leadSchema = yup.object().shape({
  project_name: yup.object().required("This is Required"), 
  launch_name: yup.object().required("This is Required"), 
  mobilephone: yup.string().matches(phoneRegExp, { message: "Not a valid Phone Number" }).required("Please add details"), 
  firstname: yup.string().required("This is Required"), 
  lastname: yup.string().required("This is Required"), 
  emailaddress1: yup.string().email("Must be valid email"),
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const TextInput = ({ field, form, ...props }) => {
  // if (field.name === "org_name") {
  //   console.log(field, form);
  // }

  return (
    <TextField
      {...field}
      {...props}
      error={
        (form?.touched[field?.name] && form?.errors[field?.name]) ||
        (form?.errors[field?.name] && form?.submitCount > 0)
      }
      helperText={form?.touched[field?.name] && form?.errors[field?.name]}
    />
  );
};

const CustomButton = ({ field, form, ...props }) => {
  return (
    <button className="registrationbuttonclient" {...field} {...props} >
      {props.children}
    </button>
  );
};

function Lead({ user }) {
  const [open, setOpen] = React.useState(false);
  const [choices, setChoices] = React.useState({
    project_name:[],
    launch:[]
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let [isloading, setIsloading] = React.useState(false);
  const navigate = useNavigate()

  React.useEffect(() => {
    
    handleDataCall()
  
    return () => {
      
    }
  }, [])

  const handleDataCall = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer "+user.token);

    // var raw = JSON.stringify({
    //   ...user?.info,
    // });

    var requestOptions = {
      method: 'get',
      headers: myHeaders,
      // body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/crm-project", requestOptions)
      .then(result => {
        // console.log(result.status)
        if (result.status%400<10){
          return NotificationManager.error('Not able to get Projects data', 'Error', 6000);
        }
        // NotificationManager.success('Feedback submiteed successfully', 'Success', 6000);
        return result.json()
      })
      .then(resp => {
        console.log(resp)
        setChoices(prev => ({...prev,project_name: resp.results}))
      })
      .catch(error => {
        console.log('error', error)
        NotificationManager.error('Something went wrong', 'Error', 6000);
        
      });

    fetch(process.env.REACT_APP_BASE_URL+"/crm-launch", requestOptions)
      .then(result => {
        // console.log(result.status)
        if (result.status%400<10){
          return NotificationManager.error('Not able to get Projects data', 'Error', 6000);
        }
        // NotificationManager.success('Feedback submiteed successfully', 'Success', 6000);
        return result.json()
      })
      .then(resp => {
        console.log(resp)
        setChoices(prev => ({...prev,launch_name: resp.results}))
      })
      .catch(error => {
        console.log('error', error)
        NotificationManager.error('Something went wrong', 'Error', 6000);
        
      });

  }
   
  const handleSubmit = (values,actions,errors) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer "+user.token);

    var raw = JSON.stringify({
      ...user?.info,
      ...values,
      cp_detail: user?.info.crm_id,
      launch_name: values.launch_name?.hoabl_launchname,
      project_name: values.project_name?.hoabl_name,
    });

    var requestOptions = {
      method: 'post',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/lead", requestOptions)
      .then(result => {
        // console.log(result.status)
        if (result.status%400<10){
          return NotificationManager.error('Not authorised to edit the data', 'Error', 6000);
        }
        NotificationManager.success('Lead submited successfully', 'Success', 6000);
        setIsloading(false)
        handleClose()
      })
      .catch(error => {
        console.log('error', error)
        NotificationManager.error('Something went wrong', 'Error', 6000);
        setIsloading(false)
      });

    handleClose()
  }

  return (
    <div>
      <Tooltip title="Add Lead">
        <IconButton style={{marginRight:"1rem",color:'white'}} onClick={handleOpen} >
          <AddCircleOutlineIcon />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Formik
            initialValues={{}}
            validationSchema={leadSchema}
            onSubmit={handleSubmit}
          >
            {({ touched, errors, values, submitCount, setFieldValue }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                      Enter Your Lead
                    </Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Autocomplete
                      id="project_name"
                      className="country-select"
                      name="project_name"
                      options={choices?.project_name?.filter(item => item.statecode === false)}
                      getOptionLabel={option => option.hoabl_name}
                      onChange={(e, value) => {
                        // console.log(value)
                        setFieldValue("project_name", value)
                        setFieldValue("project", value.hoabl_projectid)
                      }}
                      renderInput={params => (
                        <Field component={TextInput} {...params} required name="project_name" label="Project Name" fullWidth/>
                      )}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Autocomplete
                      id="launch_name"
                      className="country-select"
                      name="launch_name"
                      disabled = {!values.project_name}
                      options={choices?.launch_name?.filter(item => item.hoabl_Project_hoabl_projectid === values.project_name?.hoabl_projectid)}
                      getOptionLabel={option => option.hoabl_launchname}
                      onChange={(e, value) => {
                        // console.log(value)
                        setFieldValue("launch_name", value)
                        setFieldValue("launch", value.hoabl_launchphaseid)
                      }}
                      renderInput={params => (
                        <Field component={TextInput} {...params} required name="launch_name" label="Launch Name" fullWidth/>
                      )}
                    />
                  </Grid>
                  {/* <Grid item md={12} xs={12}>
                    <Field
                      name="launch"
                      label="Launch Name"
                      required
                      fullWidth
                      select={true}
                      SelectProps={{
                        onClose: () => {
                          setTimeout(() => {
                            document.activeElement.blur();
                          }, 0);
                        },
                      }}
                      onChange={(e) => {
                        setFieldValue("launch",e.target.value)
                      }}
                      component={TextInput}
                    >
                      {choices.launch.map(item => <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>)}
                    </Field>
                  </Grid> */}
                  <Grid item md={12} xs={12}>
                    <Field
                      name="mobilephone"
                      label="mobilephone"
                      required
                      fullWidth
                      component={TextInput}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Field
                      name="firstname"
                      label="First Name"
                      required
                      fullWidth
                      component={TextInput}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Field
                      name="lastname"
                      label="Last Name"
                      required
                      fullWidth
                      component={TextInput}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Field
                      name="emailaddress1"
                      label="Email"
                      fullWidth
                      component={TextInput}
                    />
                  </Grid>
                  <Grid item md={8} xs={0}></Grid>
                  <Grid item md={4} xs={12}>
                    <Field type="submit" component={CustomButton}>
                        {isloading ? <CircularProgress sx={{color:"#FFFFFF"}} size="1.5rem"/>:"Submit"}
                    </Field>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default Lead