import React,{ useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ImageIcon from '@mui/icons-material/Image';
import { Navigate, useNavigate } from "react-router-dom";
import { Button, TextField } from '@mui/material';
import { Link } from 'react-router-dom'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Tooltip from '@mui/material/Tooltip';
import BulkDataUpload from '../utility/BulkDataUpload';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton, Box } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import MaterialReactTable from 'material-react-table';
import { permissions } from '../utility/permissions';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


function DeleteAlert(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip arrow placement="right" title="Delete">
        <IconButton onClick={handleClickOpen} sx={{color:"#161617", cursor:'pointer', zIndex:'auto'}} >
          <Delete />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Alert
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={()=>{props.handleDelete(props.row_id);handleClose();}} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


const CustomButton = ({ field, form, ...props }) => {
  return (
    <button className="registrationbutton" {...field} {...props} >
      {props.children}
    </button>
  );
};


function ListNetDeals() {
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [refresh, setRefresh] = React.useState(true);
  const navigate = useNavigate()
  let authToken;
  try{
    authToken = JSON.parse(localStorage.getItem('EMPLOYEE-AUTH'))
  }catch(err){
    authToken = null
  }
  
  React.useEffect(() => {
    
    handleDataCall()

  }, [])

  React.useEffect(() => {
    
    handleDataCall()

  }, [refresh])

  const columns = React.useMemo(() => [
    {
      accessorKey: "unique_plot_number",
      header: "Unique Plot Number",
      // minWidth: 150,
    },
    {
      accessorKey: "application_id",
      header: "Application Id",
      // minWidth: 150,
    },
    {
      accessorKey: "invoice_id",
      header: "Invoice",
      Cell: ({ cell, column }) => {
        let value = cell.getValue()
        return value ? (
          <Link to={`/admin/invoices/edit/${value}`} style={{all:'unset'}}>
            <Button className="tablebutton">Uploaded</Button>
          </Link>
        ) : ""
      },
      // minWidth: 150,
    },
    {
      accessorKey: "customer_name",
      header: "Customer Name",
      // minWidth: 100,
    },
    {
      accessorKey: "eligibility_status",
      header: "Eligibilty Status",
      // size: 150,
    },
    {
      accessorKey: "account_eligibility_status",
      header: "Accounts Approved",
      // size: 150,
    },
    {
      accessorKey: "eligibility_amount",
      header: "Eligibility Amount",
      // minWidth: 150,
    },
    {
      accessorKey: "gst",
      header: "Gst",
      // minWidth: 150,
    },
    {
      accessorKey: "hoabl_id",
      header: "Hoabl_id",
      // minWidth: 150,
    },
    {
      accessorKey: "launch_name",
      header: "Launch Name",
      // minWidth: 150,
    },
    {
      accessorKey: "milestone",
      header: "Milestone",
      // minWidth: 150,
    },
    {
      accessorKey: "project_name",
      header: "Project Name",
      // minWidth: 150,
    },
    {
      accessorKey: "createdAt",
      header: "Created At",
      // size: 150,
    },
    {
      accessorKey: "created_by",
      header: "Created By",
      // size: 150,
    },
    {
      accessorKey: "updatedAt",
      header: "Updated At",
      // size: 150,
    },
    {
      accessorKey: "updated_by",
      header: "Updated By",
      // size: 150,
    },
    
  ])
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleDataCall = () => {
    // let authToken = JSON.parse(localStorage.getItem('USER'))
    // console.log(authToken)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer" +" "+ authToken.token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/base-earning-details", requestOptions)
      .then(response => {
        // console.log(response.status)
        if (response.status%400<10){
          NotificationManager.error('Not authorised to view this data', 'Error', 6000);
          throw new Error('Unauthorised role!!');
        }
        return response.json()
      })
      .then(result => {
        // console.log(result.status)
        // console.log(JSON.stringify(result.results))
        setData(result.results)
        setRows(result.results)
      })
      .catch(error => console.log('error', error));
  }

  const handleExcelFileUpload = async(event) => {
    var fileInput = event.target.files[0]
    // console.log(fileInput,fileUrl)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer" +" "+ authToken.token);
    var formdata = new FormData();
    formdata.append("file", fileInput,fileInput.name.replaceAll(" ","_"));
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
      headers: myHeaders
    };

    const response = await fetch(process.env.REACT_APP_BASE_URL+"/base-earning-details/upload", requestOptions)
    if(response.status === 401 || response.status === 500) {
      NotificationManager.error('Something went wrong Failed to Upload ', 'Error', 6000);
    }else{
      NotificationManager.success('Data Uploaded Successfully', 'Success', 6000);
    }
    
  }

  const handleDelete = (id) => {
    // let authToken = JSON.parse(localStorage.getItem('USER'))
    // console.log(authToken)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer" +" "+ authToken.token);

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+`/base-earning-details/${id}`, requestOptions)
      .then(async(response) => {
        // console.log(response.status)
        if (response.status === 200){
          NotificationManager.success('Successfully Deleted', 'Deleted', 6000);
          handleDataCall()
        }
        else{
          let error = await response.json()
          throw error
        }
      })
      .catch(error => {
        NotificationManager.error(error.message, 'Error', 6000);
      });
  }

  const handleSearch = (e) => {
    // console.log(e.target.value,data)
    setPage(0)
    setRows(() => {
      return data.filter((item) => (item.project_name+item.launch_name+item.hoabl_id+item.customer_name+item.unique_plot_number).toLowerCase().includes(e.target.value.toLowerCase()))
    })
  }

  return (
    <div style={{padding:"1rem 1rem"}}>
      <div style={{width:'100%' ,padding:"0 0 1rem 0", display:'flex', justifyContent: 'space-between'}}>
        {/* <Tooltip title="Search by Project, Launch, Plot Number, Customer, Hoabl Id">
          <TextField label="Search" onChange={handleSearch} style={{width:"250px"}} />
        </Tooltip> */}
        <div>

        </div>
        <div className="flex">
          {/* <label for="csv_upload">
            <img src="/images/xlsx_icon.png" style={{height:'40px',alignSelf:'right',marginRight:'1rem',cursor:'pointer'}} />
          </label>
          <input
            type="file"
            accept=".csv"
            id="csv_upload"
            name="csv_upload"
            onChange={handleExcelFileUpload}
            hidden
          /> */}
          <IconButton onClick={()=>setRefresh(prev => !prev)} sx={{marginRight:"1rem"}}><RefreshIcon/></IconButton>
          <BulkDataUpload entityType="base-earning-details"/>
          <Button sx={{marginRight:"1rem"}} className="registrationbutton"  variant="contained" startIcon={<ArrowBackIosNewIcon />} onClick={() => navigate(-1) } >Back</Button>
          <Button className="registrationbutton"  variant="contained" onClick={() => navigate('create') } >Create</Button>
        </div>
      </div>
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        initialState={{ pagination: { pageSize: 5, pageIndex: 0 } }}
        columns={columns}
        data={rows}
        enableColumnOrdering
        enableEditing
        // onEditingRowSave={handleSaveRowEdits}
        // onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => {
          // console.log(row)
          return(<Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => navigate(`edit/${row?.original.id}`)}>
                <Edit />
              </IconButton>
            </Tooltip>
            {
              (permissions.delete(authToken.info.email) || permissions.super(authToken.info.email)) && 
              <DeleteAlert handleDelete={handleDelete} row_id={row?.original.id}  />
            }
          </Box>)
        }}
        
      />
    </div>
  )
}

export default ListNetDeals