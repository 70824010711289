import React, { useState, useEffect } from 'react'
import ReactDom from 'react-dom'
import { Formik, Form, Field, useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom"
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {NotificationContainer, NotificationManager} from 'react-notifications';
 
const MODAL_STYLES = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FFF',
  padding: '50px',
  zIndex: 1000,
  width: 'fit-content',
  maxWidth: '80vw',
}

const OVERLAY_STYLES = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, .7)',
  zIndex: 1000,
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign:'center',
  alignContent:'center',
  alignItems:'center',
  display:'flex',
  justifyContent:'space-around',
  flexWrap: 'wrap',
};

const reqFormat = (num) => {
  return (num).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
}

const getFileId = () => {
  var newDate = new Date();
  return parseInt(newDate.getFullYear()+''+reqFormat(newDate.getMonth()+1)+''+reqFormat(newDate.getDate())+''+reqFormat(newDate.getHours())+''+reqFormat(newDate.getMinutes())+''+reqFormat(newDate.getSeconds()))
}

function ProjectsModal(props) {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let authToken;
  try{
    authToken = JSON.parse(localStorage.getItem(props.ls_token_key))
  }catch(err){
    authToken = null
  }

  let type_id = props.type_id
  
  const handleFileUpload = async(fileInput,fileUrl) => {
    // console.log(fileInput,fileUrl)
    var formdata = new FormData();
    formdata.append("file", fileInput,fileUrl+fileInput.name.replaceAll(" ","_"));
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    const response = await fetch(process.env.REACT_APP_BASE_URL+"/file/upload", requestOptions)
    const res = await response.json()
    if (response.status===501){
      NotificationManager.error(res.error.message, 'Form Not Submited', 8000);
    }
    return res.location
  }

  const handleSubmit = async(values, actions, errors) => {
    setIsLoading(true)
    // console.log(values, actions, errors);
    let timeString = getFileId()
    let nameString = `${timeString}`
  
    const fileUri = await handleFileUpload(values.invoice_upload, nameString)
    
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer" +" "+ authToken?.token);
    // console.log(props.type,type_id)
    var raw = JSON.stringify({
      invoice_value: values.invoice_value,
      invoice_no: values.invoice_no,
      invoice_date: values.invoice_date,
      invoice_status: "Invoice Uploaded",
      invoice_url: fileUri,
      type: props.type,
      type_id: props.type_id,
      hoabl_id: props.hoabl_id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/invoice-details", requestOptions)
      .then(result => {
        // console.log(result.status%400)
        if (result.status%400 < 110){
          throw "Something went wrong"
        }
        // console.log(result)
        setIsLoading(false)
        // navigate("/thanks")
      })
      .catch(error => {
        // navigate("/error")
        console.log('error', error)
        setIsLoading(false)
      });
    
    handleClose()
    props.handleDataRefresh()
  }
  // console.log(props.imgArray)
  const handleDownload = () => {

  }

  return (
    <div>
      <div onClick={handleOpen}>
        {props?.element}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseOutlinedIcon onClick={handleClose} style={{position:'fixed', right:'0', top:'0'}} />
          {
            props?.imgArray?.map((item,index) => (
              <div key={item}>
                <p>{props.name} {(index>0) && index}</p>
                <DescriptionOutlinedIcon style={{fontSize:'5rem'}} /><br/>
                <Button href={item} onClick={handleDownload} >Download</Button>
              </div>
            ))
          }
        </Box>
      </Modal>
    </div>
  )
}

export default ProjectsModal