import React,{ useState } from 'react'
import { Formik, Form, Field, useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {useNavigate, useParams} from "react-router-dom"
import CircularProgress from '@mui/material/CircularProgress';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MenuItem from "@mui/material/MenuItem";
import { choices } from '../utility/choices'


const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const appDetailsSchema = yup.object().shape({
  application_id: yup.string().required("This is Required"),
  application_date: yup.string().required("This is Required"),
  customer_name: yup.string().required("This is Required"),
  cp_firm_name: yup.string().required("This is Required"),
  eligibility_status: yup.string().required("This is Required"),
  eligible_amount: yup.string().required("This is Required"),
  gst_amount: yup.number().required("This is Required"),
  gst_type: yup.string().required("This is Required"),
  hoabl_id: yup.string().required("This is Required"),
  incentive_amount: yup.string().required("This is Required"),
  invoice_id: yup.string(),
  invoice_status: yup.string(),
  launch_name: yup.string().required("This is Required"),
  net_amount: yup.string(),
  payment_status: yup.string(),
  project_name: yup.string().required("This is Required"),
  tds: yup.string().required("This is Required"),
});

const TextInput = ({ field, form, ...props }) => {
  // if (field.name === "org_name") {
  //   console.log(field, form);
  // }

  return (
    <TextField
      {...field}
      {...props}
      error={
        (form?.touched[field?.name] && form?.errors[field?.name]) ||
        (form?.errors[field?.name] && form?.submitCount > 0)
      }
      helperText={form?.touched[field?.name] && form?.errors[field?.name]}
    />
  );
};

const SelectInput = ({ field, form, ...props }) => {
  // if (field.name === "org_name") {
  //   console.log(field, form);
  // }

  return (
    <input
      {...field}
      {...props}
      error={
        (form?.touched[field?.name] && form?.errors[field?.name]) ||
        (form?.errors[field?.name] && form?.submitCount > 0)
      }
      helperText={form?.touched[field?.name] && form?.errors[field?.name]}
      type='select'
      className="select"
    >
      {props.children}
    </input>
  );
};

const CustomButton = ({ field, form, ...props }) => {
  return (
    <button className="registrationbutton" {...field} {...props} >
      {props.children}
    </button>
  );
};

function CreateAppDetails() {
  const [isloading, setIsloading] = useState(false);
  const [initialValue, setInitialValue] = useState({})
  const navigate = useNavigate();

  let authToken;
  try{
    authToken = JSON.parse(localStorage.getItem('EMPLOYEE-AUTH'))
  }catch(err){
    authToken = null
  }


  const handleSubmit = async (values, actions, errors) => {
    setIsloading(true)
    // console.log(values, actions, errors);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer "+authToken.token);

    var raw = JSON.stringify({
      application_id: values.application_id,
      application_date: values.application_date,
      customer_name: values.customer_name,
      cp_firm_name: values.cp_firm_name,
      eligibility_status: values.eligibility_status,
      eligible_amount: values.eligible_amount,
      gst_amount: values.gst_amount,
      gst_type: values.gst_type,
      hoabl_id: values.hoabl_id,
      incentive_amount: values.incentive_amount,
      invoice_id: values.invoice_id,
      // invoice_status: values.invoice_status,
      launch_name: values.launch_name,
      net_amount: values.net_amount,
      // payment_status: values.payment_status,
      project_name: values.project_name,
      tds: values.tds,
    });

    var requestOptions = {
      method: 'post',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/app-incentive-details", requestOptions)
      .then(result => {
        // console.log(result.status)
        if (result.status%400<10){
          NotificationManager.error('Not authorised to edit the data', 'Error', 6000);
        }
        setIsloading(false)
        navigate("/admin/application-details")
      })
      .catch(error => {
        console.log('error', error)
        NotificationManager.error('Something went wrong', 'Error', 6000);
        setIsloading(false)
      });

  }
  return (
    <div>
      <Formik
        initialValues={{...initialValue}}
        validationSchema={appDetailsSchema}
        onSubmit={handleSubmit}
      >
        {({ touched, errors, values, submitCount, setFieldValue, handleSubmit,submitForm }) => (
          <Form  >
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <div style={{ padding: "1rem", display:'flex', justifyContent:'space-between' }}>
                  <h4 style={{ font: "Jost" }}>Create App Details</h4>
                  <Button className="registrationbutton"  variant="contained" startIcon={<ArrowBackIosNewIcon />} onClick={() => navigate(-1) } >Back</Button>
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="application_id"
                  label="Application Id"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="date"
                  name="application_date"
                  label="Application Date"
                  InputLabelProps={{ shrink: true, required: true }}
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="customer_name"
                  label="Customer Name"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="cp_firm_name"
                  label="Cp Firm Name"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {/* <Field
                  type="text"
                  name="eligibility_status"
                  label="eligibility_status"
                  fullWidth
                  component={TextInput}
                /> */}
                <Field
                  name="eligibility_status"
                  label="Eligibility Status"
                  required
                  fullWidth
                  select={true}
                  SelectProps={{
                    onClose: () => {
                      setTimeout(() => {
                        document.activeElement.blur();
                      }, 0);
                    },
                  }}
                  onChange={(e) => {
                    setFieldValue("eligibility_status",e.target.value)
                  }}
                  component={TextInput}
                >
                  {choices.eligibility_status.map(item => <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>)}
                </Field>
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="eligible_amount"
                  label="Eligible Amount"
                  required
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="gst_amount"
                  label="Gst Amount"
                  required
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {/* <Field
                  type="text"
                  name="gst_type"
                  label="Gst Type"
                  fullWidth
                  component={TextInput}
                /> */}
                <Field
                  name="gst_type"
                  label="Gst Type"
                  required
                  fullWidth
                  select={true}
                  SelectProps={{
                    onClose: () => {
                      setTimeout(() => {
                        document.activeElement.blur();
                      }, 0);
                    },
                  }}
                  onChange={(e) => {
                    setFieldValue("gst_type",e.target.value)
                  }}
                  component={TextInput}
                >
                  {choices.gst.map(item => <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>)}
                </Field>
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="hoabl_id"
                  label="Hoabl Id"
                  required
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="incentive_amount"
                  label="Incentive Amount"
                  required
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              {/* <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="invoice_id"
                  label="invoice_id"
                  fullWidth
                  component={TextInput}
                />
              </Grid> */}
              {/* <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="invoice_status"
                  label="invoice_status"
                  fullWidth
                  component={TextInput}
                />
              </Grid> */}
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="launch_name"
                  label="Launch Name"
                  required
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="net_amount"
                  label="Net Amount"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              {/* <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="payment_status"
                  label="payment_status"
                  fullWidth
                  component={TextInput}
                />
              </Grid> */}
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="project_name"
                  label="Project Name"
                  required
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="tds"
                  label="Tds"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              
              <Grid item md={12} xs={12}>
                <Field id="submitEdit" style={{width:'100%'}} name="submitEdit" type="submit"  component={CustomButton}>
                    {isloading ? <CircularProgress sx={{color:"#FFFFFF"}} size="1.5rem"/>:"Create"}
                </Field>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CreateAppDetails