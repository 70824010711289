import React,{ useState } from 'react'
import Grid  from "@mui/material/Grid"
import useMediaQuery from '@mui/material/useMediaQuery';
import { BrowserRouter , Route, Routes, Switch, Link, Redirect } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import DataList_v2 from './DataList';


const nav_elements = [
  {
    title:"Dashboard",
    img_obj: "/images/dashboard.png",
    url: "/"
  },
  {
    title:"Application Incentive",
    img_obj: "/images/applications.png",
    url: "/app-incentive"
  },
  {
    title:"Base Earnings",
    img_obj: "/images/brokerage.png",
    url: "/netdeals"
  },
  {
    title:"Ladder Incentive",
    img_obj: "/images/ladder.png",
    url: "/ladder-incentive"
  },
  {
    title:"Advance Payments",
    img_obj: "/images/AdvancePayments.png",
    url: "/adv-payments"
  },
]

const NavbarElements = () => {
  const [elements, setElements] = useState(nav_elements)
  const styles = {
          height: "30px",
          width: "50px",
          // zIndex: "1",
          overflow: "visible",
          alignItems: "center",
        }

  return (
    <>
      {elements.map((item,index) => (
        <Link key={item.url} to={item.url} style={{color:"#57564F",fontWeight:"600"}}>
          <div
            key={item.title}
            className="card card-body"
            style={{
              height: "80px",
              width: "120px",
              // zIndex: "1",
              overflow: "visible",
              marginLeft: index!==0 ? "1rem":"0",
              alignItems: "center",
              textAlign: "center",
              padding: "1rem 0",
              backgroundColor: (item.title==="Application Incentive") ? "#F1F2F3": "none"
            }}
          >
            <img src={item.img_obj} width="30px" />
            <p style={{fontSize:"10px", margin:"0.2rem", fontWeight:"600"}}>{item.title}</p>
          </div>
        </Link>
      ))}
    </>
  );
}

function AppIncentive(props) {
  const [data, setData] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [projectName, setProjectName] = useState("")
  const [launchName, setLaunchName] = useState("")
  const [filterOptions, setFilterOptions] = useState([])
  const matches = useMediaQuery('(min-width:1080px)');
  const navigate = useNavigate()
  let authToken;
  try{
    authToken = JSON.parse(localStorage.getItem('CP-USER-AUTH'))
  }catch(err){
    authToken = null
  }
    // console.log(props)
  React.useEffect(() => {
  
    handleDataCall()
    handleFilterOptionsCall()

  }, [])

  const handleFilterOptionsCall = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer" +" "+ authToken?.token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+`/app-incentive-details/projects-and-launches`, requestOptions)
      .then(response => {
        console.log(response.status)
        if (response.status%400<10){
          NotificationManager.error('Not authorised to view this data', 'Error', 6000);
          throw new Error('Unauthorised role!!');
        }
        return response.json()
      })
      .then(result => {
        // console.log(result)
        // console.log(JSON.stringify(result.results))
        setFilterOptions(result)

      })
      .catch(error => console.log('error', error));
  }
  

  const handleDataCall = () => {
    // let authToken = JSON.parse(localStorage.getItem('USER'))
    // console.log(authToken)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer" +" "+ authToken?.token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+`/client/app-incentive-details?hoabl_id=${authToken?.info?.hoabl_id}`, requestOptions)
      .then(response => {
        console.log(response.status)
        if (response.status%400<10){
          NotificationManager.error('Not authorised to view this data', 'Error', 6000);
          throw new Error('Unauthorised role!!');
        }
        return response.json()
      })
      .then(result => {
        // console.log(result.status)
        // console.log(JSON.stringify(result.results))
        setData(result.results)
        setRows(result.results)
      })
      .catch(error => console.log('error', error));
  }
  const overallEligibleIncentives = React.useMemo(() => {
    return data.length>0 ? data?.filter(item => item.eligibility_status === 'Yes')?.reduce((a,b) => {return a+ Number(b.eligibility_amount)},0).toFixed(2) : 0
  }, [data])

  const paidIncentiveTillDate = React.useMemo(() => {
    return data.length>0 ? data?.filter(item => item.payment_status === 'PAID')?.reduce((a,b) => {return a + Number(b.eligibility_amount)},0).toFixed(2) : 0
  }, [data])

  const invoiceProcessingAmount = React.useMemo(() => {
    return data.length>0 ? data?.filter(item => item.payment_status === 'INVOICE_PROCESSING')?.reduce((a,b) => {return a + Number(b.eligibility_amount)},0).toFixed(2) : 0
  }, [data])

  const invoiceRaised = React.useMemo(() => {
    return data.length>0 ? data?.filter(item => item.invoice_status === 'INVOICE_UPLOADED' || item.invoice_status === 'Accepted')?.reduce((a,b) => {return a + Number(b.eligibility_amount)},0).toFixed(2) : 0
  }, [data])

  const invoiceProcessing = React.useMemo(() => {
    return data.length>0 ? data?.filter(item => (item.payment_status === 'INVOICE_PROCESSING')).length : 0
  }, [data])



    
  return (
    <div style={{ width: "100%", padding: "1rem" }}>
      <div
        className="card card-body"
        style={{
          marginTop: "100px",
          display: "flex",
          flexDirection: "row",
          padding: "0.9rem",
        }}
      >
        {matches && (
          <>
            <div>
              <div
                style={{
                  // all:"unset",
                  display: "flex",
                  position: "absolute",
                  top: -10,
                }}
              >
                <NavbarElements />
              </div>
              {/* <div style={{ width: "60%", minWidth: "650px" }}></div> */}
            </div>
            <div style={{ width: "100%", minWidth: "650px" }}></div>
          </>
        )}
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <Grid container spacing={2}>
            {!matches && (
              <Grid item lg={6} md={6} sm={4} xs={12}>
                <select
                  name="nav"
                  id="nav-netdeals"
                  onChange={(e) =>
                    navigate(
                      `/${e.target.value === "dashboard" ? "" : e.target.value}`
                    )
                  }
                  style={{ width: "100%", minWidth: "180px", height: "30px" }}
                >
                  <option value="app-incentive">App Incentive</option>
                  <option value="ladder-incentive">Ladder Incentive</option>
                  <option value="netdeals">Base Earnings</option>
                  <option value="dashboard">Dashboard</option>
                  <option value="adv-payments">AdvancePayments</option>
                </select>
              </Grid>
            )}
            <Grid item lg={6} md={6} sm={4} xs={12}>
              <select
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                name="cars"
                id="cars"
                style={{ width: "100%", minWidth: "180px", height: "30px" }}
              >
                <option value="">All Projects</option>
                {filterOptions?.projects?.map((item) => {
                  if (!item) {
                    return null;
                  } else {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  }
                })}
              </select>
            </Grid>
            <Grid item lg={6} md={6} sm={4} xs={12}>
              <select
                value={launchName}
                onChange={(e) => setLaunchName(e.target.value)}
                name="cars"
                id="cars"
                style={{ width: "100%", minWidth: "180px", height: "30px" }}
              >
                <option value="">All Launches</option>
                {filterOptions?.launches?.map((item) => {
                  if (!item) {
                    return null;
                  } else {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  }
                })}
              </select>
            </Grid>
          </Grid>
        </div>
      </div>
      <Grid container spacing={2} style={{ marginTop: "3rem" }}>
        <Grid item md={3} sm={4} xs={12}>
          <div className="card card-body">
            <div
              style={{
                color: "#707070",
                fontSize: "16px",
                fontFamily: "'Futura Bold', sans-serif",
              }}
            >
              Overall Eligible Incentives
            </div>
            <h6
              style={{
                fontSize: "1.2rem",
                marginTop: "0",
                marginBottom: "0.5rem",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              ₹{overallEligibleIncentives}
            </h6>
          </div>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <div className="card card-body">
            <div
              style={{
                color: "#707070",
                fontSize: "16px",
                fontFamily: "'Futura Bold', sans-serif",
              }}
            >
              Paid Incentive Till Date
            </div>
            <h6
              style={{
                fontSize: "1.2rem",
                marginTop: "0",
                marginBottom: "0.5rem",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              ₹{paidIncentiveTillDate}
            </h6>
          </div>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <div className="card card-body">
            <div
              style={{
                color: "#707070",
                fontSize: "16px",
                fontFamily: "'Futura Bold', sans-serif",
              }}
            >
              Invoice Processing Amount
            </div>
            <h6
              style={{
                fontSize: "1.2rem",
                marginTop: "0",
                marginBottom: "0.5rem",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              ₹{invoiceProcessingAmount}
            </h6>
          </div>
        </Grid>
        {/* <Grid item md={3} sm={4} xs={12}>
          <div className="card card-body">
            <div
              style={{
                color: "#707070",
                fontSize: "16px",
                fontFamily: "'Futura Bold', sans-serif",
              }}
            >
              Balance Amount
            </div>
            <h6
              style={{
                fontSize: "1.2rem",
                marginTop: "0",
                marginBottom: "0.5rem",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              0
            </h6>
          </div>
        </Grid> */}
        <Grid item md={3} sm={4} xs={12}>
          <div className="card card-body">
            <div
              style={{
                color: "#707070",
                fontSize: "16px",
                fontFamily: "'Futura Bold', sans-serif",
              }}
            >
              Invoice Raised
            </div>
            <h6
              style={{
                fontSize: "1.2rem",
                marginTop: "0",
                marginBottom: "0.5rem",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              ₹{invoiceRaised}
            </h6>
          </div>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <div className="card card-body">
            <div
              style={{
                color: "#707070",
                fontSize: "16px",
                fontFamily: "'Futura Bold', sans-serif",
              }}
            >
              Invoice Processing
            </div>
            <h6
              style={{
                fontSize: "1.2rem",
                marginTop: "0",
                marginBottom: "0.5rem",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              0
            </h6>
          </div>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <div className="card card-body">
            <div
              style={{
                color: "#707070",
                fontSize: "16px",
                fontFamily: "'Futura Bold', sans-serif",
              }}
            >
              Fully Paid
            </div>
            <h6
              style={{
                fontSize: "1.2rem",
                marginTop: "0",
                marginBottom: "0.5rem",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              0
            </h6>
          </div>
        </Grid>
      </Grid>
      <div style={{ marginTop: "3rem" }}>
        {/* <List cardProps={{ sx: { paddingX: { xs: 2, md: 0 } }, title: "Application Incentive"}} >
          <DataGrid
              {...dataGridProps}
              columns={columns}
              autoHeight
              rowsPerPageOptions={[10, 20, 50, 100]}
              density="comfortable"
              sx={{
                  "& .MuiDataGrid-cell:hover": {
                      cursor: "pointer",
                  },
              }}
              onRowClick={(row) => {
                  // show("application_details", row.id);
                  edit("application_details", row.id);
              }}
          />
      </List> */}
        {/* <InvoiceUpload open={true} /> */}
        {/* <DataList /> */}
        <DataList_v2
          projectName={projectName}
          launchName={launchName}
          data={data}
          rows={rows}
          setRows={setRows}
          setData={setData}
          handleDataCall={handleDataCall}
        />
      </div>
    </div>
  );
}

export default AppIncentive