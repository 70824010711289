import React,{ useState } from 'react'
import Grid  from "@mui/material/Grid"
import useMediaQuery from '@mui/material/useMediaQuery';
import { BrowserRouter , Route, Routes, Switch, Link, Redirect } from 'react-router-dom'
import CreateLadderIncentive from './CreateLadderIncentive';
import EditLadderIncentive from './EditLadderIncentive';
import ListLadderIncentive from './ListLadderIncentiveV1';
import ShowLadderIncentive from './ShowLadderIncentive';



function LadderIncentive(props) {
  const matches = useMediaQuery('(min-width:1080px)');
  React.useEffect(() => {
    props.setIsSelected("Ladder Incentive") 
    return () => {
      
    }
  }, [])
  return (
    <div style={{width:"100%",padding:"1rem"}}>
      <Routes>
        <Route exact path='/' element={<ListLadderIncentive />}/>
        <Route exact path='/create' element={<CreateLadderIncentive />}/>
        <Route exact path='/edit/:id' element={<EditLadderIncentive />}/>
        <Route exact path='/show/:id' element={<ShowLadderIncentive />}/>
      </Routes>
    </div>
  )
}

export default LadderIncentive