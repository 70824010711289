import React,{ useState } from 'react'
import Grid  from "@mui/material/Grid"
import useMediaQuery from '@mui/material/useMediaQuery';
import { BrowserRouter , Route, Routes, Switch, Link, Redirect } from 'react-router-dom'
import CreateFeedback from './CreateFeedback';
import EditFeedback from './EditFeedback';
import ListFeedback from './ListFeedbackV1';
import ShowFeedback from './ShowFeedback';
import { FeedbackSharp } from '@mui/icons-material';

function Feedback(props) {
  React.useEffect(() => {
    props.setIsSelected("Feedback") 
    return () => {
      
    }
  }, [])
  
  return (
    <div style={{width:"100%",padding:"1rem"}}>
      <Routes>
        <Route exact path='/' element={<ListFeedback />}/>
        <Route exact path='/create' element={<CreateFeedback />}/>
        <Route exact path='/edit/:id' element={<EditFeedback />}/>
        <Route exact path='/show/:id' element={<ShowFeedback />}/>
      </Routes>
    </div>
  )
}

export default Feedback